import Checkbox from '../../../../../../../common/components/checkboxes/Checkbox';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthlete, onUpdateIsSelected }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{groupAthlete.firstName || <span>&nbsp;</span>} {groupAthlete.lastName || <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        <Checkbox
          name={`isSelected${groupAthlete.personId}`}
          checked={groupAthlete.isSelected}
          onChange={() => { onUpdateIsSelected(groupAthlete.personId) }} />
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {groupAthlete.firstName || <span>&nbsp;</span>} {groupAthlete.lastName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Competition Category
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthlete.competitionGenderTypeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Age
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {groupAthlete.age || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const ClubGroupAthletesFormGridSmall = ({ formGridState, onUpdateIsSelected }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(formGridState) && formGridState.length > 0
      ? formGridState.map((groupAthlete, i) => <GridRow key={i} groupAthlete={groupAthlete} onUpdateIsSelected={onUpdateIsSelected} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;No Athletes
          </div>
        </div>
      )
    }
  </div>
);

export default ClubGroupAthletesFormGridSmall;