import useExpiredPasswordForm from "./UseExpiredPasswordForm";

import PasswordInput from "../../../inputs/PasswordInput";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";
import PasswordRequirementsList from "../../../inputs/PasswordRequirementsList";

import style from '../LoginForms.module.css';

const ExpiredPasswordForm = ({ submitButtonText = 'Save', secondaryButtonText = 'Back',
  inputColClassName = 'col-xs-12 col-sm-6 col-md-4', verticalButtons = false, userName,
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    handleSubmit,
    updateFormState,
    onNewPasswordChanged
  } = useExpiredPasswordForm(onSubmitFormCallback, userName);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className={inputColClassName}>
          <PasswordInput
            label='Current Password*'
            name="currentPassword"
            value={formState.currentPassword}
            error={errorState.currentPassword}
            message={errorState.currentPassword}
            onChange={(value) => { updateFormState('currentPassword', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <PasswordRequirementsList passwordRequirementsState={requirementsState} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className={inputColClassName}>
          <PasswordInput
            label='New Password*'
            name="newPassword"
            value={formState.newPassword}
            error={errorState.newPassword}
            message={errorState.newPassword}
            onChange={(value) => { onNewPasswordChanged(value) }} />
        </div>
        <div className={inputColClassName}>
          <PasswordInput
            label='Confirm New Password*'
            name="confirmPassword"
            value={formState.confirmPassword}
            error={errorState.confirmPassword}
            message={errorState.confirmPassword}
            onChange={(value) => { updateFormState('confirmPassword', value) }} />
        </div>
      </div>
      <div className="row">
        <div className={["col-xs-12 usas-extra-top-margin", style.FormButtons, verticalButtons ? style.Vertical : ''].join(' ')}>
          <PrimaryButton isDisabled={enableSaveButtonState !== true} type="submit">{submitButtonText}</PrimaryButton>
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ExpiredPasswordForm;