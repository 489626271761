import { createContext, useState } from 'react';

import LscLifeMemberNewData from './LscLifeMemberNewData';

export const LscLifeMemberNewStateContext = createContext();

const LscLifeMemberNewContextProvider = ({ children }) => {
  const stateHook = useState(LscLifeMemberNewData.INITIAL_STATE);

  return (
    <LscLifeMemberNewStateContext.Provider value={stateHook}>
      {children}
    </LscLifeMemberNewStateContext.Provider>
  );
};

export default LscLifeMemberNewContextProvider;