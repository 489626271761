import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import postLscLifeMemberNew from './PostLscLifeMemberNewData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LscLifeMemberNewData = {
  INITIAL_STATE,
  postLscLifeMemberNew
};

export default LscLifeMemberNewData;