import { Fragment } from 'react';

import useClubGroupInfoHeader from './UseClubGroupInfoHeader';

import Spinner from '../../../../../../../common/components/spinners/Spinner';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const ClubGroupInfoHeader = ({ groupObj, groupMembersArray }) => {
  const {
    isLoading,
    state
  } = useClubGroupInfoHeader(groupObj, groupMembersArray);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {groupObj && groupMembersArray &&
        <div className={["row", global.HeaderText].join(' ')}>
          <div className="col-xs-6 col-md-3"><p><b>Group Name:</b></p></div>
          <div className="col-xs-6 col-md-3"> <p>{groupObj.groupName}</p></div>
          <div className="col-xs-6 col-md-3"><p><b>Group Code:</b></p></div>
          <div className="col-xs-6 col-md-3"> <p> {groupObj.groupCode}</p></div>
          <div className="col-xs-6 col-md-3"><p><b>Group Type:</b></p></div>
          <div className="col-xs-6 col-md-3"> <p>{groupObj.groupType?.typeName}</p></div>
          <div className="col-xs-6 col-md-3"><p><b>Group Category:</b></p></div>
          <div className="col-xs-6 col-md-3"> <p>{groupObj.groupCategory?.categoryName}</p></div>
          {state.dateRange &&
            <Fragment>
              <div className="col-xs-6 col-md-3"><p><b>Date Range:</b></p></div>
              <div className="col-xs-6 col-md-3"><p>{state.dateRange}</p></div>
            </Fragment>
          }
          {state.facilityName &&
            <Fragment>
              <div className="col-xs-6 col-md-3"><p><b>Facility:</b></p></div>
              <div className="col-xs-6 col-md-3"><p>{state.facilityName}</p></div>
            </Fragment>
          }
          {state.leadCoachName &&
            <Fragment>
              <div className="col-xs-6 col-md-3"><p><b>Lead Coach:</b></p></div>
              <div className="col-xs-6 col-md-3"><p>{state.leadCoachName}</p></div>
            </Fragment>
          }
          <div className="col-xs-6 col-md-3"><p><b>Number of Members:</b></p></div>
          <div className="col-xs-6 col-md-3"><p>{groupMembersArray.length}</p></div>
          {state.groupCoachesNames &&
            <Fragment>
              <div className="col-xs-6 col-md-3"><p><b>Coach(es) of Group:</b></p></div>
              <div className="col-xs-6 col-md-3"><p>{state.groupCoachesNames}</p></div>
            </Fragment>
          }
        </div>
      }
    </Fragment>
  );
};

export default ClubGroupInfoHeader;