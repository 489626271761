import Constants from '../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Life Member
  if (formState.memberId.trim() === '') {
    errors.memberId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberId.trim())) {
    errors.memberId = 'Member Id must be 14 alphanumeric characters';
  }

  //Payee
  if (formState.payeeMemberId.trim() === '') {
    errors.payeeMemberId = 'Payee Member Id is required';
  } else if (!isValidMemberId(formState.payeeMemberId.trim())) {
    errors.payeeMemberId = 'Payee Member Id must be 14 alphanumeric characters';
  }

  //Club
  if (formState.orgUnitName.trim() !== '') {
    if (formState.orgUnitId === Constants.DEFAULT_ID || formState.orgUnitId === '') {
      errors.orgUnitId = 'Must be a valid Club Name';
    }
  }
  else {
    errors.orgUnitId = 'Club is required';
  }

  //Life Member Type(s)
  if (formState.memberTypes.length === 0) {
    errors.memberTypes = 'At least one Life Member Type is required';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  await PersonHttpHelper(`/Person/member/${memberIdForUrl}/hasLifeRegistration`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.hasLifeRegistration === true) {
          errors.memberId = "Member Id already has an active life member registration";
        } else {
          formState.personId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.memberId = 'Member Id is not valid';
    });

  const payeeMemberIdForUrl = formState.payeeMemberId ? encodeURIComponent(formState.payeeMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${payeeMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.hasUserAccount === false || !(objData.personId)) {
          errors.payeeMemberId = "Payee Member Id does not have a user account";
        } else {
          formState.payeePersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.payeeMemberId = 'Payee Member Id is not valid';
    });

  return errors;
};

const NewLifeMemberFormValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default NewLifeMemberFormValidation;