import { useState } from 'react';
import { useEffect } from 'react';

import useMemberAdministrationLscLifeMemberRenewalsData from '../../../../common/state/memberAdministration/lscLifeMemberRenewals/UseMemberAdministrationLscLifeMemberRenewalsData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useLSCLifeMemberRenewals = () => {
  const { memberAdministrationLscLifeMemberRenewalsState, setMemberAdministrationLscLifeMemberRenewalsState,
    getLscLifeMemberRenewals, postLscLifeMemberRenewals, confirmSave } = useMemberAdministrationLscLifeMemberRenewalsData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const [gridState, setGridState] = useState({ gridData: [] });
  const [state, setState] = useState({ showError: false, error: '' });

  const onSelectAllClicked = () => {
    const newGridData = [];
    for (const row of gridState.gridData) {
      newGridData.push({
        ...row,
        isSelected: true
      });
    }

    setGridState({ ...gridState, gridData: newGridData });
  };

  const onUnselectAllClicked = () => {
    const newGridData = [];
    for (const row of gridState.gridData) {
      newGridData.push({
        ...row,
        isSelected: false
      });
    }

    setGridState({ ...gridState, gridData: newGridData });
  };

  const onSelectGridMember = (personId) => {
    const newGridData = [];
    for (const row of gridState.gridData) {
      newGridData.push({
        ...row,
        isSelected: personId === row.personId ? !row.isSelected : row.isSelected
      });
    }

    setGridState({ ...gridState, gridData: newGridData });
  };

  const onSaveClicked = () => {
    let memberSelectionError = false;
    let memberTypeSelectionError = false;

    if (!gridState.gridData?.find(x => x.isSelected === true)) {
      memberSelectionError = true;
    }
    if (gridState.gridData?.find(x => x.selectedMemberTypes.length === 0 && x.isSelected === true)) {
      memberTypeSelectionError = true
    }

    if (memberSelectionError === true && memberTypeSelectionError === true) {
      setState({ ...state, showError: true, error: 'Please select at least one life member to renew' });
    }
    else if (memberSelectionError === true && memberTypeSelectionError === false) {
      setState({ ...state, showError: false, error: 'Please select at least one life member to renew' });
    }
    else if (memberSelectionError === false && memberTypeSelectionError === true) {
      setState({ ...state, showError: true, error: '' });
    }
    else if (memberSelectionError === false && memberTypeSelectionError === false) {

      const array = [];

      for (let i = 0; i < gridState.gridData?.length; i++) {
        if (gridState.gridData[i].isSelected === true) {
          let renewalOrgRole = gridState.gridData[i].memberLifeOrgRole?.map(x => {
            if (gridState.gridData[i].selectedMemberTypes.find(y => (y.id === x.orgRoleId && x.currentRole === false))) {
              return ({ orgRoleId: x.orgRoleId });
            }
          });

          let renewalOrgRoleFiltered = renewalOrgRole.filter(z => z !== undefined);
          if (renewalOrgRoleFiltered.length > 0) {
            array.push({
              personId: gridState.gridData[i].personId,
              renewalOrgRole: renewalOrgRoleFiltered
            })
          }
        }
      }

      if (array.length > 0) {
        setState({ ...state, showError: false, error: '' });
        postLscLifeMemberRenewals(selectOrgUnitState.orgUnitId, array);
      }
      else {
        setState({ ...state, showError: false, error: 'Please select at least one new life member type' });
      }
    }
  };

  const onMemberTypesValueChanged = (member, value) => {
    //Do not allow user to uncheck current roles
    for (let i = 0; i < member?.memberLifeOrgRole?.length; i++) {
      if (member.memberLifeOrgRole[i].currentRole === true) {
        const name = `${member.memberLifeOrgRole[i].orgRoleName || ''} - ${member.memberLifeOrgRole[i].parentOrgUnitCode}/${member.memberLifeOrgRole[i].orgUnitCode}${member.memberLifeOrgRole[i].currentRole === true ? ' (Renewed)' : ''}`;
        if (!value?.find(x => (x.id === member.memberLifeOrgRole[i].orgRoleId && x.name === name))) {
          value.push({
            id: member.memberLifeOrgRole[i].orgRoleId,
            name: name
          })
        }
      }
    }
    const arrayDataCopy = JSON.parse(JSON.stringify(memberAdministrationLscLifeMemberRenewalsState.arrayData));
    const selectedArrayDataIndex = arrayDataCopy.findIndex(x => (x.personId === member.personId));
    arrayDataCopy[selectedArrayDataIndex].selectedMemberTypes = value;

    setMemberAdministrationLscLifeMemberRenewalsState({ ...memberAdministrationLscLifeMemberRenewalsState, arrayData: arrayDataCopy });

    const gridDataCopy = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedGridDataIndex = gridDataCopy.findIndex(x => (x.personId === member.personId));
    gridDataCopy[selectedGridDataIndex].selectedMemberTypes = value;

    setGridState({ ...gridState, gridData: gridDataCopy });
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      if (memberAdministrationLscLifeMemberRenewalsState.isSaved === true) {
        confirmSave();
      } else {
        getLscLifeMemberRenewals(selectOrgUnitState.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId, memberAdministrationLscLifeMemberRenewalsState.isSaved]);

  useEffect(() => {
    if (memberAdministrationLscLifeMemberRenewalsState.isArrayLoaded === true) {
      let newGridData = [];
      for (const row of memberAdministrationLscLifeMemberRenewalsState.arrayData) {
        const options = row.memberLifeOrgRole ? row.memberLifeOrgRole?.map(x => { return ({ id: x.orgRoleId, name: `${x.orgRoleName || ''} - ${x.parentOrgUnitCode}/${x.orgUnitCode}${x.currentRole === true ? ' (Renewed)' : ''}`, currentRole: x.currentRole, isRenewal: x.isRenewal }) }) : [];
        const selectedMemberTypes = options?.map(x => { if (x.currentRole === true || x.isRenewal === true) { return (x); } });
        const selectedMemberTypesFiltered = selectedMemberTypes?.filter(x => x !== undefined);
        newGridData.push({
          ...row,
          options: options,
          selectedMemberTypes: selectedMemberTypesFiltered,
          isSelected: false
        });
      }
      setGridState({ ...gridState, gridData: newGridData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationLscLifeMemberRenewalsState.isArrayLoaded])

  return {
    isLoading: memberAdministrationLscLifeMemberRenewalsState.isArrayLoading,
    memberAdministrationLscLifeMemberRenewalsState,
    state,
    gridState,
    onSelectGridMember,
    onSaveClicked,
    onSelectAllClicked,
    onUnselectAllClicked,
    onMemberTypesValueChanged
  };
};

export default useLSCLifeMemberRenewals;