import { useEffect, useState } from 'react';

import { COACH_ROLE_NAME } from '../../../../utils/ClubConstants';

import useBaseUsasClubData from '../../../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupFacilitiesData from '../../../../../../../common/state/groupFacilities/UseGroupFacilitiesData';
import useGroupFieldNamesData from '../../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

const INITIAL_STATE = {
  leadCoachName: '',
  groupCoachesNames: '',
  facilityName: '',
  dateRange: ''
};

const useClubGroupInfoHeader = (groupObj, groupMemberArray) => {
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupFacilitiesState, getGroupFacilitiesByOrgUnitId } = useGroupFacilitiesData();
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (groupObj?.groupType?.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP
      || groupObj?.groupType?.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE) {
      if (baseUsasClubState.isObjLoaded === true && groupFacilitiesState.orgUnitId !== baseUsasClubState.objData.orgUnitId) {
        getGroupFacilitiesByOrgUnitId(baseUsasClubState.objData.orgUnitId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, baseUsasClubState, groupFacilitiesState]);

  useEffect(() => {
    if (groupObj && groupMemberArray && baseUsasClubState.isObjLoaded === true) {
      const groupType = groupObj.groupType?.typeName;

      switch (groupType) {
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
          if (groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.orgUnitId === baseUsasClubState.objData.orgUnitId) {
            setState({
              ...state,
              leadCoachName: getLeadCoachName(groupObj.orgGroupFieldValue, groupMemberArray),
              facilityName: getFacilityName(groupObj.orgGroupFieldValue, groupFacilitiesState.options),
              dateRange: getDateRange(groupObj.orgGroupFieldValue)
            });
          }
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
          if (groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.orgUnitId === baseUsasClubState.objData.orgUnitId) {
            setState({
              ...state,
              groupCoachesNames: getGroupCoachesNames(groupMemberArray),
              facilityName: getFacilityName(groupObj.orgGroupFieldValue, groupFacilitiesState.options),
              dateRange: getDateRange(groupObj.orgGroupFieldValue)
            });
          }
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
          setState({
            ...state,
            groupCoachesNames: getGroupCoachesNames(groupMemberArray),
            dateRange: getDateRange(groupObj.orgGroupFieldValue)
          });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
        default:
          setState({ ...INITIAL_STATE });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, groupMemberArray, baseUsasClubState, groupFacilitiesState]);

  function getLeadCoachName(orgGroupFieldValue, groupMembers) {
    const leadCoachFieldValue = orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_LEAD_COACH)?.fieldValue;
    const leadCoach = groupMembers.find(x => `${x.personId}` === leadCoachFieldValue);

    return leadCoach ? `${leadCoach.firstName} ${leadCoach.lastName}` : '';
  }

  function getGroupCoachesNames(groupMembers) {
    const groupCoaches = groupMembers.filter(x => Array.isArray(x.personGroupTypeField) && x.personGroupTypeField.find(x => x.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue === COACH_ROLE_NAME);
    let coachNames = '';

    for (let i = 0; i < groupCoaches.length; i++) {
      if (coachNames) {
        if (i === groupCoaches.length - 1) {
          coachNames += ` & ${groupCoaches[i].firstName} ${groupCoaches[i].lastName}`;
        } else {
          coachNames += `, ${groupCoaches[i].firstName} ${groupCoaches[i].lastName}`;
        }
      } else {
        coachNames = `${groupCoaches[i].firstName} ${groupCoaches[i].lastName}`;
      }
    }

    return coachNames || 'None';
  }

  function getFacilityName(orgGroupFieldValue, groupFacilityOptions) {
    const facilityFieldValue = orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY)?.fieldValue;
    const facility = groupFacilityOptions.find(x => `${x.id}` === facilityFieldValue);

    return facility?.name || 'None';
  }

  function getDateRange(orgGroupFieldValue) {
    const startDate = orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue;
    const endDate = orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue;

    return `${startDate || ''} - ${endDate || ''}`;
  }

  return {
    isLoading: groupFacilitiesState.isArrayLoading,
    state
  };
};

export default useClubGroupInfoHeader;