import React from 'react';

import BaseUsasClubContextProvider from '../../../common/state/clubBase/BaseUsasClubContextProvider';
import BaseUsasClubFiltersContextProvider from '../../../common/state/clubBase/BaseUsasClubFiltersContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import SearchClubContextProvider from '../../../common/state/searchClub/SearchClubContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import MeetResultsStatusContextProvider from '../../../common/state/meet/meetResultsUploadStatus/MeetResultsStatusContextProvider';

import ClubRegistrationNewContextProvider from '../../state/clubRegistrationNew/ClubRegistrationNewContextProvider';

const ClubContextProviders = ({ children }) => {
  return (
    <SelectOrgUnitContextProvider>
      <MeetResultsStatusContextProvider>
        <PersonOrgRoleContextProvider>
          <BaseUsasClubContextProvider>
            <BaseUsasClubFiltersContextProvider>
              <SearchClubContextProvider>
                <ClubRegistrationNewContextProvider>
                  {children}
                </ClubRegistrationNewContextProvider>
              </SearchClubContextProvider>
            </BaseUsasClubFiltersContextProvider>
          </BaseUsasClubContextProvider>
        </PersonOrgRoleContextProvider>
      </MeetResultsStatusContextProvider>
    </SelectOrgUnitContextProvider>
  );
};

export default ClubContextProviders;