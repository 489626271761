import { Fragment } from 'react';

import EditIcon from '../../../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../../../common/components/icons/DeleteIcon';
import AddRemoveMembersIcon from '../../../../../../../common/components/icons/AddRemoveMembersIcon';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ group, onEditClicked, onDeleteClicked, onEditMembersClicked }) => (
  <tr>
    <td>{group?.groupName || ''}</td>
    <td>{group?.groupTypeName || ''}</td>
    <td>{group?.groupCode || ''}</td>
    <td>{group?.dateRange || ''}</td>
    <td>{group?.numberOfMembers ?? ''}</td>
    <td className='pull-right'>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onEditClicked(e, group.orgGroupId, group.groupTypeId, group.groupTypeName)}>
        <EditIcon />
      </button>
      {group?.numberOfMembers <= 0 &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteClicked(e, group.orgGroupId, group.groupName)}>
          <DeleteIcon />
        </button>
      }
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onEditMembersClicked(e, group.orgGroupId, group.groupTypeId, group.groupTypeName)}>
        <AddRemoveMembersIcon />
      </button>
    </td>
  </tr>
);

const ClubGroupsGridLarge = ({ data, isLoading, onEditClicked, onDeleteClicked, onEditMembersClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Group Name</th>
          <th>Group Type</th>
          <th>Group Code</th>
          <th>Date Range</th>
          <th>Members</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="6">Loading...</td></tr>
          : Array.isArray(data) && data.length > 0
            ? data.map((group, i) => <LargeGridRow key={i} group={group} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onEditMembersClicked={onEditMembersClicked} />)
            : <tr><td colSpan="6">No Groups</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default ClubGroupsGridLarge;