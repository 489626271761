import useNavRoutes from "../../../../../../../common/state/security/UseNavRoutes";
import { useNavigate } from "../../../../../../../common/wrappers/ReactRouterDom";

const useClubGroupsLoadingModal = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();

  const onReloadClicked = (event) => {
    event?.preventDefault();

    const homeRoute = navRoutes.CLUB_GROUPS?.route;

    if (homeRoute && homeRoute !== window.location.pathname) {
      navigate(homeRoute);
    } else {
      window.location.reload();
    }
  };

  const onBackToClubInfoClicked = (event) => {
    event?.preventDefault();

    navigate(navRoutes.CLUB_INFO?.route);
  };

  return {
    onReloadClicked,
    onBackToClubInfoClicked
  };
};

export default useClubGroupsLoadingModal;