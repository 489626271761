import { DEFAULT_ID } from "../../../../../common/utils/Constants";

/**
 * @param {object} baseGroupObjData 
 * @param {object} formState orgUnitId, groupTypeId, groupCategories, groupName, groupCode, groupDescription
 * @returns {object} group object
 */
const createGroupObjFromGroupInfoForm = (baseGroupObjData, formState) => {
  return {
    ...baseGroupObjData,
    orgUnitId: formState.orgUnitId,
    groupTypeId: formState.groupTypeId,
    groupCategoryId: formState.groupCategories[0].id,
    groupName: formState.groupName.trim(),
    groupCode: formState.groupCode.trim(),
    groupDescription: formState.groupDescription.trim(),
    groupType: undefined,
    groupCategory: undefined
  };
};

/**
 * @param {Array} orgGroupFields objects in format {fieldName, fieldValue}
 * @param {Array} orgGroupProperties 
 * @param {Number} orgGroupId optional
 * @returns {Array} org group field values
 */
const createOrgGroupFieldValues = (orgGroupFields, orgGroupProperties, orgGroupId = undefined) => {
  const orgGroupFieldValue = [];

  for (const groupField of orgGroupFields) {
    const groupTypeField = orgGroupProperties.find(x => x.fieldName === groupField.fieldName);

    if (groupTypeField) {
      orgGroupFieldValue.push({
        groupTypeField,
        groupTypeFieldId: groupTypeField.groupTypeFieldId,
        fieldValue: groupField.fieldValue,
        orgGroupId
      });
    }
  }

  return orgGroupFieldValue;
};

const getBaseGroupData = () => {
  return {
    groupCategoryId: DEFAULT_ID,
    groupTypeId: DEFAULT_ID,
    orgUnitId: '',
    groupCode: '',
    groupDescription: '',
    groupName: '',
    isDelete: false,
    orgGroupEligibility: [],
    orgGroupFieldValue: [],
    hasOrgRoleRestriction: null,
    hasRegistration: null,
    requiresCoachApproval: null,
    registrationCloseDate: null,
    registrationOpenDate: null,
    hasOpenRegistration: null
  };
};

const getGroupMemberIds = (potentialGroupMembers, existingGroupMembers) => {
  const memberIds = [];

  for (const member of potentialGroupMembers) {
    const groupCoach = existingGroupMembers.find(x => x.personId === member.id);

    if (groupCoach) {
      memberIds.push(groupCoach.personId);
    }
  }

  return memberIds;
};

const getUpdatedGroupMembers = (selectedGroupMembers, potentialGroupMembers, existingGroupMembers) => {
  const newGroupMembers = [];

  for (const member of potentialGroupMembers) {
    if (selectedGroupMembers.find(x => x.id === member.id)) { // member is selected
      if (!existingGroupMembers.find(x => x.personId === member.id)) { // member is not already in group
        newGroupMembers.push({ // add member to group
          personId: member.id,
          removeFromGroup: false
        });
      }
    } else if (existingGroupMembers.find(x => x.personId === member.id)) { // member is not selected but was in group
      newGroupMembers.push({ // remove member from group
        personId: member.id,
        removeFromGroup: true
      });
    }
  }

  return newGroupMembers;
};

export {
  createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getBaseGroupData, getGroupMemberIds, getUpdatedGroupMembers
};