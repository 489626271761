import Headings from '../../../headings/Headings';
import YesNoSwitch from '../../../yesNoSwitch/YesNoSwitch';

import style from '../../Payments.module.css'

const SwimmingWorldOptIn = ({ swimmingWorldOptIn, onValueChanged }) => (
  <div className="col-xs-12">
    <div className={style.OptionalBox}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H5>Unlock an exclusive 30% discount on Swimming World with your membership!</Headings.H5>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-10">
          Includes USA Swimming&apos;s SPLASH Magazine content with news, meet coverage, TEAM USA profiles, and expert training tips. Get full access to premium bi-monthly Digital Issues, Collector&apos;s Print Editions, and FREE downloads from the #1 app for swimmers! This offer is only available to USA Swimming members for a limited time. Select “yes” to subscribe and you&apos;ll be redirected to complete your Swimming World subscription after finishing your USA Swimming registration.
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-6 col-md-4">
          <YesNoSwitch
            name="swimmingWorldOptIn"
            checked={swimmingWorldOptIn === true}
            onChange={(newValue) => { onValueChanged('swimmingWorldOptIn', newValue); }} />
        </div>
      </div>
    </div>
  </div>
);

export default SwimmingWorldOptIn;