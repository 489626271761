/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import useClubAthleteRosterData from '../../../state/clubAthleteRoster/UseClubAthleteRosterData';
import useClubRosterVendorSyncData from '../../../state/clubRosterVendorSync/UseClubRosterVendorSyncData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useOrgGroupTypesData from '../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useMemberPersistentStorage from '../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

const useClubAthleteRoster = () => {
  const navigate = useNavigate();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { orgGroupTypesState, getOrgGroupTypes } = useOrgGroupTypesData();
  const { clubAthleteRosterState, searchClubAthleteRoster } =
    useClubAthleteRosterData();
  const {
    clubRosterVendorSyncState,
    setClubRosterVendorSyncState,
    postClubRosterVendorSync,
  } = useClubRosterVendorSyncData();

  const { setPersistentStorage } = useMemberPersistentStorage();
  const [state, setState] = useState(getInitialState());
  const [vendorSyncState, setVendorSyncState] = useState({ syncPersons: [] });
  const [transferState, setTransferState] = useState({ transferPersons: [] });

  const onEditAthlete = (personId) => {
    if (personId && baseUsasClubState.objData?.orgUnitId) {
      const returnUrl = getGlobalRoute('CLUB_ATHLETE_ROSTER')?.route;
      setPersistentStorage(
        personId,
        import.meta.env.VITE_PROJECT_NAME,
        returnUrl,
        'Back to Club'
      );

      const routeObj = getGlobalRoute('MEMBER_INFO');
      navigateToCrossUiRoute(
        routeObj?.uIProjectName,
        routeObj?.route,
        navigate
      );
    }
  };

  const onTransferCheckbox = (
    e,
    personOrgRoleDurationId,
    unClubId,
    firstName,
    preferredName,
    lastName
  ) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personArray = transferState.transferPersons;
    const idx = personArray.findIndex(
      (x) => x.personOrgRoleDurationId === personOrgRoleDurationId
    );

    if (idx > -1) {
      let removedItem = personArray.splice(idx, 1);
    } else {
      const memberTransferObj = {
        personOrgRoleDurationId: personOrgRoleDurationId,
        transferOrgUnitId: unClubId,
        name: `${firstName} ${lastName}`,
      };

      personArray.push(memberTransferObj);
    }

    setTransferState({
      ...transferState,
      transferPersons: personArray,
    });
  };

  const onVendorSyncCheckbox = (e, memberId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personArray = vendorSyncState.syncPersons;
    const idx = personArray.findIndex((x) => x.memberId === memberId);

    if (idx > -1) {
      let removedItem = personArray.splice(idx, 1);
    } else {
      const memberObj = {
        orgUnitId: baseUsasClubState.objData?.orgUnitId || Constants.DEFAULT_ID,
        memberId: memberId,
      };

      personArray.push(memberObj);
    }

    setVendorSyncState({
      ...vendorSyncState,
      syncPersons: personArray,
    });
  };

  const onSyncSelectedMembers = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postClubRosterVendorSync(vendorSyncState.syncPersons);
  };

  const onSubmitFormCallback = (formState) => {
    if (baseUsasClubState.isObjLoaded === true && formState) {
      searchClubAthleteRoster(
        baseUsasClubState.objData.usasClubId,
        createFilterObj(formState)
      );
    }
  };

  const clearTransferState = () => {
    setTransferState({ ...transferState, transferPersons: [] });
  };

  const clearVendorSync = () => {
    setVendorSyncState({ ...vendorSyncState, syncPersons: [] });

    setClubRosterVendorSyncState({
      ...clubRosterVendorSyncState,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
    });
  };

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      searchClubAthleteRoster(baseUsasClubState.objData.usasClubId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === true) {
      const practiceGroupType = orgGroupTypesState.arrayData.find(
        (x) => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP
      );
      const siteGroupType = orgGroupTypesState.arrayData.find(
        (x) => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE
      );

      setState({
        ...state,
        practiceGroupTypeId:
          practiceGroupType?.groupTypeId || Constants.DEFAULT_ID,
        siteGroupTypeId: siteGroupType?.groupTypeId || Constants.DEFAULT_ID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupTypesState]);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === false) {
      getOrgGroupTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createFilterObj(formState) {
    return {
      competitionGenderTypeId:
        formState.competitionGenderTypeId > 0
          ? formState.competitionGenderTypeId
          : undefined,
      ageStart:
        formState.minAge.trim() !== '' ? formState.minAge.trim() : undefined,
      ageEnd:
        formState.maxAge.trim() !== '' ? formState.maxAge.trim() : undefined,
      firstName:
        formState.firstOrPreferredName.trim() !== ''
          ? formState.firstOrPreferredName.trim()
          : undefined,
      lastName:
        formState.lastName.trim() !== ''
          ? formState.lastName.trim()
          : undefined,
      practiceOrgGroupId:
        formState.practiceGroupId > 0 ? formState.practiceGroupId : undefined,
      clubSiteOrgGroupId:
        formState.siteGroupId > 0 ? formState.siteGroupId : undefined,
    };
  }

  function getInitialState() {
    return {
      practiceGroupTypeId: Constants.DEFAULT_ID,
      siteGroupTypeId: Constants.DEFAULT_ID,
    };
  }

  return {
    isLoading:
      baseUsasClubState.isObjLoading ||
      clubAthleteRosterState.isArrayLoading ||
      orgGroupTypesState.isArrayLoading,
    state,
    baseUsasClubState,
    clubAthleteRosterState,
    vendorSyncState,
    clubRosterVendorSyncState,
    transferState,
    onEditAthlete,
    onVendorSyncCheckbox,
    onSyncSelectedMembers,
    onSubmitFormCallback,
    onTransferCheckbox,
    searchClubAthleteRoster,
    clearVendorSync,
    clearTransferState,
    orgUnitId: baseUsasClubState.objData?.orgUnitId || '',
    orgUnitCode: baseUsasClubState.objData?.orgUnit.orgUnitCode,
    orgUnitName: baseUsasClubState.objData?.orgUnit.orgUnitName,
    parentOrgUnitCode: baseUsasClubState.objData?.orgUnit.parentOrgUnitCode,
    clubCity:
      baseUsasClubState.objData?.usasClubAddress.length > 0
        ? baseUsasClubState.objData?.usasClubAddress[0].address.city
        : '',
    clubStateCode:
      baseUsasClubState.objData?.usasClubAddress.length > 0
        ? baseUsasClubState.objData?.usasClubAddress[0].address.stateCode
        : '',
    clubAddress1:
      baseUsasClubState.objData?.usasClubAddress.length > 0
        ? baseUsasClubState.objData?.usasClubAddress[0].address.address1
        : '',
    clubPostalCode:
      baseUsasClubState.objData?.usasClubAddress.length > 0
        ? baseUsasClubState.objData?.usasClubAddress[0].address.postalCode
        : '',
  };
};

export default useClubAthleteRoster;
