import { Fragment } from "react";

import UseNewLifeMemberForm from "./UseNewLifeMemberForm";

import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Constants from "../../../common/utils/Constants";
import SearchMemberWithLscPopup from "../../../common/components/searches/searchMember/SearchMemberWithLscPopup";
import LscDropdown from "../../../common/components/dropdowns/lscDropdown/LscDropdown";
import ActiveClubCombobox from "../../../common/components/comboboxes/activeClubCombobox/ActiveClubCombobox";
import OrgRoleMultiselect from "../../components/multiselect/orgRoleMultiselect/OrgRoleMultiselect";

const NewLifeMemberForm = ({ savedFormState, onSubmitFormCallback, LIFE_ROLES_ROLE_GROUP_ID }) => {
  const {
    formState,
    errorState,
    isSubmitting,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormState
  } = UseNewLifeMemberForm(savedFormState, onSubmitFormCallback, LIFE_ROLES_ROLE_GROUP_ID);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup label="Member Id*" memberIdName="memberId" formState={formState} errorState={errorState} setFormData={setFormState} onFormValueChanged={onFormValueChanged} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberWithLscPopup label="Payee Member Id*" memberIdName="payeeMemberId" formState={formState} errorState={errorState} setFormData={setFormState} onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <OrgRoleMultiselect
              label={"Life Member Type(s)*"}
              name="memberTypes"
              value={formState.memberTypes}
              error={errorState.memberTypes}
              message={errorState.memberTypes}
              onChange={(value) => { onFormValueChanged('memberTypes', value); }}
              roleGroupId={LIFE_ROLES_ROLE_GROUP_ID} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LscDropdown
              label="LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
                onValueTextPairChanged('', 'orgUnitId', '', 'orgUnitName', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ActiveClubCombobox
              label={formState.orgUnitLabel + '*'}
              name="orgUnitName"
              valueToMatch={formState.orgUnitName}
              error={errorState.orgUnitId}
              message={errorState.orgUnitId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
              lscId={formState.lscOrgUnitId} />
          </div>

        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>
          </div>
        </div>
      </form >
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isSubmitting} />
    </Fragment>
  );
};

export default NewLifeMemberForm;