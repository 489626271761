import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getLscLifeMemberRenewals from './GetLscLifeMemberRenewalsData';
import postLscLifeMemberRenewals from './PostLscLifeMemberRenewalsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationLscLifeMemberRenewalsData = {
  INITIAL_STATE,
  getLscLifeMemberRenewals,
  postLscLifeMemberRenewals
};

export default MemberAdministrationLscLifeMemberRenewalsData;