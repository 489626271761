import { Fragment } from 'react';

import UseNewLifeMember from './UseNewLifeMember';

import NewLifeMemberForm from './NewLifeMemberForm';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Headings from '../../../common/components/headings/Headings';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';

import Constants from '../../../common/utils/Constants';

const NewLifeMember = () => {
  const {
    lscLifeMemberNewState,
    isSaving,
    isLoading,
    formState,
    onFormSubmit,
    LIFE_ROLES_ROLE_GROUP_ID,
    modalState,
    onModalCanceled
  } = UseNewLifeMember();

  return (
    <Fragment>
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>New Life Member</Headings.H3>
          </div>
        </div>
        <NewLifeMemberForm
          savedFormState={formState}
          onSubmitFormCallback={onFormSubmit}
          LIFE_ROLES_ROLE_GROUP_ID={LIFE_ROLES_ROLE_GROUP_ID} />
      </Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp && !isSaving}
        onModalCanceled={onModalCanceled}>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-bottom-margin">
              <p><b>Status:</b> {lscLifeMemberNewState.objData?.message}</p>
            </div>
            <div className="col-xs-12 usas-extra-bottom-margin">
              <b>Your life membership(s) have been added to the payee&apos;s shopping cart!</b>
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Close</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default NewLifeMember;