import { useEffect, useState } from "react";

const useClubGroupConfirmationFormGrid = (gridData, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const [formGridState, setFormGridState] = useState([]);

  const onSaveClicked = (event) => {
    try {
      event?.preventDefault();

      const updatedGroupAthletes = [];

      for (const groupAthleteFormObj of formGridState) {
        if (groupAthleteFormObj.isInGroup === true && groupAthleteFormObj.isSelected === false) {
          updatedGroupAthletes.push({
            personId: groupAthleteFormObj.personId,
            removeFromGroup: true
          });
        } else if (groupAthleteFormObj.isInGroup === false && groupAthleteFormObj.isSelected === true) {
          updatedGroupAthletes.push({
            personId: groupAthleteFormObj.personId,
            removeFromGroup: false
          });
        }
      }

      onSubmitFormCallback(updatedGroupAthletes);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onUpdateIsSelected = (event, personId) => {
    try {
      event?.preventDefault();

      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].personId === personId) {
          newFormGridState[i].isSelected = !newFormGridState[i].isSelected;
          break;
        }
      }

      setFormGridState(newFormGridState);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  useEffect(() => {
    try {
      const newFormGridState = createFormGridState(gridData);

      setFormGridState(newFormGridState);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  function createFormGridState(gridData) {
    const newFormGridState = [];

    for (const groupAthleteObj of gridData) {
      const newGroupAthleteFormObj = createGroupAthleteFormObj(groupAthleteObj);

      newFormGridState.push(newGroupAthleteFormObj);
    }

    return newFormGridState;
  }

  function createGroupAthleteFormObj(groupAthleteObj) {
    return {
      ...groupAthleteObj,
      personId: groupAthleteObj.personId,
      firstName: groupAthleteObj.firstName,
      lastName: groupAthleteObj.lastName,
      competitionGenderTypeName: groupAthleteObj.competitionGenderTypeName,
      age: groupAthleteObj.age,
      isSelected: groupAthleteObj.isSelected === true,
      isInGroup: groupAthleteObj.isInGroup === true
    };
  }

  return {
    addedAthletes: formGridState.filter((x) => x.isSelected === true),
    removedAthletes: formGridState.filter((x) => x.isSelected === false),
    onUpdateIsSelected,
    onSaveClicked
  };
};

export default useClubGroupConfirmationFormGrid;