import { Fragment } from 'react';

import useClubGroupAthletesFormGrid from './UseClubGroupAthletesFormGrid';

import ClubGroupAthletesFormGridLarge from './ClubGroupAthletesFormGridLarge';
import ClubGroupAthletesFormGridSmall from './ClubGroupAthletesFormGridSmall';

import ClubGroupAthletesFilter from '../../forms/clubGroupAthletesFilter/ClubGroupAthletesFilter';

import Spinner from '../../../../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../../../common/components/buttons/ActionIntraPageButton';

const ClubGroupAthletesFormGrid = ({ gridData, isLoading, secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    isDirtyState,
    selectAllButtonState,
    formGridState,
    onSaveClicked,
    onUpdateIsSelected,
    onUpdateAllGridItems,
    onSubmitGroupAthleteFilterForm
  } = useClubGroupAthletesFormGrid(gridData, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <ClubGroupAthletesFilter
        onSubmitFormCallback={onSubmitGroupAthleteFilterForm}
        onErrorCaught={onErrorCaught} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" isDisabled={isDirtyState === false} onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          <span className='pull-right'>
            <ActionIntraPageButton type="button" onClick={() => onUpdateAllGridItems(selectAllButtonState === true)}>
              {selectAllButtonState === true ? 'Select All' : 'Unselect All'}
            </ActionIntraPageButton>
          </span>
        </div>
        <div className="col-xs-12">
          <p>{formGridState.length !== gridData.length ? `Showing ${formGridState.length} of ${gridData.length}` : `${formGridState.length}`} Athletes</p>
        </div>
      </div>
      <ClubGroupAthletesFormGridLarge formGridState={formGridState} onUpdateIsSelected={onUpdateIsSelected} />
      <ClubGroupAthletesFormGridSmall formGridState={formGridState} onUpdateIsSelected={onUpdateIsSelected} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" isDisabled={isDirtyState === false} onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ClubGroupAthletesFormGrid;