import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisuallyHidden } from '@reach/visually-hidden';

import global from '../GlobalStyle.module.css';
import GridFriendlyTree from './GridFriendlyTree';
import style from './GridFriendlyTree.module.css';
import useTreeView from './UseTreeView';

const GridFriendlyTreeView = ({ label, name, value, error, message, options, selectableLeavesOnly,
  singleSelect, onChange, isLoading, className }) => {

  const { isTreeVisible, openTree, closeTree, handleBlur, isReadWrite } = useTreeView();

  return (
    <div className={[global.ComponentFlex, style.TreeView].join(' ')}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div className={style.AdjustMarginTop}>
        {isLoading !== true && (
          <button
            className={[global.IconButton, style.ChevronIcon].join(' ')}
            type="button"
            onClick={isTreeVisible === true ? closeTree : openTree}>
            <VisuallyHidden>{isTreeVisible === true ? 'Close' : 'Open'} tree control for {name}</VisuallyHidden>
            <FontAwesomeIcon icon={isTreeVisible === true ? faChevronUp : faChevronDown} title={`Open tree control for ${name}`} />
          </button>
        )}
        <input
          className={[className && '', style.InputBox, error ? global.Error : global.BaseInputBox].join(' ')}
          type="text"
          value={value.map(x => x.name).join(', ')}
          id={name}
          name={name}
          readOnly="readonly" />
      </div>
      <div
        className={[
          style.TreeContainer,
          isTreeVisible === true && isReadWrite === true ? style.Shown : style.Hidden
        ].join(' ')}
        onBlur={handleBlur}>
        <GridFriendlyTree
          name={`${name}-tree`}
          value={value}
          options={options}
          selectableLeavesOnly={selectableLeavesOnly}
          singleSelect={singleSelect}
          onChange={onChange}
          isLoading={isLoading}
        />
      </div>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default GridFriendlyTreeView;
