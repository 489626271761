import { useEffect, useState } from "react";

import ToIntIfInt from "../../../../../../../common/utils/ToIntIfInt";

const useClubGroupAthletesFormGrid = (gridData, onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const [initialFormGridState, setInitialFormGridState] = useState([]);
  const [formGridState, setFormGridState] = useState([]);
  const [selectAllButtonState, setSelectAllButtonState] = useState(true);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const onSaveClicked = (event) => {
    try {
      event?.preventDefault();

      const updatedGroupAthletes = [];

      for (const groupAthleteFormObj of formGridState) {
        if (groupAthleteFormObj.isDirty === true) {
          updatedGroupAthletes.push(groupAthleteFormObj);
        }
      }

      onSubmitFormCallback(updatedGroupAthletes);
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onUpdateAllGridItems = (isSelected) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].displayInGrid === true) {
          newFormGridState[i].isSelected = isSelected;
          newFormGridState[i].isDirty = getIsGroupAthleteDirty(newFormGridState[i]);
        }
      }

      setFormGridState(newFormGridState);
      setIsDirtyState(getIsDirty(newFormGridState));
      setSelectAllButtonState(getSelectAllValue(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onUpdateIsSelected = (personId) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].personId === personId) {
          newFormGridState[i].isSelected = !newFormGridState[i].isSelected;
          newFormGridState[i].isDirty = getIsGroupAthleteDirty(newFormGridState[i]);
          break;
        }
      }

      setFormGridState(newFormGridState);
      setIsDirtyState(getIsDirty(newFormGridState));
      setSelectAllButtonState(getSelectAllValue(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  const onSubmitGroupAthleteFilterForm = (formState) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));
      const startAge = ToIntIfInt(formState.startAge) === '' ? undefined : formState.startAge;
      const endAge = ToIntIfInt(formState.endAge) === '' ? undefined : formState.endAge;
      const competitionGender = formState.competitionGenderTypeName === '--' || formState.competitionGenderTypeName === '' ? undefined : formState.competitionGenderTypeName

      for (let i = 0; i < newFormGridState.length; i++) {
        newFormGridState[i].displayInGrid = true;

        if (startAge !== undefined && newFormGridState[i].age < startAge) {
          newFormGridState[i].displayInGrid = false;
        } else if (endAge !== undefined && newFormGridState[i].age > endAge) {
          newFormGridState[i].displayInGrid = false;
        } else if (competitionGender !== undefined && newFormGridState[i].competitionGenderTypeName !== competitionGender) {
          newFormGridState[i].displayInGrid = false;
        }
      }

      setFormGridState(newFormGridState);
      setSelectAllButtonState(getSelectAllValue(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
  };

  useEffect(() => {
    try {
      const newFormGridState = createFormGridState(gridData, formGridState);
      const newInitialFormGridState = createFormGridState(gridData);

      setFormGridState(newFormGridState);
      setInitialFormGridState(newInitialFormGridState);
      setIsDirtyState(getIsDirty(newFormGridState));
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirtyState);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : () => { throw e };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirtyState]);

  function getIsGroupAthleteDirty(groupAthleteFormObj, initialGroupAthleteFormObj = undefined) {
    if (initialGroupAthleteFormObj === undefined) {
      initialGroupAthleteFormObj = initialFormGridState.find((x) => x.personId === groupAthleteFormObj.personId);
    }

    if (initialGroupAthleteFormObj?.isSelected !== groupAthleteFormObj.isSelected) {
      return true;
    } else {
      return false;
    }
  }

  function getIsDirty(newFormGridState) {
    for (const groupAthlete of newFormGridState) {
      if (groupAthlete.isDirty === true) {
        return true;
      }
    }

    return false;
  }

  function getSelectAllValue(newFormGridState) {
    for (const groupAthlete of newFormGridState) {
      if (groupAthlete.displayInGrid === true && groupAthlete.isSelected === false) {
        return true; // not all displayed athletes are selected, so select all
      }
    }

    return false; // all displayed athletes are selected, so deselect all
  }

  function createFormGridState(gridData, existingFormGridState = undefined) {
    const newFormGridState = [];

    for (const groupAthleteObj of gridData) {
      const newGroupAthleteFormObj = createGroupAthleteFormObj(groupAthleteObj);

      if (existingFormGridState) {
        const existingGroupAthleteFormObj = existingFormGridState.find((x) => x.personId === groupAthleteObj.personId);

        if (existingGroupAthleteFormObj && getIsGroupAthleteDirty(existingGroupAthleteFormObj, newGroupAthleteFormObj) === true) {
          newFormGridState.push({
            ...existingGroupAthleteFormObj,
            isDirty: true
          });
        } else {
          newFormGridState.push(newGroupAthleteFormObj);
        }
      } else {
        newFormGridState.push(newGroupAthleteFormObj);
      }
    }

    return newFormGridState;
  }

  function createGroupAthleteFormObj(groupAthleteObj) {
    return {
      personId: groupAthleteObj.personId,
      firstName: groupAthleteObj.firstName,
      lastName: groupAthleteObj.lastName,
      competitionGenderTypeName: groupAthleteObj.typeName,
      age: groupAthleteObj.age,
      isSelected: groupAthleteObj.isInGroup === true,
      isInGroup: groupAthleteObj.isInGroup === true,
      displayInGrid: true,
      isDirty: false
    };
  }

  return {
    isDirtyState,
    selectAllButtonState,
    formGridState: formGridState.filter((x) => x.displayInGrid === true),
    onUpdateIsSelected,
    onUpdateAllGridItems,
    onSaveClicked,
    onSubmitGroupAthleteFilterForm
  };
};

export default useClubGroupAthletesFormGrid;