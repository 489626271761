import { useContext } from 'react';

import LscLifeMemberNewData from './LscLifeMemberNewData';
import { LscLifeMemberNewStateContext } from '../lscLifeMemberNew/LscLifeMemberNewContextProvider';

const useLscLifeMemberNewData = () => {
  const [lscLifeMemberNewState, setLscLifeMemberNewState] = useContext(LscLifeMemberNewStateContext);

  const postLscLifeMemberNew = (orgUnitId, obj) => LscLifeMemberNewData.postLscLifeMemberNew(orgUnitId, obj, lscLifeMemberNewState, setLscLifeMemberNewState);

  const confirmSave = () => {
    setLscLifeMemberNewState({
      ...lscLifeMemberNewState,
      isSaved: false
    });
  };

  const clearLscLifeMemberNewObjData = () => {
    setLscLifeMemberNewState({
      ...lscLifeMemberNewState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {}
    });
  }

  return {
    lscLifeMemberNewState,
    postLscLifeMemberNew,
    confirmSave,
    clearLscLifeMemberNewObjData
  };
};

export default useLscLifeMemberNewData;