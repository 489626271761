import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import setMeetResultsStatusData from './SetMeetResultsStatusData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetResultsStatusData = {
  INITIAL_STATE, setMeetResultsStatusData
};

export default MeetResultsStatusData;