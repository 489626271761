const SetMeetResultsStatusData = (hasUploadStarted, state, setState) => {
    const newState = {
        ...state,
        isSaving: true,
        isSaved: false,
        hasUploadStarted: false,
        message: 'Saving...'
      };
      setState(newState);

      setState({
        ...newState,
        isObjLoaded: true,
        isSaving: false,
        isSaved: true,
        hasUploadStarted: hasUploadStarted,
        message: ''
      });
}

export default SetMeetResultsStatusData;