import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import GroupData from '../../../common/state/group/GroupData';

import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { DEFAULT_ID } from '../../../common/utils/Constants';

const getClubGroupsData = (orgUnitId, state, setState) => {
  if (orgUnitId) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const url = `/OrgGroup/orgUnit/flat/${orgUnitIdForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getClubGroupPotentialAthletesData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const newState = {
      ...state,
      orgGroupId
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const orgGroupIdForUrl = orgGroupId ? encodeURIComponent(orgGroupId) : 'NaN';
    const url = `/OrgGroup/Athlete/${orgGroupIdForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_CLUB_GROUPS_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: ''
};

const INITIAL_CLUB_GROUP_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_CLUB_GROUP_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE,
  orgGroupId: DEFAULT_ID
};

const INITIAL_CLUB_GROUP_POTENTIAL_ATHLETES_STATE = {
  ...BASIC_INITIAL_STATE,
  orgGroupId: DEFAULT_ID
};

const ClubGroupsData = {
  INITIAL_CLUB_GROUPS_STATE,
  INITIAL_CLUB_GROUP_INFO_STATE,
  INITIAL_CLUB_GROUP_MEMBERS_STATE,
  INITIAL_CLUB_GROUP_POTENTIAL_ATHLETES_STATE,
  postClubGroupData: GroupData.postGroupData,
  getClubGroupsData,
  getClubGroupInfoData: GroupData.getGroupData,
  getClubGroupMembersData: GroupData.getGroupMembersData,
  getClubGroupPotentialAthletesData,
  putClubGroupData: GroupData.putGroupData,
  putClubGroupMembersData: GroupData.putGroupMembersData,
  deleteClubGroupData: GroupData.deleteGroupData
};

export default ClubGroupsData;