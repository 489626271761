import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../NavLinks';

import useOrgGroupTypesData from '../../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_STATE = {
  orgGroupTypes: [],
  continueErrorMessage: '',
  pageErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  selectedGroupType: '',
  selectedGroupTypeDescription: 'Please select a Group Type'
};

const useClubGroupsSelectGroupType = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { orgGroupTypesState, getOrgGroupTypes } = useOrgGroupTypesData();
  const { formState, errorState, setFormState, setIsDirty } = useForm(INITIAL_FORM_STATE);
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (event) => {
    try {
      event?.preventDefault();

      const targetGroup = state.orgGroupTypes.find(x => x.value === formState.selectedGroupType);

      if (targetGroup?.value) {
        switch (targetGroup.value) {
          case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
            navigate(NavLinks.CLUB_GROUPS_LIST_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
            break;
          case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
            navigate(NavLinks.CLUB_GROUPS_TRAVEL_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
            break;
          case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
            navigate(NavLinks.CLUB_GROUPS_PRACTICE_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
            break;
          case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
            navigate(NavLinks.CLUB_GROUPS_CLUB_SITE_ADD, { state: { groupTypeId: targetGroup.id, groupTypeName: targetGroup.value } });
            break;
          default:
            throw new Error(`Invalid group type: ${targetGroup.value}`);
        }
      } else {
        setState({
          ...state,
          continueErrorMessage: 'You must select a group type to continue'
        });
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onSelectedGroupTypeChange = (value) => {
    try {
      const newDescription = state.orgGroupTypes.find(x => x.value === value)?.description;

      setIsDirty(true);
      setFormState({
        ...formState,
        selectedGroupType: value,
        selectedGroupTypeDescription: newDescription || ''
      });
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === false) {
      getOrgGroupTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === true) {
      setState({
        ...state,
        orgGroupTypes: filterGroupTypes(orgGroupTypesState.arrayData)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupTypesState]);

  function filterGroupTypes(groupTypes) {
    const filteredGroupTypes = [];

    for (let i = 0; i < groupTypes.length; i++) {
      if (groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP
        || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP || groupTypes[i].typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE) {
        filteredGroupTypes.push({
          id: groupTypes[i].groupTypeId,
          value: groupTypes[i].typeName,
          label: groupTypes[i].typeName,
          description: groupTypes[i].typeDescription
        });
      }
    }

    return filteredGroupTypes;
  }

  return {
    isLoading: orgGroupTypesState.isArrayLoading,
    state,
    formState,
    errorState,
    onSelectedGroupTypeChange,
    onContinueClicked,
    onBackClicked
  };
};

export default useClubGroupsSelectGroupType;