import { useEffect } from 'react';

import useContactRelationshipTypeData from '../../../state/contactRelationshipType/UseContactRelationshipTypesData';

const SELF_LABEL = 'Self';

const useNoSelfContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState, getContactRelationshipTypes } = useContactRelationshipTypeData();

  useEffect(() => {
    if (contactRelationshipTypeState.isArrayLoading === false && contactRelationshipTypeState.isArrayLoaded === false) {
      getContactRelationshipTypes();
    }
    if (contactRelationshipTypeState.isArrayLoading === false && contactRelationshipTypeState.arrayData.length > 0) {
      const selfIndex = contactRelationshipTypeState.dataAsOptionsWithNames.findIndex(x => x.name === SELF_LABEL);
      const selfIndexNT = contactRelationshipTypeState.ntDataAsOptionsWithNames.findIndex(x => x.name === SELF_LABEL);
      
      if (selfIndex >= 0) {
        contactRelationshipTypeState.dataAsOptionsWithNames.splice(selfIndex, 1);
      }

      if (selfIndexNT >= 0) {
        contactRelationshipTypeState.ntDataAsOptionsWithNames.splice(selfIndexNT, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRelationshipTypeState]);

  return {
    contactRelationshipTypeState
  };
};

export default useNoSelfContactRelationshipTypeDropdown;