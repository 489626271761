import { Fragment } from 'react';

import ClubGroupsGridLarge from './ClubGroupsGridLarge';
import ClubGroupsGridSmall from './ClubGroupsGridSmall';

const ClubGroupsGrid = ({ data, isLoading, onEditClicked, onDeleteClicked, onEditMembersClicked }) => (
  <Fragment>
    <ClubGroupsGridLarge
      data={data}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onEditMembersClicked={onEditMembersClicked} />
    <ClubGroupsGridSmall
      data={data}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      onEditMembersClicked={onEditMembersClicked} />
  </Fragment>
);

export default ClubGroupsGrid;