import GroupInfoFormValidation from "../../../../../../common/components/forms/groups/GroupInfoFormValidation";
import TravelTripAttributesFormValidation from "../../../../../../common/components/forms/groups/TravelTripAttributesFormValidation";

const ClubGroupTravelTripValidation = async (formState) => {
  let errors = {
    ...GroupInfoFormValidation(formState),
    ...TravelTripAttributesFormValidation(formState)
  };

  return errors ? errors : {};
};

export default ClubGroupTravelTripValidation;