import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupTravelTripValidation';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getBaseGroupData } from '../../utils/ClubGroupsUtils';
import { COACH_ROLE_NAME } from '../../../utils/ClubConstants';

import NavLinks from '../../../NavLinks';

import useClubGroupsData from '../../../../../state/clubGroups/UseClubGroupsData';

import useBaseUsasClubData from '../../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupCategoryData from '../../../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupFieldNamesData from '../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';

import useForm from '../../../../../../common/utils/UseForm';
import { DEFAULT_ID, BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';

const INITIAL_STATE = {
  pageErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  orgUnitId: '',
  groupTypeId: DEFAULT_ID,
  groupTypeName: '',
  groupCategories: [],
  groupName: '',
  groupCode: '',
  groupDescription: '',
  groupCoaches: [],
  startDate: BLANK_DATE_STRING,
  endDate: BLANK_DATE_STRING,
  city: '',
  stateCode: '',
  stateName: '',
  zip: '',
  tripPurpose: ''
};

const useClubGroupTravelTripAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupCategoryState } = useGroupCategoryData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { clubGroupInfoState, clubGroupMembersState, postClubGroup, putClubGroupMembers } = useClubGroupsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    try {
      if (location.state?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(location.state.groupTypeId);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (location.state?.groupTypeId && location.state?.groupTypeName) {
        if (baseUsasClubState.isObjLoaded === true && groupCategoryState.isArrayLoaded === true
          && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === location.state?.groupTypeId) {
          setFormData({
            ...formState,
            groupTypeId: location.state.groupTypeId,
            groupTypeName: location.state.groupTypeName,
            orgUnitId: baseUsasClubState.objData.orgUnitId
          });
        }
      } else {
        navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState, orgGroupPropertiesState]);

  async function submitFormCallback(formState) {
    try {
      const newOrgGroupObj = createGroupObjFromGroupInfoForm(getBaseGroupData(), formState);
      const newGroupMembersArray = formState.groupCoaches.map(coach => {
        return {
          personId: coach.id,
          removeFromGroup: false
        };
      });

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_CITY, fieldValue: formState.city },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_STATE, fieldValue: formState.stateCode },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_ZIP, fieldValue: formState.zip },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_TRIP_PURPOSE, fieldValue: formState.tripPurpose }
      ], orgGroupPropertiesState.arrayData);

      const postClubGroupResult = await postClubGroup(newOrgGroupObj);

      if (postClubGroupResult?.objData?.orgGroupId) {
        const orgGroupId = postClubGroupResult.objData.orgGroupId;

        const putClubGroupMembersResult = await putClubGroupMembers(orgGroupId, newGroupMembersArray, COACH_ROLE_NAME);

        if (putClubGroupMembersResult) {
          navigate(NavLinks.CLUB_GROUPS);
        }
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  }

  return {
    isLoading: groupCategoryState.isArrayLoading || orgGroupPropertiesState.isArrayLoading,
    isSaving: clubGroupInfoState.isObjLoading || clubGroupMembersState.isObjLoading,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupTravelTripAdd;