import useNoSelfContactRelationshipTypeDropdown from './UseNoSelfContactRelationshipTypeDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css'

const NoSelfContactRelationshipTypeDropdown = ({ label, name, value, error, message, onChange, showNationalTeamOptions = false }) => {
  const { contactRelationshipTypeState } = useNoSelfContactRelationshipTypeDropdown();

  return contactRelationshipTypeState.message
    ? <span className={global.LoadingMsg}>{contactRelationshipTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={showNationalTeamOptions === true ? contactRelationshipTypeState.ntDataAsOptionsWithNames : contactRelationshipTypeState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={contactRelationshipTypeState.isArrayLoading}
      />
    );
};

export default NoSelfContactRelationshipTypeDropdown;