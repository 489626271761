import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import postMeetResultsData from './PostMeetResultsData';
import getMeetResultsUploadStatusData from './GetMeetResultsUploadStatusData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetResultsData = {
  INITIAL_STATE, postMeetResultsData, getMeetResultsUploadStatusData
};

export default MeetResultsData;