import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';

import { createGroupObjFromGroupInfoForm } from '../../utils/ClubGroupsUtils';

import NavLinks from '../../../NavLinks';

import useClubGroupsData from '../../../../../state/clubGroups/UseClubGroupsData';

import validate from '../../../../../../common/components/forms/groups/GroupInfoFormValidation';

import useGroupCategoryData from '../../../../../../common/state/groupCategory/UseGroupCategoryData';
import useBaseUsasClubData from '../../../../../../common/state/clubBase/UseBaseUsasClubData';

import useForm from '../../../../../../common/utils/UseForm';
import { DEFAULT_ID } from '../../../../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../../../../common/utils/HierarchicalDataUtils';

const INITIAL_STATE = {
  pageErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  orgUnitId: '',
  groupTypeId: DEFAULT_ID,
  groupTypeName: '',
  groupCategories: [],
  groupName: '',
  groupCode: '',
  groupDescription: ''
};

const useClubGroupListEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupCategoryState } = useGroupCategoryData();
  const { clubGroupInfoState, getClubGroupInfo, putClubGroup } = useClubGroupsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    async function loadClubGroup() {
      try {
        if (location.state?.orgGroupId) {
          if (baseUsasClubState.isObjLoaded === true && groupCategoryState.isArrayLoaded === true
            && baseUsasClubState.objData.orgUnitId === groupCategoryState.id) {
            const getClubGroupInfoResult = await getClubGroupInfo(location.state.orgGroupId);

            if (getClubGroupInfoResult) {
              const groupObj = getClubGroupInfoResult.objData;

              setFormData({
                ...formState,
                orgUnitId: baseUsasClubState.objData.orgUnitId,
                groupTypeId: groupObj.groupTypeId,
                groupTypeName: groupObj.groupType?.typeName || '',
                groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
                groupName: groupObj.groupName,
                groupCode: groupObj.groupCode,
                groupDescription: groupObj.groupDescription
              }, true);
            }
          }
        } else {
          navigate(NavLinks.CLUB_GROUPS);
        }
      } catch (e) {
        setState({
          ...state,
          pageErrorMessage: e.message
        });
      }
    }
    loadClubGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState]);

  async function submitFormCallback(formState) {
    try {
      const putClubGroupResult = await putClubGroup(clubGroupInfoState.objData.orgGroupId, createGroupObjFromGroupInfoForm(clubGroupInfoState.objData, formState));

      if (putClubGroupResult) {
        navigate(NavLinks.CLUB_GROUPS);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  }

  return {
    isLoading: groupCategoryState.isArrayLoading || clubGroupInfoState.isObjLoading,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onBackClicked,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupListEdit;