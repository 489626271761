import React, { Fragment } from 'react';

import ClubAthleteRosterGridLarge from './ClubAthleteRosterGridLarge';
import ClubAthleteRosterGridSmall from './ClubAthleteRosterGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';

   
const ClubAthleteRosterGrid = ({ gridData, isLoading, onEditAthlete, onVendorSyncCheckbox, onTransferCheckbox }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12">
            {gridData.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Athletes returned. Please add additional filters to narrow your results.</p>
              : <p>{gridData.length === 1 ? `${gridData.length} Athlete` : `${gridData.length} Athletes`}</p>
            }
          </div>
        </div>
      ) : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
    }
    <ClubAthleteRosterGridLarge
      gridData={gridData}
      isLoading={isLoading}
      onEditAthlete={onEditAthlete}
      onVendorSyncCheckbox={onVendorSyncCheckbox} 
      onTransferCheckbox={onTransferCheckbox}    
    />
    <ClubAthleteRosterGridSmall
      gridData={gridData}
      isLoading={isLoading}
      onEditAthlete={onEditAthlete}
      onVendorSyncCheckbox={onVendorSyncCheckbox} 
      onTransferCheckbox={onTransferCheckbox}
    />
  </Fragment>
);

export default ClubAthleteRosterGrid;