/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import validate from './ClubAthleteRosterFormValidation';

import useForm from "../../../../common/utils/UseForm";
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import useClubRosterTransferData from '../../../state/clubRosterTransfer/UseClubRosterTransferData';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }
];

const INITIAL_TRANSFER_POPUP_STATE = {
  displayModal: false
};

const useClubAthleteRosterForm = (onSubmitFormCallback, clearVendorSync, clearTransferState, transferState, searchClubAthleteRoster, baseUsasClubState) => {
  const { formState, errorState, setFormData, updateFormState, onValueTextPairChanged, handleSubmit } = useForm(getInitialFormState, onSubmitFormCallback, validate);
  const { clubRosterTransferState, postClubRosterTransfer} = useClubRosterTransferData();
  const [ transferPopupState, setTransferPopupState ] = useState(INITIAL_TRANSFER_POPUP_STATE);

  const onTransferModalCancelClicked = () => {
    setTransferPopupState(INITIAL_TRANSFER_POPUP_STATE);
  };

  const onTransferModalConfirmClicked = () => {
    let transferObjArray = [];
    transferState.transferPersons.forEach((member) => {
      const postObj = {
        personOrgRoleDurationId: member.personOrgRoleDurationId,
        transferOrgUnitId: member.transferOrgUnitId,
        transferSignature: '',
        transferSignatureDate: formatDate(new Date()),
        lastCompetitionDate: null
      };

      transferObjArray.push(postObj);

    });

    const postClubRosterTransferPromise = postClubRosterTransfer(transferObjArray);
      if (postClubRosterTransferPromise !== null) {
        postClubRosterTransferPromise
          .then((newState) => {
            if (newState !== null) {
              clearTransferState();
              searchClubAthleteRoster(
                baseUsasClubState.objData.usasClubId,
                createFilterObj(formState)
              );
            }
          })
          .catch((e) => {
            //TODO context error
          });
      }

      setTransferPopupState(INITIAL_TRANSFER_POPUP_STATE);
  };

  const onClearFilterClicked = () => {
    setFormData(getInitialFormState());
    clearVendorSync();
    clearTransferState();

    onSubmitFormCallback(getInitialFormState());
  };

  function createFilterObj() {
    return {
      competitionGenderTypeId:
        formState.competitionGenderTypeId > 0
          ? formState.competitionGenderTypeId
          : undefined,
      ageStart:
        formState.minAge.trim() !== '' ? formState.minAge.trim() : undefined,
      ageEnd:
        formState.maxAge.trim() !== '' ? formState.maxAge.trim() : undefined,
      firstName:
        formState.firstOrPreferredName.trim() !== ''
          ? formState.firstOrPreferredName.trim()
          : undefined,
      lastName:
        formState.lastName.trim() !== ''
          ? formState.lastName.trim()
          : undefined,
      practiceOrgGroupId:
        formState.practiceGroupId > 0 ? formState.practiceGroupId : undefined,
      clubSiteOrgGroupId:
        formState.siteGroupId > 0 ? formState.siteGroupId : undefined,
    };
  }

  const onTransferSelectedMembers = () => {
    setTransferPopupState({
      ...transferPopupState,
      displayModal: true
    });
  };

  const buildStringFromArrayOfObjects = (arrayName, propertyName, separator) => {
    let retVal = "";
    arrayName.forEach(item => {
      retVal = retVal + separator + " " + item[propertyName];
    });

    return retVal.slice(separator.length + 1);
  }

  function getInitialFormState() {
    return {
      competitionGenderTypeId: Constants.DEFAULT_ID,
      competitionGenderTypeName: '',
      minAge: '',
      maxAge: '',
      practiceGroupId: Constants.DEFAULT_ID,
      practiceGroupName: '',
      siteGroupId: Constants.DEFAULT_ID,
      siteGroupName: '',
      firstOrPreferredName: '',
      lastName: ''
    };
  }

  return {
    formState,
    errorState,
    transferPopupState,
    clubRosterTransferState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    onTransferSelectedMembers,
    onTransferModalCancelClicked,
    onTransferModalConfirmClicked,
    competitionGenderOptions,
    confirmationMessage: buildStringFromArrayOfObjects(transferState.transferPersons, "name", ",")
  };
};

export default useClubAthleteRosterForm;