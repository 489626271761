import Constants from "../../../../common/utils/Constants";
import { isValidDate } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.touchpointNote.trim().length === 0) {
    errors.touchpointNote = 'Touchpoint Note is required';
  } else if (formState.touchpointNote.trim().length > 5000) {
    errors.touchpointNote = 'Touchpoint Note cannot exceed 5000 characters';
  }

  if (formState.touchpointTypeId === Constants.DEFAULT_ID) {
    errors.touchpointTypeId = 'Touchpoint Type is required';
  }

  if (formState.touchpointDate === Constants.BLANK_DATE_STRING) {
    errors.touchpointDate = 'Touchpoint Date is required';
  } else if (!isValidDate(formState.touchpointDate)) {
    errors.touchpointDate = 'Touchpoint Date must be a valid date';
  }

  return errors;
};

const ClubTouchpointsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ClubTouchpointsDetailValidation;