import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  roleGroupId: Constants.DEFAULT_ID,
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  options: [],
  message: ''
};

const getOrgRoleMultiselectByOrgRoleId = (roleGroupId, state, setState) => {
  if (state.isArrayLoading !== true && roleGroupId > 0 && !state.message) {
    let newState = {
      ...state,
      roleGroupId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgRole/OrgRoleGroup/${roleGroupIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options: data.map((o) => { return { id: o.orgRoleId, name: o.orgRoleName, restrictions: o.restrictions }; }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const OrgRoleMultiselectData = { INITIAL_STATE, getOrgRoleMultiselectByOrgRoleId };

export default OrgRoleMultiselectData;