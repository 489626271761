import { useEffect } from 'react';

import useOrgRoleMultiselectData from '../../../state/orgRoleMultiselect/UseOrgRoleMultiselectData';

const useOrgRoleMultiselect = (roleGroupId) => {
  const { orgRoleMultiselectState, getOrgRoleMultiselect } = useOrgRoleMultiselectData();

  useEffect(() => {
    if (orgRoleMultiselectState.isArrayLoading !== true && roleGroupId > 0 && roleGroupId !== orgRoleMultiselectState.roleGroupId) {
      getOrgRoleMultiselect(roleGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleMultiselectState, roleGroupId]);

  return {
    orgRoleMultiselectState
  };
};

export default useOrgRoleMultiselect;