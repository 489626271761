import { useContext } from 'react';

import MemberAdministrationLscLifeMemberRenewalsData from './MemberAdministrationLscLifeMemberRenewalsData';
import { MemberAdministrationLscLifeMemberRenewalsStateContext } from '../../../../common/state/memberAdministration/lscLifeMemberRenewals/MemberAdministrationLscLifeMemberRenewalsContextProvider';

const useMemberAdministrationLscLifeMemberRenewalsData = () => {
  const [memberAdministrationLscLifeMemberRenewalsState, setMemberAdministrationLscLifeMemberRenewalsState] = useContext(MemberAdministrationLscLifeMemberRenewalsStateContext);

  const getLscLifeMemberRenewals = (orgUnitId) => MemberAdministrationLscLifeMemberRenewalsData.getLscLifeMemberRenewals(orgUnitId, memberAdministrationLscLifeMemberRenewalsState, setMemberAdministrationLscLifeMemberRenewalsState);
  const postLscLifeMemberRenewals = (orgUnitId, array) => MemberAdministrationLscLifeMemberRenewalsData.postLscLifeMemberRenewals(orgUnitId, array, memberAdministrationLscLifeMemberRenewalsState, setMemberAdministrationLscLifeMemberRenewalsState);

  const confirmSave = () => {
    setMemberAdministrationLscLifeMemberRenewalsState({
      ...memberAdministrationLscLifeMemberRenewalsState,
      isSaved: false
    });
  };

  const clearLscLifeMemberRenewalsArrayData = () => {
    setMemberAdministrationLscLifeMemberRenewalsState({
      ...memberAdministrationLscLifeMemberRenewalsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: []
    });
  }

  return {
    memberAdministrationLscLifeMemberRenewalsState,
    setMemberAdministrationLscLifeMemberRenewalsState,
    getLscLifeMemberRenewals,
    postLscLifeMemberRenewals,
    confirmSave,
    clearLscLifeMemberRenewalsArrayData
  };
};

export default useMemberAdministrationLscLifeMemberRenewalsData;