import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupPracticeValidation';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getGroupMemberIds, getUpdatedGroupMembers } from '../../utils/ClubGroupsUtils';
import { COACH_ROLE_NAME } from '../../../utils/ClubConstants';

import NavLinks from '../../../NavLinks';

import useClubGroupsData from '../../../../../state/clubGroups/UseClubGroupsData';

import useBaseUsasClubData from '../../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupCategoryData from '../../../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupFieldNamesData from '../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useGroupCoachesData from '../../../../../../common/state/groupCoaches/UseGroupCoachesData';
import useGroupFacilitiesData from '../../../../../../common/state/groupFacilities/UseGroupFacilitiesData';
import useOrgGroupPropertiesData from '../../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';

import useForm from '../../../../../../common/utils/UseForm';
import HierarchicalDataUtils from '../../../../../../common/utils/HierarchicalDataUtils';
import { DEFAULT_ID, BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';

const INITIAL_STATE = {
  pageErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  orgUnitId: '',
  groupTypeId: DEFAULT_ID,
  groupTypeName: '',
  groupCategories: [],
  groupName: '',
  groupCode: '',
  groupDescription: '',
  groupCoaches: [],
  facilityId: DEFAULT_ID,
  facilityName: '--',
  startDate: BLANK_DATE_STRING,
  endDate: BLANK_DATE_STRING
};

const useClubGroupPracticeEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupCategoryState } = useGroupCategoryData();
  const { groupCoachesState } = useGroupCoachesData();
  const { groupFacilitiesState } = useGroupFacilitiesData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { clubGroupInfoState, clubGroupMembersState, getClubGroupInfo, getClubGroupMembers,
    putClubGroup, putClubGroupMembers
  } = useClubGroupsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    try {
      if (location.state?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(location.state.groupTypeId);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function loadClubGroup() {
      try {
        if (location.state?.groupTypeId && location.state?.groupTypeId) {
          if (baseUsasClubState.isObjLoaded === true
            && groupCategoryState.isArrayLoaded === true && groupCategoryState.id === baseUsasClubState.objData.orgUnitId
            && groupCoachesState.isArrayLoaded === true && groupCoachesState.orgUnitId === baseUsasClubState.objData.orgUnitId
            && groupFacilitiesState.isArrayLoaded === true && groupFacilitiesState.orgUnitId === baseUsasClubState.objData.orgUnitId
            && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === location.state?.groupTypeId) {
            const getClubGroupInfoResult = await getClubGroupInfo(location.state.orgGroupId);

            if (getClubGroupInfoResult) {
              const groupObj = getClubGroupInfoResult.objData;
              const getClubGroupMembersResult = await getClubGroupMembers(location.state.orgGroupId);

              if (getClubGroupMembersResult) {
                const groupMembersArray = getClubGroupMembersResult.arrayData;
                const groupCoachIds = getGroupMemberIds(groupCoachesState.options, groupMembersArray);

                const facilityFieldValue = groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY)?.fieldValue;
                const targetFacility = groupFacilitiesState.options.find(x => `${x.id}` === facilityFieldValue);

                setFormData({
                  ...formState,
                  orgUnitId: groupObj.orgUnitId,
                  groupTypeId: groupObj.groupTypeId,
                  groupTypeName: groupObj.groupType?.typeName || '',
                  groupCategories: HierarchicalDataUtils.GetNameIdPairs(groupCategoryState.treeData, [groupObj.groupCategoryId]),
                  groupName: groupObj.groupName,
                  groupCode: groupObj.groupCode,
                  groupDescription: groupObj.groupDescription,
                  groupCoaches: HierarchicalDataUtils.GetNameIdPairs(groupCoachesState.options, groupCoachIds),
                  facilityId: targetFacility?.id || DEFAULT_ID,
                  facilityName: targetFacility?.name || '--',
                  startDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE)?.fieldValue,
                  endDate: groupObj.orgGroupFieldValue.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE)?.fieldValue
                }, true);
              }
            }
          }
        } else {
          navigate(NavLinks.CLUB_GROUPS);
        }
      } catch (e) {
        setState({
          ...state,
          pageErrorMessage: e.message
        });
      }
    }
    loadClubGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState, orgGroupPropertiesState, groupFacilitiesState, groupCoachesState]);

  async function submitFormCallback(formState) {
    try {
      const newOrgGroupObj = createGroupObjFromGroupInfoForm(clubGroupInfoState.objData, formState);
      const newGroupMembersArray = getUpdatedGroupMembers(formState.groupCoaches, groupCoachesState.options, clubGroupMembersState.arrayData);

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_START_DATE, fieldValue: formState.startDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_END_DATE, fieldValue: formState.endDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY, fieldValue: formState.facilityId }
      ], orgGroupPropertiesState.arrayData, clubGroupInfoState.objData.orgGroupId);

      const putClubGroupResult = await putClubGroup(clubGroupInfoState.objData.orgGroupId, newOrgGroupObj);

      if (putClubGroupResult) {
        if (newGroupMembersArray.length > 0) {
          const putClubGroupMembersResult = await putClubGroupMembers(clubGroupInfoState.objData.orgGroupId, newGroupMembersArray, COACH_ROLE_NAME);

          if (putClubGroupMembersResult) {
            navigate(NavLinks.CLUB_GROUPS);
          }
        } else {
          navigate(NavLinks.CLUB_GROUPS);
        }
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  }

  return {
    isLoading: groupCategoryState.isArrayLoading || orgGroupPropertiesState.isArrayLoading || clubGroupInfoState.isObjLoading || clubGroupMembersState.isArrayLoading || groupCoachesState.isArrayLoading || groupFacilitiesState.isArrayLoading || clubGroupMembersState.isObjLoading,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupPracticeEdit;