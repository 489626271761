import useReLoginForm from "./UseReLoginForm";

import PasswordInput from "../../../inputs/PasswordInput";
import ReadOnly from "../../../readOnly/ReadOnly";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";

import style from '../LoginForms.module.css';

const ReLoginForm = ({ submitButtonText = 'Login', secondaryButtonText = 'Cancel', username = '',
  verticalButtons = false, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onValueChanged
  } = useReLoginForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <ReadOnly
            label={'Username'}
            name={'username'}
            value={username} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PasswordInput
            label={'Password'}
            name={'password'}
            value={formState.password}
            error={errorState.password}
            message={errorState.password}
            autoFocus={true}
            onChange={(value) => onValueChanged('Password', 'password', value)} />
        </div>
        <div className={["col-xs-12 usas-extra-top-margin", style.FormButtons, verticalButtons ? style.Vertical : ''].join(' ')}>
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          <SecondaryButton onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ReLoginForm;