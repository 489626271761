import { useEffect, useState } from "react";

import useLscLifeMemberNewData from "../../../common/state/memberAdministration/lscLifeMemberNew/UseLscLifeMemberNewData";
import useEnvironmentVariableData from "../../../common/state/environmentVariable/UseEnvironmentVariableData";

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: `New Life Member - Added to Payee's Shopping Cart Confirmation`
};

const UseNewLifeMember = () => {
  const { lscLifeMemberNewState, postLscLifeMemberNew, confirmSave } = useLscLifeMemberNewData();
  const { environmentVariableState, LIFE_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const [formState, setFormState] = useState({});

  const onFormSubmit = (formState) => {
    setFormState(formState);
    postLscLifeMemberNew(formState.orgUnitId, createNewLscLifeMemberObj(formState));
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({});
    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (lscLifeMemberNewState.isSaved === true &&
      lscLifeMemberNewState.isObjLoading === false &&
      lscLifeMemberNewState.isSaving === false) {
      confirmSave();
      setModalState({ ...modalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscLifeMemberNewState])

  function createNewLscLifeMemberObj(formState) {
    const orgRole = formState.memberTypes?.map(x => { return ({ orgRoleId: x.id }) })

    return {
      personId: formState.personId,
      paymentPersonId: formState.payeePersonId,
      orgRole: orgRole
    }
  }

  return {
    lscLifeMemberNewState,
    isSaving: lscLifeMemberNewState.isSaving,
    isLoading: environmentVariableState.isLoading,
    formState,
    onFormSubmit,
    LIFE_ROLES_ROLE_GROUP_ID,
    modalState,
    onModalCanceled
  };
};

export default UseNewLifeMember;