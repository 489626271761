import { createContext, useState } from 'react';

import OrgRoleMultiselectData from './OrgRoleMultiselectData';

export const OrgRoleMultiselectStateContext = createContext();

const OrgRoleMultiselectContextProvider = ({ children }) => {
  const stateHook = useState(OrgRoleMultiselectData.INITIAL_STATE);

  return (
    <OrgRoleMultiselectStateContext.Provider value={stateHook}>
      {children}
    </OrgRoleMultiselectStateContext.Provider>
  );
};

export default OrgRoleMultiselectContextProvider;