import { Fragment } from 'react';

import useClubGroups from './UseClubGroups';

import ClubGroupsGrid from './components/grids/clubGroups/ClubGroupsGrid';
import ClubGroupsLoadingModal from './components/dialogs/loadingModal/ClubGroupsLoadingModal';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import { SAVING_MSG } from '../../../../common/utils/Constants';

const ClubGroups = () => {
  const {
    isLoading,
    isSaving,
    gridData,
    state,
    onAddClubGroupClicked,
    onEditClubGroupClicked,
    onDeleteClubGroupClicked,
    onEditClubGroupMembersClicked,
    onDeleteModalConfirmClicked,
    onModalCancelClicked
  } = useClubGroups();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Club Groups</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddClubGroupClicked}>Add Group</ActionIntraPageButton>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ClubGroupsGrid
            data={gridData}
            isLoading={isLoading}
            onEditClicked={onEditClubGroupClicked}
            onDeleteClicked={onDeleteClubGroupClicked}
            onEditMembersClicked={onEditClubGroupMembersClicked} />
        </div>
      </div>
      <PopUpModal
        title={'Delete this group?'}
        displayPopUp={state.displayDeletePopup === true}
        widthPct={90}
        maxWidth={720}
        onModalCanceled={onModalCancelClicked}>
        <div className='row'>
          <div className='col-xs-12'>
            <p><b>Group Name:</b> {state.deletePopupGroupObj?.groupName}</p>
          </div>
        </div>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12'>
            <PrimaryButton type="button" onClick={onDeleteModalConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <ClubGroupsLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default ClubGroups;