import React, { Fragment } from 'react';

import ClubNonAthleteRosterGridLarge from './ClubNonAthleteRosterGridLarge';
import ClubNonAthleteRosterGridSmall from './ClubNonAthleteRosterGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';
import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const ClubNonAthleteRosterGrid = ({ gridData, isLoading, onEditNonAthlete, onVendorSyncCheckbox, onTransferCheckbox }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      {isLoading === false
        ? (
          <div className="row">
            <div className="col-xs-12">
              {gridData.length > 499
                ? <p className={global.ErrorMessage}>More than 500 Non Athletes returned. Please add additional filters to narrow your results.</p>
                : <p>{gridData.length === 1 ? `${gridData.length} Non Athlete` : `${gridData.length} Non Athletes`}</p>
              }
            </div>
          </div>
        ) : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
      }
      <ClubNonAthleteRosterGridLarge
        gridData={gridData}
        isLoading={isLoading}
        onEditNonAthlete={onEditNonAthlete}
        expandedId={expandedId}
        onClick={onClick} 
        onVendorSyncCheckbox={onVendorSyncCheckbox}
        onTransferCheckbox={onTransferCheckbox}
      />
      <ClubNonAthleteRosterGridSmall
        gridData={gridData}
        isLoading={isLoading}
        onEditNonAthlete={onEditNonAthlete}
        expandedId={expandedId}
        onClick={onClick} 
        onVendorSyncCheckbox={onVendorSyncCheckbox}
        onTransferCheckbox={onTransferCheckbox}
      />
    </Fragment>
  );
}

export default ClubNonAthleteRosterGrid;