import { Fragment } from 'react';

import useClubGroupMembers from './UseClubGroupMembers';

import ClubGroupInfoHeader from '../components/headers/clubGroupInfoHeader/ClubGroupInfoHeader';
import ClubGroupsLoadingModal from '../components/dialogs/loadingModal/ClubGroupsLoadingModal';
import ClubGroupAthletesFormGrid from '../components/grids/clubGroupAthletesForm/ClubGroupAthletesFormGrid';
import ClubGroupConfirmationFormGrid from '../components/grids/clubGroupConfirmationForm/ClubGroupConfirmationFormGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

const ClubGroupMembers = () => {
  const {
    isLoading,
    isGridLoading,
    groupObj,
    groupMembersArray,
    groupAthletesArray,
    state,
    onErrorCaught,
    onBackClicked,
    onSubmitAthleteFormGrid,
    onSubmitConfirmationFormGrid,
    onConfirmationModalCancelClicked
  } = useClubGroupMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add/Edit Group Athletes</Headings.H3>
        </div>
      </div>
      <ClubGroupInfoHeader groupObj={groupObj} groupMembersArray={groupMembersArray} />
      <ClubGroupAthletesFormGrid
        gridData={groupAthletesArray}
        isLoading={isGridLoading}
        onSubmitFormCallback={onSubmitAthleteFormGrid}
        onSecondaryButtonClicked={onBackClicked}
        onErrorCaught={onErrorCaught} />
      {state.displayConfirmationModal &&
        <PopUpModal
          title={'Confirm Group Updates'}
          displayPopUp={state.displayConfirmationModal === true}
          widthPct={90}
          onModalCanceled={onConfirmationModalCancelClicked}>
          <ClubGroupConfirmationFormGrid
            gridData={state.updatedGroupAthletes}
            isLoading={false}
            groupName={groupObj.groupName}
            onSubmitFormCallback={onSubmitConfirmationFormGrid}
            onSecondaryButtonClicked={onConfirmationModalCancelClicked}
            onErrorCaught={onErrorCaught} />
        </PopUpModal>
      }
      <ClubGroupsLoadingModal
        isLoading={isLoading}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default ClubGroupMembers;