import { useEffect } from "react";

import validate from './NewLifeMemberFormValidation';

import UseForm from "../../../common/utils/UseForm";
import Constants from "../../../common/utils/Constants";

const UseNewLifeMemberForm = (savedFormState, onSubmitFormCallback, LIFE_ROLES_ROLE_GROUP_ID) => {
  const { formState, errorState, isSubmitting, setIsDirty, handleSubmit, updateFormState, onValueTextPairChanged, setFormState, setErrors
  } = UseForm(getInitialFormState, onSubmitFormCallback, validate);

  const resetForm = () => {
    setFormState(getInitialFormState);
    setErrors({})
    setIsDirty(false);
  };

  useEffect(() => {
    setFormState({
      ...formState,
      ...savedFormState
    });
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(savedFormState).length === 0) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFormState]);

  useEffect(() => {
    if (formState.lscOrgUnitId === Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL_LSC) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC
      });
    }
    else if (formState.lscOrgUnitId !== Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.lscOrgUnitId]);

  function getInitialFormState() {
    return {
      memberId: '',
      personId: '',
      payeeMemberId: '',
      payeePersonId: '',
      memberTypes: [],
      lscOrgUnitId: Constants.DEFAULT_ID,
      lscOrgUnitName: '--',
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC,
      LIFE_ROLES_ROLE_GROUP_ID: LIFE_ROLES_ROLE_GROUP_ID
    };
  }

  return {
    formState,
    errorState,
    isSubmitting,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    setFormState,
    resetForm
  };
};

export default UseNewLifeMemberForm;