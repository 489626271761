import { Fragment } from 'react';

import useClubGroupsSelectGroupType from './UseClubGroupsSelectGroupType';

import ClubGroupsLoadingModal from '../components/dialogs/loadingModal/ClubGroupsLoadingModal';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import RadiobuttonList from '../../../../../common/components/radiobuttonlists/RadiobuttonList';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ClubGroupsSelectGroupType = () => {
  const {
    isLoading,
    state,
    formState,
    errorState,
    onSelectedGroupTypeChange,
    onContinueClicked,
    onBackClicked
  } = useClubGroupsSelectGroupType();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          {state.orgGroupTypes.length === 0
            ? <p>Loading...</p>
            : <RadiobuttonList
              label={"Select Group Type"}
              name={"selectedGroupType"}
              items={state.orgGroupTypes}
              error={errorState.selectedGroupType}
              message={errorState.selectedGroupType}
              value={formState.selectedGroupType}
              isVertical={true}
              onChange={(value) => { onSelectedGroupTypeChange(value) }} />
          }
        </div>
        <div className="col-xs-12 col-sm-6">
          <div>
            <p><b>Group Type Description:</b></p>
            <p>{formState.selectedGroupTypeDescription}</p>
          </div>
        </div>
      </div>
      {state.continueErrorMessage &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{state.continueErrorMessage}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <ClubGroupsLoadingModal
        isLoading={isLoading}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default ClubGroupsSelectGroupType;
