import DeleteIcon from '../../../../../../../common/components/icons/DeleteIcon';
import SGColumnHeader from '../../../../../../../common/components/grids/SGColumnHeader';

import SGConstants from '../../../../../../../common/utils/SGConstants';
import useSortableGrid from '../../../../../../../common/utils/UseSortableGrid';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthlete, onUpdateIsSelected }) => (
  <tr>
    <td style={{ width: '40%' }}>{groupAthlete.firstName || ''} {groupAthlete.lastName || ''}</td>
    <td style={{ width: '30%' }}>{groupAthlete.competitionGenderTypeName || ''}</td>
    <td style={{ width: '10%' }}>{groupAthlete.age || ''}</td>
    <td style={{ width: '20%' }}>
      <button className={global.IconButtonMargin} type="button" id={groupAthlete?.personId} onClick={(e) => onUpdateIsSelected(e, groupAthlete?.personId)}>
        <DeleteIcon toolTipText='Remove Athlete From Group' />
      </button>
    </td>
  </tr>
);

const ClubGroupConfirmationFormGridAdd = ({ gridData, onUpdateIsSelected }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={global.UsasTable}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age'} columnField={'age'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th>Remove Athlete</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map((groupAthlete, i) =>
            <GridRow key={i} groupAthlete={groupAthlete} onUpdateIsSelected={onUpdateIsSelected} />)
          : <tr><td colSpan="4">No Added Athletes</td></tr>
        }
      </tbody>
    </table>
  );
}

export default ClubGroupConfirmationFormGridAdd;