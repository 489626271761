import GridFriendlyTreeView from '../tree/GridFriendlyTreeView';
import useMultiSelect from './UseMultiSelect';

const GridFriendlyMultiSelect = ({ label, name, value, error, message, options, onChange, 
    isLoading, className }) => {
  const {formattedOptions} = useMultiSelect(options)
  return (
    <GridFriendlyTreeView 
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      options={formattedOptions}
      selectableLeavesOnly={true}
      singleSelect={false}
      onChange={onChange}
      isLoading={isLoading}
      className={className} />);
}

export default GridFriendlyMultiSelect;