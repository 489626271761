import { Fragment } from 'react';

import useLeftNav from './UseLeftNav';

import LeftNavItem from './LeftNavItem';

import Headings from '../headings/Headings';

import style from './LeftNav.module.css';

const LeftNav = ({ title, state, formattedTitle, children, validateBeforeRedirect }) => {
  const { items, onClick } = useLeftNav(state);

  return (
    <div className="row">
      <div className="col-lg-3">
        <ul tabIndex="0" className={style.LeftNav}>
          {formattedTitle ? <li>{formattedTitle}</li>
            : title ? <li className={style.Title}><Headings.H5>{title}</Headings.H5></li> : <Fragment />}
          {items.map((item, i) => <LeftNavItem key={i} item={item} onClick={(e) => onClick(e, item.path, validateBeforeRedirect)} />)}
        </ul>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
      </div>
      <div className="col-lg-9">
        {children}
      </div>
    </div>
  );
};

export default LeftNav;