import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import UploadIcon from "../../../../common/components/icons/UploadIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import FileExclamationIcon from "../../../../common/components/icons/FileExclamationIcon";
import ViewDashboardIcon from '../../../../common/components/icons/ViewDashboardIcon';
import DollarSignIcon from '../../../../common/components/icons/DollarSignIcon';
import ReportIcon from "../../../../common/components/icons/ReportIcon";
import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";
import { formatDate } from "../../../../common/utils/DateFunctions";
import useSortableGrid from "../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../common/utils/SGConstants";
import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ meet, onUploadClicked, onDelete, onEdit, onMeetReconClicked, onViewReport, onViewMeetAnnouncementReport, onGoToCart }) => (
  <tr>
    <td>{meet.meetName}</td>
    <td>{`${formatDate(meet.startDate)} - ${formatDate(meet.endDate)}`}</td>
    <td>{meet.meetClassification}</td>
    <td>{meet.meetType}</td>
    <td>{meet.status}</td>
    <td>
      <Fragment>
        {meet.isPastMeet === true && meet.status === 'Paid' &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onUploadClicked(meet)}>
              <UploadIcon />
            </button>
          </>
        }
        {meet.status === 'Paid' &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onMeetReconClicked(meet)}>
              <FileExclamationIcon toolTipText="Meet Recon" />
            </button>
          </>
        }
        {onEdit !== undefined && meet.hasResults === false && meet.hasCartItem === false &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onEdit(meet)}>
              <EditIcon toolTipText="View / Edit" />
            </button>
          </>
        }
        {meet.hasCartItem === true &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onGoToCart()}>
              <DollarSignIcon />
            </button>
          </>
        }
        {onEdit !== undefined && meet.hasResults === false && meet.status === 'Pending' && meet.hasCartItem === false &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onDelete(meet)}>
              <DeleteIcon />
            </button>
          </>
        }
        {onViewReport !== undefined && meet.hasResults === true && 
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onViewReport(meet)}>
              <ViewDashboardIcon />
            </button>
          </>
        }
        {onViewMeetAnnouncementReport != undefined && meet.status === 'Paid' &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onViewMeetAnnouncementReport(meet)}>
              <ReportIcon />
            </button>
          </>
        }
      </Fragment>
    </td>
  </tr>
);

const SmallGridRow = ({
  meet,
  onUploadClicked,
  onDelete,
  onEdit,
  onMeetReconClicked,
  onViewReport,
  onViewMeetAnnouncementReport,
  onGoToCart
}) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{meet.meetName}</span>&nbsp;
      <Fragment>
        {onDelete !== undefined && meet.hasResults === false && meet.status === 'Pending' && meet.hasCartItem === false &&
          <button
            className={global.IconButton}
            type="button"
            onClick={() => onDelete(meet)}>
            <DeleteIcon />
          </button>
        }
        {onEdit !== undefined && meet.hasResults === false && meet.hasCartItem === false &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onEdit(meet)}>
            <EditIcon />
          </button>
        }
        {meet.isPastMeet === true && meet.status === 'Paid' &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onUploadClicked(meet)}>
            <UploadIcon />
          </button>
        }
        {meet.status === 'Paid' &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onMeetReconClicked(meet)}>
            <FileExclamationIcon toolTipText="Meet Recon" />
          </button>
        }
        {onViewReport !== undefined && meet.hasResults === true &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onViewReport(meet)}>
            <ViewDashboardIcon />
          </button>
        }
        {onViewMeetAnnouncementReport != undefined && meet.status === 'Paid' &&
          <>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onViewMeetAnnouncementReport(meet)}>
              <ReportIcon />
            </button>
          </>
        }
        {onGoToCart !== undefined && meet.hasCartItem === true &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onGoToCart()}>
            <DollarSignIcon />
          </button>
        }
        
      </Fragment>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Meet Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{meet.meetName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Host Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.orgUnitName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Dates</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{`${formatDate(meet.startDate)} - ${formatDate(meet.endDate)}`}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Classification</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.meetClassification}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.meetType}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.status}</div>
      </div>
    </div>
  </div>
);

const LargeGrid = ({
  data,
  isLoading,
  onUploadClicked,
  onDelete,
  onEdit,
  onMeetReconClicked,
  onViewReport,
  onViewMeetAnnouncementReport,
  onGoToCart }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Dates'} columnField={'startDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Classification'} columnField={'meetClassification'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Type'} columnField={'meetType'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Status'} columnField={'status'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="7">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((meet, i) =>
              <LargeGridRow
                key={i}
                meet={meet}
                onUploadClicked={onUploadClicked}
                onDelete={onDelete}
                onEdit={onEdit}
                onMeetReconClicked={onMeetReconClicked}
                onViewReport={onViewReport}
                onViewMeetAnnouncementReport={onViewMeetAnnouncementReport}
                onGoToCart={onGoToCart} />)
            : <tr><td colSpan="7">No Meets</td></tr>
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({
  data,
  isLoading,
  onUploadClicked,
  onDelete,
  onEdit,
  onMeetReconClicked,
  onViewReport,
  onViewMeetAnnouncementReport,
  onGoToCart
}) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((meet, i) =>
          <SmallGridRow
            key={i}
            meet={meet}
            onUploadClicked={onUploadClicked}
            onDelete={onDelete}
            onEdit={onEdit}
            onMeetReconClicked={onMeetReconClicked}
            onViewReport={onViewReport}
            onViewMeetAnnouncementReport={onViewMeetAnnouncementReport}
            onGoToCart={onGoToCart}
          />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meets</div>
            </div>
          </div>
        )
    }
  </div>
);

const ClubBlockPartyMeetsGrid = ({ 
  data, isLoading, onUploadClicked, onDelete, onEdit, onMeetReconClicked, onViewReport, onViewMeetAnnouncementReport, onGoToCart }) => (
  <Fragment>
    <LargeGrid
      data={data}
      isLoading={isLoading}
      onUploadClicked={onUploadClicked}
      onDelete={onDelete}
      onEdit={onEdit}
      onMeetReconClicked={onMeetReconClicked}
      onViewReport={onViewReport}
      onViewMeetAnnouncementReport={onViewMeetAnnouncementReport}
      onGoToCart={onGoToCart} />
    <SmallGrid
      data={data}
      isLoading={isLoading}
      onUploadClicked={onUploadClicked}
      onDelete={onDelete}
      onEdit={onEdit}
      onMeetReconClicked={onMeetReconClicked}
      onViewReport={onViewReport}
      onViewMeetAnnouncementReport={onViewMeetAnnouncementReport}
      onGoToCart={onGoToCart}
    />
  </Fragment>
);

export default ClubBlockPartyMeetsGrid;