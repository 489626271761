import { useState } from 'react';

import ClubGroupData from './ClubGroupsData';

const useClubGroupsData = () => {
  const [clubGroupsState, setClubGroupsState] = useState(ClubGroupData.INITIAL_CLUB_GROUPS_STATE);
  const [clubGroupInfoState, setClubGroupInfoState] = useState(ClubGroupData.INITIAL_CLUB_GROUP_INFO_STATE);
  const [clubGroupMembersState, setClubGroupMembersState] = useState(ClubGroupData.INITIAL_CLUB_GROUP_MEMBERS_STATE);
  const [clubGroupPotentialAthletesState, setClubGroupPotentialAthletesState] = useState(ClubGroupData.INITIAL_CLUB_GROUP_POTENTIAL_ATHLETES_STATE);

  const postClubGroup = (orgGroupObj) => {
    return ClubGroupData.postClubGroupData(orgGroupObj, clubGroupInfoState, setClubGroupInfoState);
  };

  const getClubGroups = (orgUnitId) => {
    return ClubGroupData.getClubGroupsData(orgUnitId, clubGroupsState, setClubGroupsState);
  };

  const getClubGroupInfo = (orgGroupId) => {
    return ClubGroupData.getClubGroupInfoData(orgGroupId, clubGroupInfoState, setClubGroupInfoState);
  };

  const getClubGroupMembers = (orgGroupId) => {
    return ClubGroupData.getClubGroupMembersData(orgGroupId, clubGroupMembersState, setClubGroupMembersState);
  };

  const getClubGroupPotentialAthletes = (orgGroupId) => {
    return ClubGroupData.getClubGroupPotentialAthletesData(orgGroupId, clubGroupPotentialAthletesState, setClubGroupPotentialAthletesState);
  };

  const putClubGroup = (orgGroupId, orgGroupObj) => {
    return ClubGroupData.putClubGroupData(orgGroupId, orgGroupObj, clubGroupInfoState, setClubGroupInfoState);
  };

  const putClubGroupMembers = (orgGroupId, groupMembersArray, groupAttendeeTypeName) => {
    return ClubGroupData.putClubGroupMembersData(orgGroupId, groupMembersArray, groupAttendeeTypeName, clubGroupMembersState, setClubGroupMembersState);
  };

  const deleteClubGroup = (orgGroupId) => {
    return ClubGroupData.deleteClubGroupData(orgGroupId, clubGroupInfoState, setClubGroupInfoState);
  };

  return {
    clubGroupsState,
    clubGroupInfoState,
    clubGroupMembersState,
    clubGroupPotentialAthletesState,
    postClubGroup,
    getClubGroups,
    getClubGroupInfo,
    getClubGroupMembers,
    getClubGroupPotentialAthletes,
    putClubGroup,
    putClubGroupMembers,
    deleteClubGroup
  };
};

export default useClubGroupsData;