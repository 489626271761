import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // add validation only
  if (!(formState.personId)) {
    if (formState.memberId.trim().length === 0) {
      errors.memberId = 'Staff Member Id is required';
    } else if (isValidMemberId(formState.memberId.trim()) === false) {
      errors.memberId = 'Staff Member Id must be 14 alphanumeric characters';
    }
  }

  if (formState.personOrgRole.length === 0 && formState.registrationRoles.length === 0) {
    errors.personOrgRole = 'Must select at least one staff role';
  } else if (formState.personOrgRole.length > 0 && formState.personOrgRole.some(x =>
  (x.id === formState.headCoachOrgRoleId ||
    x.id === formState.assistantCoachOrgRoleId ||
    x.id === formState.ageGroupCoachOrgRoleId ||
    x.id === formState.seniorCoachOrgRoleId)) && formState.coachTypeCode === '') {
    errors.coachTypeCode = 'Must make a selection';
  }

  return errors;
};

export const serverValidate = async (formState) => {
  let errors = {};

  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  const roleGroupIdForUrl = formState.clubStaffRegistrationRoleGroupId ? encodeURI(formState.clubStaffRegistrationRoleGroupId) : 'NaN';
  await PersonHttpHelper(`/Person/member/${memberIdForUrl}/hasActiveRegistration?roleGroupId=${roleGroupIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        const isCoach = formState.personOrgRole.some(x =>
          (x.id === formState.headCoachOrgRoleId ||
            x.id === formState.assistantCoachOrgRoleId ||
            x.id === formState.ageGroupCoachOrgRoleId ||
            x.id === formState.seniorCoachOrgRoleId));

        if (isCoach && objData.hasActiveCoachRegistration === false) {
          errors.apiError = "This person does not have an active coach registration";
        } else if (objData.hasActiveRegistration === false) {
          errors.apiError = "This person does not have an active registration";
        } else {
          formState.postPersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.apiError = "This person does not have an active registration";
    });

  return errors;
};

const ClubStaffDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  //server validate only done if local validate succeeds and is not an edit
  if ((!errors || Object.keys(errors).length === 0) && !(formState.personId)) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default ClubStaffDetailValidation;