import { isValidDate } from '../../../../utils/validation';
import Constants from '../../../../utils/Constants';
import shared from './Shared';

const TIME_TYPE_INDIVIDUAL = "INDIVIDUAL";
const TIME_TYPE_RELAY = "RELAY";

const RESULT_STATUS_DSQ = "DSQ";
const RESULT_STATUS_DNS = "DNS";
const RESULT_STATUS_DNF = "DNF";
const RESULT_STATUS_SICK = "SICK";
const RESULT_STATUS_WDR = "WDR";

const ROUND_FINAL = "FIN";
const ROUND_SEMIFINAL = "SEM";
const ROUND_QUARTERFINAL = "QUA";
const ROUND_PRELIM = "PRE";
const ROUND_SWIMOFF_SOP = "SOP";
const ROUND_SWIMOFF_SOS = "SOS";
const ROUND_SWIMOFF_SOQ = "SOQ";
const ROUND_TIMED_FINAL = "TIM";
const ROUND_FASTEST_HEATS_TIME = "FHT";

const LEF_STROKE_BACK = "BACK";
const LEF_STROKE_BREAST = "BREAST";
const LEF_STROKE_FLY = "FLY";
const LEF_STROKE_FREE = "FREE"
const LEF_STROKE_MEDLEY = "MEDLEY";

const LEF_GENDER_MALE = "M";
const LEF_GENDER_FEMALE = "F";

const sessionCodesMap = {
  'TIM': { code: 'T' },
  'FHT': { code: 'T' },
  'QUA': { code: 'Q' },
  'SEM': { code: 'E' },
  'SOP': { code: 'S' },
  'SOS': { code: 'S' },
  'SOQ': { code: 'S' },
  'PRE': { code: 'P' },
  'FIN': { code: 'F' }
};

const strokeCodesMap = {
  'FREE': { code: 'FR' },
  'BREAST': { code: 'BR' },
  'BACK': { code: 'BK' },
  'FLY': { code: 'FL' },
  'MEDLEY': { code: 'IM' }
}

const relayStrokeCodesMap = {
  'FREE': { code: 'FR-R' },
  'MEDLEY': { code: 'MED-R' }
}

const continentFromCountryCodeMap = {
  'ALG': { continent: 'AFRICA' },
  'ANG': { continent: 'AFRICA' },
  'DZA': { continent: 'AFRICA' },
  'AGO': { continent: 'AFRICA' },
  'BWA': { continent: 'AFRICA' },
  'IOT': { continent: 'AFRICA' },
  'BDI': { continent: 'AFRICA' },
  'BOT': { continent: 'AFRICA' },
  'BUR': { continent: 'AFRICA' },
  'CMR': { continent: 'AFRICA' },
  'CPV': { continent: 'AFRICA' },
  'CAF': { continent: 'AFRICA' },
  'CGO': { continent: 'AFRICA' },
  'TCD': { continent: 'AFRICA' },
  'COM': { continent: 'AFRICA' },
  'MYT': { continent: 'AFRICA' },
  'CHA': { continent: 'AFRICA' },
  'COG': { continent: 'AFRICA' },
  'COD': { continent: 'AFRICA' },
  'BEN': { continent: 'AFRICA' },
  'CBS': { continent: 'AFRICA' },
  'GNQ': { continent: 'AFRICA' },
  'ETH': { continent: 'AFRICA' },
  'ERI': { continent: 'AFRICA' },
  'ATF': { continent: 'AFRICA' },
  'DJI': { continent: 'AFRICA' },
  'GAB': { continent: 'AFRICA' },
  'GAM': { continent: 'AFRICA' },
  'GBS': { continent: 'AFRICA' },
  'GEQ': { continent: 'AFRICA' },
  'GMB': { continent: 'AFRICA' },
  'GHA': { continent: 'AFRICA' },
  'GIN': { continent: 'AFRICA' },
  'CIV': { continent: 'AFRICA' },
  'KEN': { continent: 'AFRICA' },
  'LBA': { continent: 'AFRICA' },
  'LES': { continent: 'AFRICA' },
  'LSO': { continent: 'AFRICA' },
  'LBR': { continent: 'AFRICA' },
  'LBY': { continent: 'AFRICA' },
  'MAD': { continent: 'AFRICA' },
  'MDG': { continent: 'AFRICA' },
  'MWI': { continent: 'AFRICA' },
  'MLI': { continent: 'AFRICA' },
  'MRT': { continent: 'AFRICA' },
  'MTN': { continent: 'AFRICA' },
  'MUS': { continent: 'AFRICA' },
  'MAR': { continent: 'AFRICA' },
  'MAS': { continent: 'AFRICA' },
  'MAW': { continent: 'AFRICA' },
  'MOZ': { continent: 'AFRICA' },
  'MRI': { continent: 'AFRICA' },
  'NAM': { continent: 'AFRICA' },
  'NER': { continent: 'AFRICA' },
  'NGA': { continent: 'AFRICA' },
  'NGR': { continent: 'AFRICA' },
  'GNB': { continent: 'AFRICA' },
  'REU': { continent: 'AFRICA' },
  'RSA': { continent: 'AFRICA' },
  'RWA': { continent: 'AFRICA' },
  'SHN': { continent: 'AFRICA' },
  'STP': { continent: 'AFRICA' },
  'SEN': { continent: 'AFRICA' },
  'SEY': { continent: 'AFRICA' },
  'SYC': { continent: 'AFRICA' },
  'SLE': { continent: 'AFRICA' },
  'SOM': { continent: 'AFRICA' },
  'TPE': { continent: 'AFRICA' },
  'ZAF': { continent: 'AFRICA' },
  'ZWE': { continent: 'AFRICA' },
  'SSD': { continent: 'AFRICA' },
  'SDN': { continent: 'AFRICA' },
  'ESH': { continent: 'AFRICA' },
  'SWZ': { continent: 'AFRICA' },
  'TAN': { continent: 'AFRICA' },
  'TGO': { continent: 'AFRICA' },
  'TUN': { continent: 'AFRICA' },
  'UGA': { continent: 'AFRICA' },
  'EGY': { continent: 'AFRICA' },
  'TZA': { continent: 'AFRICA' },
  'BFA': { continent: 'AFRICA' },
  'ZAM': { continent: 'AFRICA' },
  'ZMB': { continent: 'AFRICA' },
  'ZIM': { continent: 'AFRICA' },

  'ATA': { continent: 'ANTARCTICA' },
  'BVT': { continent: 'ANTARCTICA' },
  'SGS': { continent: 'ANTARCTICA' },
  'HMD': { continent: 'ANTARCTICA' },

  'AFG': { continent: 'ASIA' },
  'AZE': { continent: 'ASIA' },
  'BHR': { continent: 'ASIA' },
  'BAN': { continent: 'ASIA' },
  'BGD': { continent: 'ASIA' },
  'BHU': { continent: 'ASIA' },
  'ARM': { continent: 'ASIA' },
  'BTN': { continent: 'ASIA' },
  'BRN': { continent: 'ASIA' },
  'BRU': { continent: 'ASIA' },
  'MMR': { continent: 'ASIA' },
  'KHM': { continent: 'ASIA' },
  'LKA': { continent: 'ASIA' },

  'CAM': { continent: 'ASIA' },
  'CHN': { continent: 'ASIA' },
  'TWN': { continent: 'ASIA' },
  'CXR': { continent: 'ASIA' },
  'CCK': { continent: 'ASIA' },
  'CYP': { continent: 'ASIA' },
  'GEO': { continent: 'ASIA' },
  'HKG': { continent: 'ASIA' },
  'IND': { continent: 'ASIA' },
  'IDN': { continent: 'ASIA' },
  'INA': { continent: 'ASIA' },
  'IRI': { continent: 'ASIA' },
  'IRN': { continent: 'ASIA' },
  'IRQ': { continent: 'ASIA' },
  'ISR': { continent: 'ASIA' },
  'JPN': { continent: 'ASIA' },
  'KAZ': { continent: 'ASIA' },
  'KSA': { continent: 'ASIA' },
  'KUW': { continent: 'ASIA' },
  'JOR': { continent: 'ASIA' },
  'PRK': { continent: 'ASIA' },
  'KOR': { continent: 'ASIA' },
  'KWT': { continent: 'ASIA' },
  'KGZ': { continent: 'ASIA' },
  'LAO': { continent: 'ASIA' },
  'LBN': { continent: 'ASIA' },
  'MAC': { continent: 'ASIA' },
  'MYS': { continent: 'ASIA' },
  'MDV': { continent: 'ASIA' },
  'MGL': { continent: 'ASIA' },
  'MGN': { continent: 'ASIA' },
  'MYA': { continent: 'ASIA' },
  'OMA': { continent: 'ASIA' },
  'OMN': { continent: 'ASIA' },
  'NEP': { continent: 'ASIA' },
  'NPL': { continent: 'ASIA' },
  'PAK': { continent: 'ASIA' },
  'PSE': { continent: 'ASIA' },
  'PHI': { continent: 'ASIA' },
  'PHL': { continent: 'ASIA' },
  'PLE': { continent: 'ASIA' },
  'TLS': { continent: 'ASIA' },
  'QAT': { continent: 'ASIA' },
  'ROC': { continent: 'ASIA' },
  'RUS': { continent: 'ASIA' },
  'EUN': { continent: 'ASIA' }, //united russia
  'SAU': { continent: 'ASIA' },
  'SGP': { continent: 'ASIA' },
  'VNM': { continent: 'ASIA' },
  'SRI': { continent: 'ASIA' },
  'SYR': { continent: 'ASIA' },
  'TJK': { continent: 'ASIA' },
  'THA': { continent: 'ASIA' },
  'ARE': { continent: 'ASIA' },
  'TUR': { continent: 'ASIA' },
  'TKM': { continent: 'ASIA' },
  // 'EGY': { continent: 'ASIA' },
  'UAE': { continent: 'ASIA' },
  'URS': { continent: 'ASIA' },
  'UZB': { continent: 'ASIA' },
  'VAN': { continent: 'ASIA' },
  'YEM': { continent: 'ASIA' },
  'VIE': { continent: 'ASIA' },

  'ALB': { continent: 'EUROPE' },
  'AND': { continent: 'EUROPE' },
  // 'ARM': { continent: 'EUROPE' },
  'AUT': { continent: 'EUROPE' },
  // 'AZE': { continent: 'EUROPE' },
  'BEL': { continent: 'EUROPE' },
  'BIH': { continent: 'EUROPE' },
  'BLR': { continent: 'EUROPE' },
  'BUL': { continent: 'EUROPE' },
  'CRO': { continent: 'EUROPE' },
  // 'CYP': { continent: 'EUROPE' },
  'CZE': { continent: 'EUROPE' },
  'DEN': { continent: 'EUROPE' },
  'ESP': { continent: 'EUROPE' },
  'EST': { continent: 'EUROPE' },
  'FAR': { continent: 'EUROPE' },
  'FIN': { continent: 'EUROPE' },
  'FRA': { continent: 'EUROPE' },
  'FRG': { continent: 'EUROPE' },
  'GBR': { continent: 'EUROPE' },
  'GDR': { continent: 'EUROPE' },
  // 'GEO': { continent: 'EUROPE' },
  'GER': { continent: 'EUROPE' },
  'GIB': { continent: 'EUROPE' },
  'GRE': { continent: 'EUROPE' },
  'HUN': { continent: 'EUROPE' },
  'IRL': { continent: 'EUROPE' },
  'ISL': { continent: 'EUROPE' },
  // 'ISR': { continent: 'EUROPE' },
  'ITA': { continent: 'EUROPE' },
  'KOS': { continent: 'EUROPE' },
  'LAT': { continent: 'EUROPE' },
  'LIE': { continent: 'EUROPE' },
  'LTU': { continent: 'EUROPE' },
  'LUX': { continent: 'EUROPE' },
  'MDA': { continent: 'EUROPE' },
  'MKD': { continent: 'EUROPE' },
  'MLT': { continent: 'EUROPE' },
  'MNE': { continent: 'EUROPE' },
  'MON': { continent: 'EUROPE' },
  'NED': { continent: 'EUROPE' },
  'NOR': { continent: 'EUROPE' },
  'POL': { continent: 'EUROPE' },
  'POR': { continent: 'EUROPE' },
  'ROU': { continent: 'EUROPE' },
  // 'RUS': { continent: 'EUROPE' },
  'SLO': { continent: 'EUROPE' },
  'SMR': { continent: 'EUROPE' },
  'SRB': { continent: 'EUROPE' },
  'SUD': { continent: 'EUROPE' },
  'SUI': { continent: 'EUROPE' },
  'SVK': { continent: 'EUROPE' },
  'SWE': { continent: 'EUROPE' },
  // 'TUR': { continent: 'EUROPE' },
  'UKR': { continent: 'EUROPE' },

  'AGU': { continent: 'NORTH AMERICA' },
  'ATG': { continent: 'NORTH AMERICA' },
  'ANT': { continent: 'NORTH AMERICA' },
  'BAH': { continent: 'NORTH AMERICA' },
  'BHS': { continent: 'NORTH AMERICA' },
  'BAR': { continent: 'NORTH AMERICA' },
  'BRB': { continent: 'NORTH AMERICA' },
  'BER': { continent: 'NORTH AMERICA' },
  'BMU': { continent: 'NORTH AMERICA' },
  'BIZ': { continent: 'NORTH AMERICA' },
  'BLZ': { continent: 'NORTH AMERICA' },
  'VGB': { continent: 'NORTH AMERICA' },
  'CAN': { continent: 'NORTH AMERICA' },
  'CAY': { continent: 'NORTH AMERICA' },
  'CRC': { continent: 'NORTH AMERICA' },
  'CYM': { continent: 'NORTH AMERICA' },
  'CRI': { continent: 'NORTH AMERICA' },
  'CUB': { continent: 'NORTH AMERICA' },
  'CUR': { continent: 'NORTH AMERICA' },
  'DMA': { continent: 'NORTH AMERICA' },
  'DOM': { continent: 'NORTH AMERICA' },
  'EOR': { continent: 'NORTH AMERICA' }, //? in the olympics file but is not a code in wikipedia or in SWIMS db or google search
  'ESA': { continent: 'NORTH AMERICA' },
  'SLV': { continent: 'NORTH AMERICA' },
  'GRL': { continent: 'NORTH AMERICA' },
  'GRN': { continent: 'NORTH AMERICA' },
  'GRD': { continent: 'NORTH AMERICA' },
  'GLP': { continent: 'NORTH AMERICA' },
  'GTM': { continent: 'NORTH AMERICA' },
  'GUI': { continent: 'NORTH AMERICA' },
  'GUA': { continent: 'NORTH AMERICA' },
  'HAI': { continent: 'NORTH AMERICA' },
  'HON': { continent: 'NORTH AMERICA' },
  'HTI': { continent: 'NORTH AMERICA' },
  'HND': { continent: 'NORTH AMERICA' },
  'IFA': { continent: 'NORTH AMERICA' },
  'ISV': { continent: 'NORTH AMERICA' },
  'IVB': { continent: 'NORTH AMERICA' },
  'JAM': { continent: 'NORTH AMERICA' },
  'MTQ': { continent: 'NORTH AMERICA' },
  'MEX': { continent: 'NORTH AMERICA' },
  'MSR': { continent: 'NORTH AMERICA' },
  'CUW': { continent: 'NORTH AMERICA' },
  'ABW': { continent: 'NORTH AMERICA' },
  'SKN': { continent: 'NORTH AMERICA' },
  'SXM': { continent: 'NORTH AMERICA' },
  'BES': { continent: 'NORTH AMERICA' },
  'NCA': { continent: 'NORTH AMERICA' },
  'NIC': { continent: 'NORTH AMERICA' },
  'NIG': { continent: 'NORTH AMERICA' },
  'UMI': { continent: 'NORTH AMERICA' },
  'PAN': { continent: 'NORTH AMERICA' },
  'PRI': { continent: 'NORTH AMERICA' },
  'BLM': { continent: 'NORTH AMERICA' },
  'KNA': { continent: 'NORTH AMERICA' },
  'AIA': { continent: 'NORTH AMERICA' },
  'LCA': { continent: 'NORTH AMERICA' },
  'MAF': { continent: 'NORTH AMERICA' },
  'MAA': { continent: 'NORTH AMERICA' },
  'PUR': { continent: 'NORTH AMERICA' },
  'SPM': { continent: 'NORTH AMERICA' },
  'VCT': { continent: 'NORTH AMERICA' },
  'TCN': { continent: 'NORTH AMERICA' },
  'TGA': { continent: 'NORTH AMERICA' },
  'TOG': { continent: 'NORTH AMERICA' },
  'TTO': { continent: 'NORTH AMERICA' },
  'TCA': { continent: 'NORTH AMERICA' },
  'URU': { continent: 'NORTH AMERICA' },
  'USA': { continent: 'NORTH AMERICA' },
  'VIN': { continent: 'NORTH AMERICA' },
  'VIR': { continent: 'NORTH AMERICA' },

  'ASA': { continent: 'OCEANIA' },
  'ASM': { continent: 'OCEANIA' },
  'AUS': { continent: 'OCEANIA' },
  'SLB': { continent: 'OCEANIA' },
  'COK': { continent: 'OCEANIA' },
  'FIJ': { continent: 'OCEANIA' },
  'PYF': { continent: 'OCEANIA' },
  'KIR': { continent: 'OCEANIA' },
  'GUM': { continent: 'OCEANIA' },
  'NRU': { continent: 'OCEANIA' },
  'NCL': { continent: 'OCEANIA' },
  'VUT': { continent: 'OCEANIA' },
  'NZL': { continent: 'OCEANIA' },
  'NIU': { continent: 'OCEANIA' },
  'NFK': { continent: 'OCEANIA' },
  'NMA': { continent: 'OCEANIA' },

  'MNP': { continent: 'OCEANIA' },
  // 'UMI': { continent: 'OCEANIA' },
  'FSM': { continent: 'OCEANIA' },
  'MHL': { continent: 'OCEANIA' },
  'PLW': { continent: 'OCEANIA' },
  'PNG': { continent: 'OCEANIA' },
  'PCN': { continent: 'OCEANIA' },
  'SAM': { continent: 'OCEANIA' },
  'SOL': { continent: 'OCEANIA' },
  'TKL': { continent: 'OCEANIA' },
  'TON': { continent: 'OCEANIA' },
  'TUV': { continent: 'OCEANIA' },
  'WLF': { continent: 'OCEANIA' },
  'WSM': { continent: 'OCEANIA' },

  'ARU': { continent: 'SOUTH AMERICA' },
  'ARG': { continent: 'SOUTH AMERICA' },
  'BOL': { continent: 'SOUTH AMERICA' },
  'BRA': { continent: 'SOUTH AMERICA' },
  'CHI': { continent: 'SOUTH AMERICA' },
  'CHL': { continent: 'SOUTH AMERICA' },
  'COL': { continent: 'SOUTH AMERICA' },
  'ECU': { continent: 'SOUTH AMERICA' },
  'FLK': { continent: 'SOUTH AMERICA' },
  'GUF': { continent: 'SOUTH AMERICA' },
  'GUY': { continent: 'SOUTH AMERICA' },
  'PAR': { continent: 'SOUTH AMERICA' },
  'PRY': { continent: 'SOUTH AMERICA' },
  'PER': { continent: 'SOUTH AMERICA' },
  'SUR': { continent: 'SOUTH AMERICA' },
  'URY': { continent: 'SOUTH AMERICA' },
  'VEN': { continent: 'SOUTH AMERICA' }
}

const convertTimeToSeconds = shared.convertTimeToSeconds;
const convertSecondsToTime = shared.convertSecondsToTime;

function makeClubObject(clubNode) {
  //FINA meets clubCode = country code
  const clubCode = clubNode.attributes["code"] !== undefined ? clubNode.attributes["code"].nodeValue : "";

  let lscId = "";
  lscId = continentFromCountryCodeMap[clubCode] !== undefined ? continentFromCountryCodeMap[clubCode].continent : "";

  return {
    //TODO: assumption -> all lenex files will set lscid to 'US'
    //TODO: if the code attribute on the <CLUB node is missing do we log an error and strip out this club or create json with missing value (see R_VIC_2013.lef)?
    lscId: lscId.length > 0 ? lscId : "US",
    clubCode: clubCode,
    clubName: clubNode.attributes["name"] !== undefined ? clubNode.attributes["name"].nodeValue : ""
  }
}

function doesPersonObjectHaveErrors(personObject, athleteId, lefErrorArray, timeType) {
  let errorFound = false;
  const genders = [LEF_GENDER_MALE, LEF_GENDER_FEMALE];
  const nodeName = timeType === TIME_TYPE_RELAY ? "<RELAYPOSITION" : "<ATHLETE"

  if (personObject.lastName.length === 0) {

    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'lastname' = '${personObject.lastName}' is invalid or not populated for 'athleteid' = ${athleteId}. See ${nodeName} node for this athleteId`
      });
    errorFound = true;
  }

  if (personObject.firstName.length === 0) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'firstname' = '${personObject.firstName}' is invalid or not populated for 'athleteid' = ${athleteId}. See <ATHLETE node for this athleteId`
      });
    errorFound = true;
  }

  //Firefox doesn't like dates with '-'
  const checkDate = personObject.birthDate.replaceAll("-", "/");
  if (isValidDate(checkDate) === false) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'birthdate' = '${personObject.birthDate}' is invalid or not populated for 'athleteid' = ${athleteId}. See <ATHLETE node for this athleteId`
      });
    errorFound = true;
  } else {
    const now = new Date();
    const dob = Date.parse(personObject.birthDate);

    if (dob > now) {
      lefErrorArray.push(
        {
          errorType: 'data',
          errorMessage: `'birthdate' = '${personObject.birthDate}' is invalid for 'athleteid' = ${athleteId}. See <ATHLETE node for this athleteId`
        });
    }
  }

  if (genders.includes(personObject.gender) === false) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'gender' = '${personObject.gender}' is invalid or not populated for 'athleteid' = ${athleteId}. See <ATHLETE node for this athleteId`
      });
    errorFound = true;
  }

  return errorFound;
}

function lef2USASDateFormat(dateVal) {
  const year = dateVal.substring(0, 4);
  const month = dateVal.substring(5, 7);
  const day = dateVal.substring(8, 10);

  return `${month}-${day}-${year}`;
}

function makePersonObject(personNode, lefErrorArray) {
  if (personNode === undefined) return {};

  let athleteId = Constants.DEFAULT_ID;
  if (personNode.attributes["athleteid"] !== undefined) {
    athleteId = personNode.attributes["athleteid"].nodeValue;
  }

  const personObj = {
    firstName: personNode.attributes["firstname"] !== undefined ? personNode.attributes["firstname"].nodeValue : '',
    lastName: personNode.attributes["lastname"] !== undefined ? personNode.attributes["lastname"].nodeValue : '',
    middleName: '',
    birthDate: personNode.attributes["birthdate"] !== undefined ? lef2USASDateFormat(personNode.attributes["birthdate"].nodeValue) : '',
    gender: personNode.attributes["gender"] !== undefined ? personNode.attributes["gender"].nodeValue : ''
  }

  if (doesPersonObjectHaveErrors(personObj, athleteId, lefErrorArray, TIME_TYPE_INDIVIDUAL) === true) return {};

  return personObj;
}

function makeAthleteEntryObject(entryNode) {
  return {
    eventId: entryNode.attributes["eventid"].nodeValue,
    entryTime: entryNode.attributes["entrytime"].nodeValue,
    heat: entryNode.attributes["heat"] !== undefined ? entryNode.attributes["heat"].nodeValue : 0,
    lane: entryNode.attributes["lane"] !== undefined ? entryNode.attributes["lane"].nodeValue : 0
  }
}

function findEventInfo(eventIdVal, eventsArray) {
  return eventsArray.find(({ eventId }) => eventId === eventIdVal);
}

function findEntryInfo(eventIdVal, athleteEntriesArray) {
  return athleteEntriesArray.find(({ eventId }) => eventId === eventIdVal)
}

function findRankInfo(resultIdVal, rankingArray) {
  return rankingArray.find(({ resultId }) => resultId === resultIdVal)
}

function findHeatInfo(heatIdVal, heatArray) {
  return heatArray.find(({ heatId }) => heatId === heatIdVal)
}

function getAthleteEntriesArray(entriesNode) {
  const athleteEntriesArray = [];

  if (entriesNode[0] === undefined) return;

  const countEntries = entriesNode[0].childElementCount * 2;

  if (countEntries === 0) return;

  for (var entriesIndex = 0; entriesIndex < countEntries; entriesIndex++) {
    const entriesNodeName = entriesNode[0].childNodes[entriesIndex].nodeName;

    if (entriesNodeName === "ENTRY") {
      const entryNode = entriesNode[0].childNodes[entriesIndex];

      if (entryNode !== undefined) {
        athleteEntriesArray.push(makeAthleteEntryObject(entryNode));
      }
    }
  }
  return athleteEntriesArray;
}

function getAthleteSplitsArray(splitsNode, swimTime) {
  let splitsArray = [];
  if (splitsNode === undefined || splitsNode[0] === undefined) {
    return [];
  }
  const countSplits = splitsNode[0].childElementCount * 2;

  if (countSplits === 0) return [];

  let splitNum = 1;

  for (var splitIndex = 0; splitIndex < countSplits; splitIndex++) {
    const splitNodeName = splitsNode[0].childNodes[splitIndex].nodeName;

    if (splitNodeName === "SPLIT") {
      const splitNode = splitsNode[0].childNodes[splitIndex];

      let swimTime = "";
      if (splitNode.attributes["swimtime"] !== undefined) {
        swimTime = splitNode.attributes["swimtime"].value;
      }

      if (swimTime.length > 0) {
        splitsArray.push({ splitNumber: splitNum, splitTime: swimTime });
        splitNum++;
      }
    }
  }

  //If LEF file does not provide the final split set it from swimTime
  if (splitsArray.length > 1 && splitsArray.length % 2 === 1) {
    splitsArray.push({ splitNumber: splitNum++, splitTime: swimTime });
  }

  return splitsArray;
}

function stripLeadingZeros(value) {
  const charArray = value.split("");
  let zeroCount = 0;
  let nonZeroCount = 0;
  charArray.forEach(element => {
    if (element === "0" && nonZeroCount === 0) {
      zeroCount++;
    } else {
      nonZeroCount++;
    }
  });

  return value.slice(zeroCount);
}

function makeRelayPersonObject(athleteIdVal, athleteLookupArray, lefErrorArray) {
  //Some LEF files have leading zeros on their AthleteId in RelayPositions nodes starting 11/2023
  const fixedAthleteIdVal = stripLeadingZeros(athleteIdVal);
  let personInfo = athleteLookupArray.find(({ athleteId }) => athleteId === fixedAthleteIdVal);

  if (personInfo === undefined) return {};

  let relayPersonObject = {
    firstName: personInfo.firstName !== undefined ? personInfo.firstName : "",
    lastName: personInfo.lastName !== undefined ? personInfo.lastName : "",
    middleName: "",
    birthDate: personInfo.birthDate !== undefined ? lef2USASDateFormat(personInfo.birthDate) : "",
    gender: personInfo.gender !== undefined ? personInfo.gender : ""
  }

  if (doesPersonObjectHaveErrors(relayPersonObject, athleteIdVal, lefErrorArray, TIME_TYPE_RELAY) === true) return {};

  return relayPersonObject;
}

function populateRelayLegSplitsArray(relayLegSplitsArray, relayLegSplitObj, splitCount, legNum) {
  if (splitCount === 4 || splitCount === 3) {
    if (legNum === 1 && relayLegSplitObj.splitNumber === 1) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 2 && relayLegSplitObj.splitNumber === 2) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 3 && relayLegSplitObj.splitNumber === 3) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 4 && relayLegSplitObj.splitNumber === 4) {
      relayLegSplitsArray.push(relayLegSplitObj);
    }
  } else if (splitCount === 8 || splitCount === 7) {
    if (legNum === 1 && [1, 2].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 2 && [3, 4].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 3 && [5, 6].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 4 && [7, 8].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    }
  }
  else if (splitCount === 16 || splitCount === 15) {
    if (legNum === 1 && [1, 2, 3, 4].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 2 && [5, 6, 7, 8].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 3 && [9, 10, 11, 12].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 4 && [13, 14, 15, 16].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    }
  }
  else if (splitCount === 32 || splitCount === 31) {
    if (legNum === 1 && [1, 2, 3, 4, 5, 6, 7, 8].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 2 && [9, 10, 11, 12, 13, 14, 15, 16].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 3 && [17, 18, 19, 20, 21, 22, 23, 24].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    } else if (legNum === 4 && [25, 26, 27, 28, 29, 30, 31, 32].includes(relayLegSplitObj.splitNumber)) {
      relayLegSplitsArray.push(relayLegSplitObj);
    }
  }
}

function makeRelayLegSplitsArray(resultNode, relayTime, legNum, eventDistance) {
  let relayLegSplitsArray = [];
  const splitsNode = resultNode.getElementsByTagName("SPLITS");

  if (splitsNode.length === 0 || splitsNode[0].childElementCount === 0) return;

  const countSplits = splitsNode[0].childElementCount;
  const countSplitIterations = countSplits * 2; //for every split node there is a useless 'TEXT' node
  let splitNum = 1;
  for (var splitIndex = 0; splitIndex < countSplitIterations; splitIndex++) {
    const nodeName = splitsNode[0].childNodes[splitIndex].nodeName;

    if (nodeName === "SPLIT") {
      const splitNode = splitsNode[0].childNodes[splitIndex];

      let swimTime = "";
      if (splitNode.attributes["swimtime"] !== undefined) {
        swimTime = splitNode.attributes["swimtime"].value;
      }

      if (swimTime.length > 0) {
        const relayLegSplitObj = {
          splitNumber: splitNum,
          splitTime: swimTime
        }

        populateRelayLegSplitsArray(relayLegSplitsArray, relayLegSplitObj, countSplits, legNum);

        splitNum++;
      }
    }
  }

  //if LEF file does not contain the final split (odd length) get it from the relay time
  if ((relayLegSplitsArray.length === 0 || relayLegSplitsArray.length % 2 === 1) && legNum === 4) {
    relayLegSplitsArray.push({ splitNumber: splitNum, splitTime: relayTime });
  }

  return relayLegSplitsArray;
}

function getLegTimeFromSplits(relayLegSplitsArray) {
  if (relayLegSplitsArray === undefined || relayLegSplitsArray.length === 0) return "";

  let legTime = relayLegSplitsArray[relayLegSplitsArray.length - 1].splitTime;

  return legTime;
}

function convertLegTimesToIntervalTimes(relayLegsArray) {
  relayLegsArray[3].legTime = convertSecondsToTime(convertTimeToSeconds(relayLegsArray[3].legTime) - convertTimeToSeconds(relayLegsArray[2].legTime));
  relayLegsArray[2].legTime = convertSecondsToTime(convertTimeToSeconds(relayLegsArray[2].legTime) - convertTimeToSeconds(relayLegsArray[1].legTime));
  relayLegsArray[1].legTime = convertSecondsToTime(convertTimeToSeconds(relayLegsArray[1].legTime) - convertTimeToSeconds(relayLegsArray[0].legTime));
}

function makeRelayLegsArray(resultNode, athleteLookupArray, relayTime, eventDistance, lefErrorArray) {
  if (resultNode === undefined || resultNode.length === 0) return;

  let relayLegsArray = [];
  const relayPositionsNode = resultNode.getElementsByTagName("RELAYPOSITIONS");

  if (relayPositionsNode.length === 0 || relayPositionsNode[0].childElementCount === 0) return;

  const countRelayPositions = relayPositionsNode[0].childElementCount * 2;
  let legNum = 1;

  for (var relayPositionIndex = 0; relayPositionIndex < countRelayPositions; relayPositionIndex++) {
    const nodeName = relayPositionsNode[0].childNodes[relayPositionIndex].nodeName;

    let relayLegSplitsArray = makeRelayLegSplitsArray(resultNode, relayTime, legNum, eventDistance);

    if (nodeName === "RELAYPOSITION") {
      const relayPositionNode = relayPositionsNode[0].childNodes[relayPositionIndex];

      let athleteId = Constants.DEFAULT_ID;
      if (relayPositionNode.attributes["athleteid"] !== undefined) {
        athleteId = relayPositionNode.attributes["athleteid"].value;
      }

      const legObject = {
        legNumber: legNum,
        legTime: getLegTimeFromSplits(relayLegSplitsArray),
        person: makeRelayPersonObject(athleteId, athleteLookupArray, lefErrorArray),
        splits: relayLegSplitsArray
      };
      relayLegsArray.push(legObject);

      legNum++;
    }
  }

  convertLegTimesToIntervalTimes(relayLegsArray);

  return relayLegsArray;
}

function getRelayResults(resultsNode, relayTimesArray, eventsArray, rankingArray, course, clubObj, athleteLookupArray, heatsArray, lefErrorArray) {
  if (resultsNode.length === 0) return;

  const countResults = resultsNode[0].childElementCount * 2;

  if (countResults === 0) return;

  for (var resultIndex = 0; resultIndex < countResults; resultIndex++) {
    const resultNodeName = resultsNode[0].childNodes[resultIndex].nodeName;

    if (resultNodeName === "RESULT") {
      const resultNode = resultsNode[0].childNodes[resultIndex];

      let status = "";
      if (resultNode.attributes["status"] !== undefined) {
        status = resultNode.attributes["status"].value;
      }

      if ([RESULT_STATUS_DSQ, RESULT_STATUS_DNS, RESULT_STATUS_DNF, RESULT_STATUS_SICK, RESULT_STATUS_WDR].includes(status)) return;

      let swimTime = "";
      if (resultNode.attributes["swimtime"] !== undefined) {
        swimTime = resultNode.attributes["swimtime"].value;
      }

      let eventId = Constants.DEFAULT_ID;
      if (resultNode.attributes["eventid"] !== undefined) {
        eventId = resultNode.attributes["eventid"].value;
      }

      let resultId = Constants.DEFAULT_ID;
      if (resultNode.attributes["resultid"] !== undefined) {
        resultId = resultNode.attributes["resultid"].value;
      }

      let place = 0;
      if (resultNode.attributes["place"] !== undefined) {
        place = resultNode.attributes["place"].value;
      }

      const eventInfo = findEventInfo(eventId, eventsArray);

      let rankInfo = undefined;
      if (rankingArray !== undefined && resultId > 0) {
        rankInfo = findRankInfo(resultId, rankingArray)
      }

      const heatNumber = getHeatNumber(resultNode, heatsArray, []);

      let hasResultErrors = logResultErrors(resultId, swimTime, lefErrorArray)

      if (hasResultErrors === false) {
        const relayObj = {
          courseCode: course,
          distance: eventInfo.distance,
          eventGender: eventInfo.gender === 'X' ? 'MX' : eventInfo.gender,
          heatNumber: heatNumber,
          laneNumber: resultNode.attributes["lane"] !== undefined ? resultNode.attributes["lane"].value : 0,
          legs: makeRelayLegsArray(resultNode, athleteLookupArray, swimTime, eventInfo.distance, lefErrorArray),
          placeRanking: rankInfo !== undefined ? rankInfo.place : place,
          relayTime: swimTime,
          seedTime: "",
          session: sessionCodesMap[eventInfo.session].code,
          stroke: relayStrokeCodesMap[eventInfo.stroke].code,
          swimDate: eventInfo.sessionDate,
          team: clubObj
        }

        relayTimesArray.push(relayObj);
      }
    }
  }
}

function isValidReactionTime(reactionTime) {
  if (reactionTime.substring(0, 1) !== "+" && reactionTime.substring(0, 1) !== "-") return false;
  if (parseInt(reactionTime.substring(1, 2)) === isNaN) return false;
  return true;
}

function getLaneNumber(resultNode, entryInfo) {
  let laneNumber = 0;
  if (resultNode.attributes["lane"] !== undefined) {
    laneNumber = resultNode.attributes["lane"].value;
  }

  if (laneNumber === 0 && entryInfo !== undefined) {
    laneNumber = entryInfo.laneNumber;
  }

  return laneNumber;
}

function getHeatNumber(resultNode, heatsArray, entryInfo) {
  //heat number can come from 3 different places - rediculous!!
  //1. 'heat' attribute on '<RESULT' node
  let heatNumber = 0;
  if (resultNode.attributes["heat"] !== undefined) {
    heatNumber = resultNode.attributes["heat"].value;
  }

  //2. 'heat' attribute on the '<ENTRY' node if it exists
  if (heatNumber === 0 && entryInfo !== undefined && entryInfo.length > 0) {
    heatNumber = entryInfo.heat;
  }

  //3. 'number' attribute on the <HEAT' node if it exists --> lookup by heatid
  if (heatNumber === 0) {
    let heatId = Constants.DEFAULT_ID;
    if (resultNode.attributes["heatid"] !== undefined) {
      heatId = resultNode.attributes["heatid"].value;
    }

    let heatInfo = undefined;
    if (heatsArray !== undefined) {
      heatInfo = findHeatInfo(heatId, heatsArray);
    }

    if (heatInfo !== undefined) {
      heatNumber = heatInfo.heatNumber;
    }
  }
  return heatNumber;
}

function logEventErrors(eventId, eventInfo, lefErrorArray, lastName, firstName, swimTime) {
  let errorFound = false;

  if (eventInfo === undefined) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `The Event for 'eventId' = '${eventId}' for '${firstName} ${lastName}' has not been defined in the <EVENTS section of this file. This time '${swimTime}' will be skipped.`
      }
    )
  }

  return errorFound;
}

function logResultErrors(resultId, swimTime, lefErrorArray) {
  let errorFound = false;

  //TODO: validate malformed times
  if (swimTime.length === 0) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'swimtime' = '${swimTime}' is invalid or not populated for 'resultid' = ${resultId}. See <RESULT node for this resultid`
      });
    errorFound = true;
  }

  return errorFound;
}

function getAthleteResults(resultsNode, individualTimesArray, eventsArray, rankingArray, course, clubObject, personObject, athleteEntriesArray, heatsArray, lefErrorArray) {
  const countResults = resultsNode[0].childElementCount * 2;

  if (countResults === 0) return;

  for (var resultIndex = 0; resultIndex < countResults; resultIndex++) {
    const resultNodeName = resultsNode[0].childNodes[resultIndex].nodeName;

    if (resultNodeName === "RESULT") {
      const resultNode = resultsNode[0].childNodes[resultIndex];

      let status = "";
      if (resultNode.attributes["status"] !== undefined) {
        status = resultNode.attributes["status"].value;
      }

      if ([RESULT_STATUS_DSQ, RESULT_STATUS_DNS, RESULT_STATUS_DNF, RESULT_STATUS_SICK, RESULT_STATUS_WDR].includes(status) === false) {

        let swimTime = resultNode.attributes["swimtime"] !== undefined ? resultNode.attributes["swimtime"].value : "";
        let reactionTime = resultNode.attributes["reactiontime"] !== undefined ? resultNode.attributes["reactiontime"].value : "";
        let eventId = resultNode.attributes["eventid"] !== undefined ? resultNode.attributes["eventid"].value : "";
        let resultId = resultNode.attributes["resultid"] !== undefined ? resultNode.attributes["resultid"].value : "";
        let place = resultNode.attributes["place"] !== undefined ? resultNode.attributes["place"].value : 0;
        let entryInfo = athleteEntriesArray !== undefined ? findEntryInfo(eventId, athleteEntriesArray) : undefined;
        let rankInfo = rankingArray !== undefined ? findRankInfo(resultId, rankingArray) : undefined;

        const eventInfo = findEventInfo(eventId, eventsArray);
        const heatNumber = getHeatNumber(resultNode, heatsArray, entryInfo);
        const laneNumber = getLaneNumber(resultNode, entryInfo);

        let usasSessionCode = "";
        let usasStrokeCode = "";

        if (eventInfo === undefined || eventInfo.session === undefined) {
          console.error('No session found');
        } else {
          usasSessionCode = sessionCodesMap[eventInfo.session].code;
          usasStrokeCode = strokeCodesMap[eventInfo.stroke].code;
        }

        if (usasSessionCode === 'X') {
          console.error('Unsupported session found', eventInfo.session);
        }

        if (usasStrokeCode === undefined) {
          console.error('Unsupported stroke found', eventInfo.session);
        }

        let hasErrors = logResultErrors(resultId, swimTime, lefErrorArray);
        hasErrors = logEventErrors(eventId, eventInfo, lefErrorArray, personObject.lastName, personObject.firstName, swimTime);

        if (
          eventInfo !== undefined && 
          eventInfo.session !== undefined &&
          usasSessionCode !== 'X' &&
          usasStrokeCode !== undefined
        ) {
          const splitsNode = resultNode.getElementsByTagName("SPLITS");
          let splitsArray = getAthleteSplitsArray(splitsNode, swimTime);

          if (hasErrors === false) {
            const athleteTimeObj = {
              person: personObject,
              courseCode: course,
              distance: eventInfo.distance,
              stroke: usasStrokeCode,
              session: usasSessionCode,
              swimTime: swimTime,
              reactionTime: isValidReactionTime(reactionTime) === true ? reactionTime : "",
              swimDate: eventInfo.sessionDate,
              splits: splitsArray,
              heatNumber: heatNumber,
              laneNumber: laneNumber,
              placeRanking: rankInfo !== undefined ? rankInfo.place : place,
              seedTime: entryInfo !== undefined ? entryInfo.entryTime : '',
              club: clubObject
            }

            individualTimesArray.push(athleteTimeObj);
          }
        }
      }
    }
  }
}

function processRelays(relaysNode, relayTimesArray, eventsArray, rankingArray, course, clubObj, athleteLookupArray, heatsArray, lefErrorArray) {
  if (relaysNode.length === 0) return;

  const countRelays = relaysNode[0].childElementCount * 2;

  for (var relayIndex = 0; relayIndex < countRelays; relayIndex++) {
    if (relaysNode[0].childNodes[relayIndex] === undefined) break;

    const nodeName = relaysNode[0].childNodes[relayIndex].nodeName;

    if (nodeName === "RELAY") {
      const relayNode = relaysNode[0].childNodes[relayIndex];
      const resultsNode = relayNode.getElementsByTagName("RESULTS");

      if (resultsNode.length > 0) {
        getRelayResults(resultsNode, relayTimesArray, eventsArray, rankingArray, course, clubObj, athleteLookupArray, heatsArray, lefErrorArray);
      }
    }
  }
}

function makeAthleteLookupObject(athleteNode) {
  if (athleteNode === undefined) return {};

  let athleteId = Constants.DEFAULT_ID;
  if (athleteNode.attributes["athleteid"] !== undefined) {
    athleteId = athleteNode.attributes["athleteid"].value;
  }

  let lastName = "";
  if (athleteNode.attributes["lastname"] !== undefined) {
    lastName = athleteNode.attributes["lastname"].value;
  }

  let firstName = "";
  if (athleteNode.attributes["firstname"] !== undefined) {
    firstName = athleteNode.attributes["firstname"].value;
  }

  let birthDate = "";
  if (athleteNode.attributes["birthdate"] !== undefined) {
    birthDate = athleteNode.attributes["birthdate"].value;
  }

  let gender = "";
  if (athleteNode.attributes["gender"] !== undefined) {
    gender = athleteNode.attributes["gender"].value;
  }

  if (
    athleteId === Constants.DEFAULT_ID ||
    lastName.length === 0 ||
    firstName.length === 0 ||
    birthDate.length === 0 ||
    gender.length === 0) return {};

  return {
    athleteId: athleteId,
    lastName: lastName,
    firstName: firstName,
    birthDate: birthDate,
    gender: gender
  }
}

function processAthletes(athletesNode, individualTimesArray, eventsArray, rankingArray, course, clubObject, athleteLookupArray, heatsArray, lefErrorArray) {
  if (athletesNode === undefined || athletesNode[0] === undefined) return;

  const countAthletes = athletesNode[0].childElementCount * 2;

  for (var athIndex = 0; athIndex < countAthletes; athIndex++) {
    const nodeName = athletesNode[0].childNodes[athIndex].nodeName;

    if (nodeName === "ATHLETE") {
      const athleteNode = athletesNode[0].childNodes[athIndex];

      athleteLookupArray.push(makeAthleteLookupObject(athleteNode));

      const personObj = makePersonObject(athleteNode, lefErrorArray);

      if (Object.keys(personObj).length > 0) {
        const entriesNode = athleteNode.getElementsByTagName("ENTRIES");
        const athleteEntriesArray = getAthleteEntriesArray(entriesNode);
        const resultsNode = athleteNode.getElementsByTagName("RESULTS");

        if (resultsNode.length > 0) {
          getAthleteResults(resultsNode, individualTimesArray, eventsArray, rankingArray, course, clubObject, personObj, athleteEntriesArray, heatsArray, lefErrorArray);
        }
      }
    }
  }
}

function populateHeatsArray(eventNode, heatsArray) {
  const heatsNode = eventNode.getElementsByTagName("HEATS");

  if (heatsNode === undefined || heatsNode[0] === undefined) return;

  const countHeats = heatsNode[0].childElementCount * 2;

  for (var heatIndex = 0; heatIndex < countHeats; heatIndex++) {
    const heatNodeName = heatsNode[0].childNodes[heatIndex].nodeName;

    if (heatNodeName === "HEAT") {
      const heat = heatsNode[0].childNodes[heatIndex];

      if (heat.attributes["heatid"] !== undefined && heat.attributes["number"] !== undefined) {
        const heatId = heat.attributes["heatid"].nodeValue;
        const heatNumber = heat.attributes["number"].nodeValue;

        heatsArray.push({ heatId: heatId, heatNumber: heatNumber });
      }
    }
  }
}

function populateRankingsArray(eventNode, rankingArray) {
  const rankingsNode = eventNode.getElementsByTagName("RANKINGS");

  if (rankingsNode.length === 0) return;

  const countRankings = rankingsNode[0].childElementCount * 2;

  for (var rankIndex = 0; rankIndex < countRankings; rankIndex++) {
    const rankNodeName = rankingsNode[0].childNodes[rankIndex].nodeName;

    if (rankNodeName === "RANKING") {
      const ranking = rankingsNode[0].childNodes[rankIndex];

      let resultId = Constants.DEFAULT_ID;
      if (ranking.attributes["resultid"] !== undefined) {
        resultId = ranking.attributes["resultid"].nodeValue;
      }

      let place = 0;
      if (ranking.attributes["place"] !== undefined) {
        place = ranking.attributes["place"].nodeValue;
      }

      if (resultId > 0 && place > 0) {
        const rankingObj = {
          resultId: resultId,
          place: place
        }
        rankingArray.push(rankingObj);
      }
    }
  }
}

function doesEventObjectHaveErrors(eventObject, lefErrorArray) {
  let errorFound = false;
  const rounds = [ROUND_FINAL, ROUND_SEMIFINAL, ROUND_QUARTERFINAL, ROUND_PRELIM, ROUND_SWIMOFF_SOP, ROUND_SWIMOFF_SOS, ROUND_SWIMOFF_SOQ, ROUND_TIMED_FINAL, ROUND_FASTEST_HEATS_TIME];
  const distances = [25, 50, 100, 200, 400, 500, 800, 1000, 1500, 1650, 2000, 3000, 4000, 5000];
  const strokes = [LEF_STROKE_BACK, LEF_STROKE_BREAST, LEF_STROKE_FLY, LEF_STROKE_FREE, LEF_STROKE_MEDLEY];

  if (rounds.includes(eventObject.session) === false) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'round' = '${eventObject.session}' is invalid or not populated for 'eventid' = ${eventObject.eventId}. See <EVENT node for this eventid`
      });
    errorFound = true;
  }

  if (distances.includes(eventObject.distance) === false) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'distance' = '${eventObject.distance}' is invalid or not populated for 'eventid' = ${eventObject.eventId}. See <EVENT --> <SWIMSTYLE node for this eventid`
      });
    errorFound = true;
  }

  if (strokes.includes(eventObject.stroke) === false) {
    lefErrorArray.push(
      {
        errorType: 'data',
        errorMessage: `'stroke' = '${eventObject.stroke}' is invalid or not populated for 'eventid' = ${eventObject.eventId}. See <EVENT --> <SWIMSTYLE node for this eventid`
      });
    errorFound = true;
  }

  return errorFound;
}

function makeEventObject(eventNode, sessionDate, lefErrorArray) {
  //TODO: check for missing attributes in MakeEventObject()

  const eventId = eventNode.attributes["eventid"].nodeValue;
  const round = eventNode.attributes["round"].nodeValue;
  const gender = eventNode.attributes["gender"].nodeValue;
  const swimStyle = eventNode.getElementsByTagName("SWIMSTYLE");
  const relayCount = swimStyle[0].attributes["relaycount"].nodeValue;
  const distance = swimStyle[0].attributes["distance"].nodeValue;
  const stroke = swimStyle[0].attributes["stroke"].nodeValue;
  const trueDistance = distance * relayCount;

  const eventObj = {
    eventId: eventId,
    gender: gender,
    session: round,
    distance: trueDistance,
    stroke: stroke,
    sessionDate: sessionDate
  }

  if (doesEventObjectHaveErrors(eventObj, lefErrorArray) === true) return {};

  return eventObj;
}

const makeLEFAthLineNumberArray = (fileContent) => {
  const fileArray = fileContent.toString().replace(/\r\n/g, '\n').split('\n');
  let lefAthLineNumberArray = [];

  for (let i = 0; i < fileArray.length; i++) {
    let lineNum = i;
    if (fileArray[i].indexOf('<ATHLETE') > 0) {
      lefAthLineNumberArray.push({ lineNumber: lineNum + 1, line: fileArray[i] });
    }
  }
  return lefAthLineNumberArray;
}

const getSessionArrays = (xmlDoc, lefErrorArray) => {
  let eventsArray = [];
  let heatsArray = [];
  let rankingArray = [];

  const sessions = xmlDoc.getElementsByTagName("SESSIONS");
  const countSessions = sessions[0].childElementCount * 2;

  for (var sessionIndex = 0; sessionIndex < countSessions; sessionIndex++) {
    const sessionNodeName = sessions[0].childNodes[sessionIndex].nodeName;

    if (sessionNodeName === "SESSION") {
      const session = sessions[0].childNodes[sessionIndex];
      const sessionDate = session.attributes["date"].nodeValue;
      const events = session.getElementsByTagName("EVENTS");
      const countEvents = events[0].childElementCount * 2;

      for (var eventIndex = 0; eventIndex < countEvents; eventIndex++) {
        const eventNodeName = events[0].childNodes[eventIndex].nodeName;

        if (eventNodeName === "EVENT") {
          const event = events[0].childNodes[eventIndex];
          const eventObj = makeEventObject(event, sessionDate, lefErrorArray);

          if (Object.keys(eventObj).length > 0) {
            eventsArray.push(eventObj);
          }

          populateHeatsArray(event, heatsArray);
          populateRankingsArray(event, rankingArray);
        }
      }
    }
  }

  return {
    eventsArray: eventsArray,
    heatsArray: heatsArray,
    rankingArray: rankingArray
  }
}

const processClubs = (clubs, individualTimesArray, relayTimesArray, eventsArray, rankingArray, course, heatsArray, lefErrorArray) => {
  //for some reason, for every child element called 'club' there is another one called 'text'
  //this isn't in the XML file but appears to be added by DOMParser. This is repeated for each node so far.
  //childElementCount does not count the 'text' nodes so the count has to be doubled
  const countClubs = clubs[0].childElementCount * 2;

  for (var clubIndex = 0; clubIndex < countClubs; clubIndex++) {
    const clubNodeName = clubs[0].childNodes[clubIndex].nodeName;

    if (clubNodeName === "CLUB") {
      const clubNode = clubs[0].childNodes[clubIndex];
      const clubObj = makeClubObject(clubNode);
      const athletesNode = clubNode.getElementsByTagName("ATHLETES");
      let athleteLookupArray = [];

      processAthletes(athletesNode, individualTimesArray, eventsArray, rankingArray, course, clubObj, athleteLookupArray, heatsArray, lefErrorArray);

      const relaysNode = clubNode.getElementsByTagName("RELAYS");
      processRelays(relaysNode, relayTimesArray, eventsArray, rankingArray, course, clubObj, athleteLookupArray, heatsArray, lefErrorArray);
    }
  }
}

const LEFConversion = {
  makeLEFAthLineNumberArray,
  getSessionArrays,
  processClubs
};

export default LEFConversion;