import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";
import { ATHLETE_ROLE_NAME } from "../../utils/ClubConstants";

import useClubGroupsData from "../../../../state/clubGroups/UseClubGroupsData";

const INITIAL_STATE = {
  displayConfirmationModal: false,
  updatedGroupAthletes: [],
  pageErrorMessage: ''
};

const useClubGroupMembers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clubGroupInfoState, clubGroupMembersState, clubGroupPotentialAthletesState,
    getClubGroupInfo, getClubGroupMembers, getClubGroupPotentialAthletes, putClubGroupMembers
  } = useClubGroupsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onErrorCaught = (e) => {
    setState({
      ...state,
      pageErrorMessage: e.message
    });
  };

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS);
    } catch (e) {
      onErrorCaught(e);
    }
  };

  const onSubmitAthleteFormGrid = (updatedGroupAthletes) => {
    try {
      setState({
        ...state,
        displayConfirmationModal: true,
        updatedGroupAthletes: updatedGroupAthletes
      });
    } catch (e) {
      onErrorCaught(e);
    }
  };

  const onSubmitConfirmationFormGrid = async (confirmedGroupAthletes) => {
    try {
      setState({
        ...state,
        displayConfirmationModal: false,
        updatedGroupAthletes: []
      });

      const putClubGroupMembersResult = await putClubGroupMembers(clubGroupInfoState.objData.orgGroupId, confirmedGroupAthletes, ATHLETE_ROLE_NAME);

      if (putClubGroupMembersResult) {
        navigate(NavLinks.CLUB_GROUPS);
      }
    } catch (e) {
      onErrorCaught(e);
    }
  };

  const onConfirmationModalCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayConfirmationModal: false,
        updatedGroupAthletes: []
      });
    } catch (e) {
      onErrorCaught(e);
    }
  };

  useEffect(() => {
    async function loadClubGroupMembers() {
      try {
        if (location.state?.orgGroupId) {
          const getClubGroupInfoResult = await getClubGroupInfo(location.state.orgGroupId);

          if (getClubGroupInfoResult) {
            const getClubGroupMembersResult = await getClubGroupMembers(location.state.orgGroupId);

            if (getClubGroupMembersResult) {
              await getClubGroupPotentialAthletes(location.state.orgGroupId);
            }
          }
        }
      } catch (e) {
        onErrorCaught(e);
      }
    }
    loadClubGroupMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: clubGroupInfoState.isObjLoading || clubGroupMembersState.isArrayLoading || clubGroupMembersState.isObjLoading,
    isGridLoading: clubGroupPotentialAthletesState.isArrayLoading,
    groupObj: clubGroupInfoState.objData,
    groupMembersArray: clubGroupMembersState.arrayData,
    groupAthletesArray: clubGroupPotentialAthletesState.arrayData,
    state,
    onErrorCaught,
    onBackClicked,
    onSubmitAthleteFormGrid,
    onSubmitConfirmationFormGrid,
    onConfirmationModalCancelClicked
  };
};

export default useClubGroupMembers;