import { Fragment } from "react";
import LSCLifeMemberRenewalsGridLarge from "./LSCLifeMemberRenewalsGridLarge";
import LSCLifeMemberRenewalsGridSmall from "./LSCLifeMemberRenewalsGridSmall";


const LSCLifeMemberRenewalsGrid = ({ data, isLoading, onSelected, showError, onMemberTypesValueChanged }) => (
  <Fragment>
    <LSCLifeMemberRenewalsGridLarge data={data} isLoading={isLoading} onSelected={onSelected} showError={showError} onMemberTypesValueChanged={onMemberTypesValueChanged} />
    <LSCLifeMemberRenewalsGridSmall data={data} isLoading={isLoading} onSelected={onSelected} showError={showError} onMemberTypesValueChanged={onMemberTypesValueChanged} />
  </Fragment>
);

export default LSCLifeMemberRenewalsGrid;