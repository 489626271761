import { useState } from "react";

const usePasswordInput = (initialHidePasswordValue) => {
  const [hidePasswordState, setHidePasswordState] = useState(initialHidePasswordValue === true ? true : false);

  const onToggleHideShowPassword = () => {
    setHidePasswordState(!hidePasswordState);
  };

  return {
    hidePassword: hidePasswordState,
    onToggleHideShowPassword
  };
};

export default usePasswordInput;