import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import UploadIcon from "../../../../common/components/icons/UploadIcon";
import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";

import { formatDate } from "../../../../common/utils/DateFunctions";
import Constants from "../../../../common/utils/Constants";
import useSortableGrid from "../../../../common/utils/UseSortableGrid";
import SGConstants from "../../../../common/utils/SGConstants";

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ meet, clubOrgUnitId, onUploadClicked, onDeleteClicked }) => (
  <tr>
    <td>{meet.meetName}</td>
    <td>{meet.orgUnitName}</td>
    <td>{`${formatDate(meet.startDate)} - ${formatDate(meet.endDate)}`}</td>
    <td>{meet.meetClassification}</td>
    <td>{meet.meetType}</td>
    <td>{meet.status}</td>
    <td>
      {clubOrgUnitId === meet.hostOrgUnitId && meet.status === Constants.WORKFLOW_STATUS_APPROVED ? (
        <Fragment>
          {onUploadClicked !== undefined && meet.isPastMeet === true &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onUploadClicked(meet)}>
              <UploadIcon />
            </button>
          }
          {onDeleteClicked !== undefined && meet.hasResults === false &&
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={() => onDeleteClicked(meet)}>
              <DeleteIcon />
            </button>
          }
        </Fragment>
      ) : <Fragment />}
    </td>
  </tr>
);

const SmallGridRow = ({ meet, clubOrgUnitId, onUploadClicked, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{meet.meetName}</span>&nbsp;
      {clubOrgUnitId === meet.hostOrgUnitId && meet.status === Constants.WORKFLOW_STATUS_APPROVED ? (
        <Fragment>
          {onDeleteClicked !== undefined && meet.hasResults === false &&
            <button
              className={global.IconButton}
              type="button"
              onClick={() => onDeleteClicked(meet)}>
              <DeleteIcon />
            </button>
          }
          {onUploadClicked !== undefined &&
            <button
              className={meet.hasResults === false ? global.IconButtonMobileMargin : global.IconButton}
              type="button"
              onClick={() => onUploadClicked(meet)}>
              <UploadIcon />
            </button>
          }
        </Fragment>
      ) : <Fragment />}
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Meet Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{meet.meetName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Host Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.orgUnitName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Dates</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{`${formatDate(meet.startDate)} - ${formatDate(meet.endDate)}`}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Classification</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.meetClassification}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.meetType}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Status</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{meet.status}</div>
      </div>
    </div>
  </div>
);

const LargeGrid = ({ data, isLoading, clubOrgUnitId, onUploadClicked, onDeleteClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Host Organization'} columnField={'orgUnitName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Dates'} columnField={'startDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Classification'} columnField={'meetClassification'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Type'} columnField={'meetType'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Status'} columnField={'status'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="7">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((meet, i) => <LargeGridRow key={i} meet={meet} clubOrgUnitId={clubOrgUnitId} onUploadClicked={onUploadClicked} onDeleteClicked={onDeleteClicked} />)
            : <tr><td colSpan="7">No Meets</td></tr>
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({ data, isLoading, clubOrgUnitId, onUploadClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((meet, i) => <SmallGridRow key={i} meet={meet} clubOrgUnitId={clubOrgUnitId} onUploadClicked={onUploadClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meets</div>
            </div>
          </div>
        )
    }
  </div>
);

const ClubMeetsGrid = ({ data, isLoading, clubOrgUnitId, onUploadClicked, onDeleteClicked }) => (
  <Fragment>
    <LargeGrid data={data} isLoading={isLoading} clubOrgUnitId={clubOrgUnitId} onUploadClicked={onUploadClicked} onDeleteClicked={onDeleteClicked} />
    <SmallGrid data={data} isLoading={isLoading} clubOrgUnitId={clubOrgUnitId} onUploadClicked={onUploadClicked} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default ClubMeetsGrid;