import useClubGroupsLoadingModal from './UseClubGroupsLoadingModal';

import PopUpModal from '../../../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../../common/components/buttons/SecondaryButton';

import { ERROR_MSG, LOADING_MSG } from '../../../../../../../common/utils/Constants';

const ClubGroupsLoadingModal = ({ isLoading, loadingMessage = LOADING_MSG, isError, errorMessage = ERROR_MSG }) => {
  const {
    onReloadClicked,
    onBackToClubInfoClicked
  } = useClubGroupsLoadingModal();

  if (isError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Club Groups Error'}
        displayPopUp={true}
        onModalCanceled={onReloadClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{errorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onReloadClicked}>Reload Club Groups</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackToClubInfoClicked}>Go Back To Club Info</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={loadingMessage}
        displayPopUp={isLoading === true} />
    );
  }
};

export default ClubGroupsLoadingModal;