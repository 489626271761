import { useState } from 'react';

import ClubRosterTransferData from './ClubRosterTransferData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_CLUB_ROSTER_TRANSFER_STATE = {
  ...BASIC_INITIAL_STATE
};

const useClubRosterTransferData = () => {
  const [clubRosterTransferState, setClubRosterTransferState] = useState(INITIAL_CLUB_ROSTER_TRANSFER_STATE);

  const postClubRosterTransfer = (clubTransferObj) => {
    return ClubRosterTransferData.postClubRosterTransferData(clubTransferObj, clubRosterTransferState, setClubRosterTransferState);
  };

  return {
    clubRosterTransferState,
    postClubRosterTransfer
  };
};

export default useClubRosterTransferData;