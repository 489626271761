import { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

const PasswordRequirementsList = ({ passwordRequirementsState, displayUsernameAsEmail = false }) => (
  <Fragment>
    <span>Password Requirements</span>
    <p style={{ fontSize: 14 }}>
      <FontAwesomeIcon color={passwordRequirementsState.password10Characters === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.password10Characters === true ? faCheckCircle : faCircle}></FontAwesomeIcon> No less than 10 characters<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordNumber === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordNumber === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 number<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordSpecialCharacter === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordSpecialCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 special character<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordLowercaseCharacter === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordLowercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 lowercase character<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordUppercaseCharacter === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordUppercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 uppercase character<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordNotEqualToUsername === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordNotEqualToUsername === true ? faCheckCircle : faCircle}></FontAwesomeIcon> Not equal to {displayUsernameAsEmail === true ? 'email' : 'username'}<br />
      <FontAwesomeIcon color={passwordRequirementsState.passwordNoSpaces === true ? 'green' : '#c1c6c8'} icon={passwordRequirementsState.passwordNoSpaces === true ? faCheckCircle : faCircle}></FontAwesomeIcon> No spaces
    </p>
  </Fragment>
);

export default PasswordRequirementsList;