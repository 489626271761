import Checkbox from "../../../../common/components/checkboxes/Checkbox";
import GridFriendlyMultiSelect from "../../../../common/components/multiSelect/GridFriendlyMultiSelect";

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ member, onSelected, showError, onMemberTypesValueChanged }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className="hidden-xs">&nbsp;{(member.firstName || member.middleName || member.lastName) ? `${member.firstName || ''}  ${member.preferredName !== '' && member.preferredName !== member.firstName ? '"' + member.preferredName + '"' : ''} ${member.middleName || ''} ${member.lastName || ''}` : <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatLeft}>
        <Checkbox
          label={''}
          name="isSelected"
          onChange={() => onSelected(member.personId)}
          checked={member.isSelected ?? false} />
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{(member.firstName || member.middleName || member.lastName) ? `${member.firstName || ''}  ${member.preferredName !== '' && member.preferredName !== member.firstName ? '"' + member.preferredName + '"' : ''} ${member.middleName || ''} ${member.lastName || ''}` : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-9'].join(' ')}>{member.memberId || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-12'].join(' ')}>Life Member Type(s)</div>
        <div className={[global.SmallTableRowData, 'col-xs-12'].join(' ')}>
          <GridFriendlyMultiSelect
            label=""
            name={"lifeMemberTypes" + member.personId}
            value={member.selectedMemberTypes}
            error={showError === true && member.selectedMemberTypes?.length === 0 && member.isSelected === true}
            message={showError === true && member.selectedMemberTypes?.length === 0 && member.isSelected === true ? 'At least one Life Member Type is required' : ''}
            options={member.options || []}
            onChange={(value) => { onMemberTypesValueChanged(member, value) }} />
        </div>
      </div>
    </div>
  </div>
);

const LSCStaffMembersGridSmall = ({ data, isLoading, onSelected, showError, onMemberTypesValueChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((member, i) => <GridRow key={i} member={member} showError={showError} onSelected={onSelected} onMemberTypesValueChanged={onMemberTypesValueChanged} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No LSC Life Member Renewals Available at This Time</div>
            </div>
          </div>
        )
    }
  </div>
);

export default LSCStaffMembersGridSmall;