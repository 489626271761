import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { DEFAULT_ID } from '../../utils/Constants';

const postGroupData = (orgGroupObj, state, setState) => {
  if (orgGroupObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    if (orgGroupObj.orgGroupFieldValue?.length > 0) {
      for (const orgGroupField of orgGroupObj.orgGroupFieldValue) {
        orgGroupField.groupTypeField = undefined;
      }
    }

    const url = `/OrgGroup`;

    return api.executeObject ? api.executeObject(url, 'POST', orgGroupObj) : null;
  }
};

const getGroupData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const url = `/OrgGroup/${orgGroupIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getGroupMembersData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const newState = {
      ...state,
      orgGroupId
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const url = `/OrgGroup/Member/${orgGroupIdForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putGroupData = (orgGroupId, orgGroupObj, state, setState) => {
  if (orgGroupId > 0 && orgGroupObj) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    if (orgGroupObj.orgGroupFieldValue?.length > 0) {
      for (const orgGroupField of orgGroupObj.orgGroupFieldValue) {
        orgGroupField.groupTypeField = undefined;
      }
    }

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const url = `/OrgGroup/${orgGroupIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'PUT', orgGroupObj) : null;
  }
};

const putGroupMembersData = (orgGroupId, groupMembersArray, groupAttendeeTypeName, state, setState) => {
  if (orgGroupId > 0 && groupMembersArray) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const groupAttendeeTypeNameForUrl = encodeURIComponent(groupAttendeeTypeName);
    const url = `/OrgGroup/${orgGroupIdForUrl}/Member?groupAttendeeType=${groupAttendeeTypeNameForUrl}`;

    return api.executeObject ? api.executeObject(url, 'PUT', groupMembersArray) : null;
  }
};

const deleteGroupData = (orgGroupId, state, setState) => {
  if (orgGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);

    const orgGroupIdForUrl = encodeURIComponent(orgGroupId);
    const url = `/OrgGroup/${orgGroupIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_GROUP_STATE = {
  ...BASIC_INITIAL_STATE,
};

const INITIAL_GROUP_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE,
  orgGroupId: DEFAULT_ID
};

const GroupData = {
  INITIAL_GROUP_STATE,
  INITIAL_GROUP_MEMBERS_STATE,
  postGroupData,
  getGroupData,
  getGroupMembersData,
  putGroupData,
  putGroupMembersData,
  deleteGroupData
};

export default GroupData;