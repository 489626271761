import useClubNonAthleteRosterForm from "./UseClubNonAthleteRosterForm";

import ClubGroupCombobox from "../../../components/comboboxes/clubGroupCombobox/ClubGroupCombobox";

import Input from "../../../../common/components/inputs/Input";

import global from '../../../../common/components/GlobalStyle.module.css';
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const ClubNonAthleteRosterForm = ({
  onSubmitFormCallback,
  onSyncSelectedMembers,
  orgUnitId,
  PRACTICE_GROUP_TYPE_ID, SITE_GROUP_TYPE_ID,
  vendorSyncState,
  transferState,
  clearVendorSync,
  clearTransferState,
  searchClubNonAthleteRoster,
  baseUsasClubState
}) => {

  const {
    formState,
    errorState,
    transferPopupState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFilterClicked,
    onTransferSelectedMembers,
    onTransferModalCancelClicked,
    onTransferModalConfirmClicked,
    confirmationMessage
  } = useClubNonAthleteRosterForm(onSubmitFormCallback, clearVendorSync, clearTransferState, transferState, searchClubNonAthleteRoster, baseUsasClubState);

  return (
    <>
      <form onSubmit={handleSubmit} >
        <div className='row'>
          {PRACTICE_GROUP_TYPE_ID > 0 && orgUnitId &&
            <div className="col-xs-12 col-sm-6 col-md-3">
              <ClubGroupCombobox
                label={"Practice Group"}
                name={"practiceGroupId"}
                valueToMatch={formState.practiceGroupName}
                error={errorState.practiceGroupId}
                message={errorState.practiceGroupId}
                onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'practiceGroupId', newValueLabel, 'practiceGroupName'); }}
                orgUnitId={orgUnitId}
                groupTypeId={PRACTICE_GROUP_TYPE_ID} />
            </div>
          }
          {SITE_GROUP_TYPE_ID > 0 && orgUnitId &&
            <div className="col-xs-12 col-sm-6 col-md-3">
              <ClubGroupCombobox
                label={"Site Group"}
                name={"siteGroupId"}
                valueToMatch={formState.siteGroupName}
                error={errorState.siteGroupId}
                message={errorState.siteGroupId}
                onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'siteGroupId', newValueLabel, 'siteGroupName', e); }}
                orgUnitId={orgUnitId}
                groupTypeId={SITE_GROUP_TYPE_ID}
              />
            </div>
          }
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="First or Preferred Name"
              name="firstOrPreferredName"
              value={formState.firstOrPreferredName}
              error={errorState.firstOrPreferredName}
              message={errorState.firstOrPreferredName}
              onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Input
              label="Last Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
        </div>
        <div className="row">
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} type='submit'>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} type='button' onClick={onClearFilterClicked}>Clear Filter</button>
          </div>
          {vendorSyncState.syncPersons.length > 0 &&
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <button className={global.PlainButton} type="button" onClick={onSyncSelectedMembers}>Sync Selected Members</button>
            </div>
          }
          {transferState.transferPersons.length > 0 && (
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <button className={global.PlainButton} type="button" onClick={onTransferSelectedMembers}>Transfer Selected Members</button>
            </div>
          )}
        </div>
      </form >
      <PopUpModal widthPct={90} maxWidth={720} title={'Transfer Members?'} displayPopUp={transferPopupState.displayModal === true} onModalCanceled={onTransferModalCancelClicked}>
        <div className="row">
          <p className="col-xs-12">
          Are you sure you want to transfer the selected members to Unattached? After transferring, they will appear on your roster with a Pending status until the transfer is approved.
            <br /><br />
            <span style={{ fontSize: 20, color: 'darkgrey' }}>
              { confirmationMessage }
            </span>
          </p>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onTransferModalConfirmClicked}>
              Yes, Transfer
            </PrimaryButton>
            &nbsp;
            <SecondaryButton type="button" onClick={onTransferModalCancelClicked}>
              Cancel
            </SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </>
  );
};

export default ClubNonAthleteRosterForm;