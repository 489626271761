import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

const postClubRosterTransferData = (clubTransferObj, state, setState) => {
  if (clubTransferObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonClubTransfer/roster`;

    return api?.executeObject
      ? api.executeObject(url, 'POST', clubTransferObj)
      : null;
  }
};

const ClubRosterTransferData = {
  postClubRosterTransferData,
};

export default ClubRosterTransferData;
