import { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions'

const LargeGrid = ({ state, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th style={{ lineHeight: 1.2 }}>Touchpoint Date</th>
        <th>Notes</th>
        <th>Type</th>
        <th style={{ lineHeight: 1.2 }}>Entered By Person</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr>
            {state.isArrayLoading === true ?
              <td colSpan="3">Loading...</td>
              : <td colSpan="3">No Touchpoints</td>
            }
          </tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map(touchpoint => (
            <tr key={touchpoint.usasClubTouchpointId}>
              <td>{formatDate(touchpoint.touchpointDate)}</td>
              <td style={{ width: '700px', maxWidth: '700px', lineHeight: 1.2 }}>{touchpoint.touchpointNote?.length > 1000 ? `${touchpoint.touchpointNote.slice(0, 999).trim()}...` : touchpoint.touchpointNote}</td>
              <td style={{ lineHeight: 1.2 }}>{touchpoint.touchpointTypeName}</td>
              <td>{touchpoint.enteredByFirstName} {touchpoint.enteredByLastName}</td>
              <td>
                <button className={global.IconButtonMargin} type="button"
                  onClick={(e) => onEdit(e, touchpoint.usasClubTouchpointId, touchpoint.touchpointNote, touchpoint.touchpointDate, touchpoint.touchpointTypeId)}>
                  <EditIcon />
                </button>
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDelete(e, touchpoint.usasClubTouchpointId)}>
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan="3">No Touchpoints</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

const SmallGrid = ({ state, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Touchpoints</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map(touchpoint => (
          <div className={global.SmallTableRow} key={touchpoint.usasClubTouchpointId}>
            <div className={global.SmallTableRowHead}><span>{touchpoint.touchpointNote?.length > 1000 ? `${touchpoint.touchpointNote.slice(0, 999).trim()}...` : touchpoint.touchpointNote}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, touchpoint.usasClubTouchpointId)}>
                <DeleteIcon />
              </button>
              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEdit(e, touchpoint.usasClubTouchpointId, touchpoint.touchpointNote, touchpoint.touchpointDate, touchpoint.touchpointTypeId)}>
                <EditIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Touchpoint Date</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{formatDate(touchpoint.touchpointDate)}</div>
                <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Touchpoint Type</div>
                <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{touchpoint.touchpointTypeName}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Touchpoints</div>
            </div>
          </div>
        )
    }
  </div >
);

const ClubTouchpointsGrid = ({ state, onEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} onEdit={onEdit} onDelete={onDelete} />
    <SmallGrid state={state} onEdit={onEdit} onDelete={onDelete} />
  </Fragment>
);

export default ClubTouchpointsGrid;