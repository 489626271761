import ClubOwnerTypeContextProvider from './state/clubOwnerType/ClubOwnerTypeContextProvider';
import BusinessEntityContextProvider from './state/businessEntity/BusinessEntityContextProvider';
import ClubInactiveReasonContextProvider from './state/clubInactiveReason/ClubInactiveReasonContextProvider';
import OrgRoleMultiselectContextProvider from './state/orgRoleMultiselect/OrgRoleMultiselectContextProvider';

import OrganizationContextProvider from '../common/state/organization/OrganizationContextProvider';
import LscContextProvider from '../common/state/lsc/LscContextProvider';
import CourseContextProvider from '../common/state/course/CourseContextProvider';
import PoolDimensionsContextProvider from '../common/state/poolDimensions/PoolDimensionsContextProvider';
import PoolConfigurationContextProvider from '../common/state/poolConfiguration/PoolConfigurationContextProvider';
import OrgRoleStaffRoleHierarchyContextProvider from '../common/state/orgRoleStaffRoleHierarchy/OrgRoleStaffRoleHierarchyContextProvider';
import MeetClassificationContextProvider from '../common/state/meet/meetClassification/MeetClassificationContextProvider';
import BPMeetClassificationContextProvider from '../common/state/meet/blockPartyMeetClassification/BPMeetClassificationContextProvider';
import MeetSoftwareContextProvider from '../common/state/meet/meetSoftware/MeetSoftwareContextProvider';
import ClubAffiliationTypeContextProvider from '../common/state/clubAffiliationType/ClubAffiliationTypeContextProvider'
import VendorContextProvider from '../common/state/vendor/VendorContextProvider';
import RouteCodeValueContextProvider from '../common/state/routeCode/RouteCodeValueContextProvider';
import LscLifeMemberNewContextProvider from '../common/state/memberAdministration/lscLifeMemberNew/LscLifeMemberNewContextProvider';

const AppStateContextProviders = ({ children }) => {
  return (
    <LscLifeMemberNewContextProvider>
      <OrgRoleMultiselectContextProvider>
        <OrganizationContextProvider>
          <LscContextProvider>
            <CourseContextProvider>
              <PoolDimensionsContextProvider>
                <PoolConfigurationContextProvider>
                  <VendorContextProvider>
                    <ClubOwnerTypeContextProvider>
                      <BusinessEntityContextProvider>
                        <ClubInactiveReasonContextProvider>
                          <OrgRoleStaffRoleHierarchyContextProvider>
                            <MeetClassificationContextProvider>
                              <BPMeetClassificationContextProvider>
                                <MeetSoftwareContextProvider>
                                  <ClubAffiliationTypeContextProvider>
                                    <RouteCodeValueContextProvider>
                                      {children}
                                    </RouteCodeValueContextProvider>
                                  </ClubAffiliationTypeContextProvider>
                                </MeetSoftwareContextProvider>
                              </BPMeetClassificationContextProvider>
                            </MeetClassificationContextProvider>
                          </OrgRoleStaffRoleHierarchyContextProvider>
                        </ClubInactiveReasonContextProvider>
                      </BusinessEntityContextProvider>
                    </ClubOwnerTypeContextProvider>
                  </VendorContextProvider>
                </PoolConfigurationContextProvider>
              </PoolDimensionsContextProvider>
            </CourseContextProvider>
          </LscContextProvider>
        </OrganizationContextProvider>
      </OrgRoleMultiselectContextProvider>
    </LscLifeMemberNewContextProvider>
  );
};

export default AppStateContextProviders;