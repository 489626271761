import { Fragment } from "react";

import useSessionEndingExpiredPassword from "./UseSessionEndingExpiredPassword";

import PopUpModal from "../../../../../dialogs/PopUpModal";
import SecondaryButton from "../../../../../buttons/SecondaryButton";
import ExpiredPasswordForm from "../../../../../forms/login/expiredPassword/ExpiredPasswordForm";

import Constants from "../../../../../../utils/Constants";

const SessionEndingExpiredPassword = ({ popupState, updatePopupStep, onPopupClosed }) => {
  const {
    isLoading,
    loginErrorPopupState,
    username,
    onCloseLoginErrorPopupClicked,
    onSubmitFormCallback
  } = useSessionEndingExpiredPassword(popupState, updatePopupStep, onPopupClosed);

  return (
    <Fragment>
      <div className={'col-xs-12 usas-extra-bottom-margin'}>
        <b>Password Expired</b>
        <p>Your password has expired and must be changed</p>
        <ExpiredPasswordForm
          inputColClassName="col-xs-12 col-sm-6"
          submitButtonText={'Submit'}
          secondaryButtonText={'Cancel'}
          userName={username}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={() => onPopupClosed(true)} />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SessionEndingExpiredPassword;