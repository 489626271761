import useOrgRoleMultiselect from './UseOrgRoleMultiselect';

import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../common/components/GlobalStyle.module.css';

const OrgRoleMultiselect = ({ label, name, value, error, message, onChange, roleGroupId }) => {
  const { orgRoleMultiselectState } = useOrgRoleMultiselect(roleGroupId);

  return orgRoleMultiselectState.message
    ? <span className={global.LoadingMsg}>{orgRoleMultiselectState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={orgRoleMultiselectState.options}
        error={error}
        message={message}
        isLoading={orgRoleMultiselectState.isArrayLoading}
        onChange={onChange} />
    );
};

export default OrgRoleMultiselect;