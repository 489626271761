/* eslint-disable react-refresh/only-export-components */
import { createContext, useState } from 'react';

import MeetResultsStatusData from './MeetResultsStatusData';

export const MeetResultsStatusStateContext = createContext();

const MeetClassificationContextProvider = ({ children }) => {
  const stateHook = useState(MeetResultsStatusData.INITIAL_STATE);

  return (
    <MeetResultsStatusStateContext.Provider value={stateHook}>
      {children}
    </MeetResultsStatusStateContext.Provider>
  );
};

export default MeetClassificationContextProvider;