import { Fragment } from 'react';

import ClubGroupConfirmationFormGridAdd from './ClubGroupConfirmationFormGridAdd';
import ClubGroupConfirmationFormGridRemove from './ClubGroupConfirmationFormGridRemove';

import useClubGroupConfirmationFormGrid from './UseClubGroupConfirmationFormGrid';

import Spinner from '../../../../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../../common/components/buttons/SecondaryButton';

const ClubGroupConfirmationFormGrid = ({ gridData, isLoading, groupName, secondaryButtonText = 'Cancel', onSubmitFormCallback,
  onSecondaryButtonClicked, onErrorCaught = undefined }) => {
  const {
    addedAthletes,
    removedAthletes,
    onSaveClicked,
    onUpdateIsSelected,
  } = useClubGroupConfirmationFormGrid(gridData, onSubmitFormCallback, onErrorCaught);

  if (isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <p><b>Athletes To Be Added To {groupName}</b></p>
        </div>
      </div>
      <ClubGroupConfirmationFormGridAdd gridData={addedAthletes} onUpdateIsSelected={onUpdateIsSelected} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <p><b>Athletes To Be Removed From {groupName}</b></p>
        </div>
      </div>
      <ClubGroupConfirmationFormGridRemove gridData={removedAthletes} onUpdateIsSelected={onUpdateIsSelected} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default ClubGroupConfirmationFormGrid;