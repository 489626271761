import React, { Fragment } from 'react';

import useMeetResultsUpload from './UseMeetResultsUpload';

import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

import PopUpModal from '../../dialogs/PopUpModal';
import Constants from '../../../utils/Constants';

import MeetUploadStatus from '../../infoPanels/meetUploadStatus/MeetUploadStatus';  
import Headings from '../../../components/headings/Headings';
import global from '../../../components/GlobalStyle.module.css';

const MeetResultsUpload = ({ meetId, meetStartDate, meetEndDate }) => {
  const FileUpload = () => {
    const hiddenFileInput = React.useRef(null);
    let fileReader;
    let fileType;

    const handleSelectFileClicked = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      hiddenFileInput.current.click();
    };

    const handleFileRead = (event) => {
      const fileContent = fileReader.result;
      processFile(fileContent, fileType);
    };

    const handleFileChosen = (file) => {
      fileType = file.name.substring(file.name.length - 3);

      setFileUiState({ ...fileUiState, fileName: file.name });

      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    };

    return (
      <>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={handleSelectFileClicked}>Select a File</PrimaryButton>&nbsp;
          Hy-Tek (*.cl2), SDIF (*.sd3), Lenex (*.lef)
        </div>
        <input
          type='file'
          id='file'
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={e => handleFileChosen(e.target.files[0])} />
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          {fileUiState.fileName}
        </div>
      </div>
      </>
    );
  };

  const {
    meetState,
    meetResultsState,
    meetRelayResultsState,
    meetResultsStatusState,
    jsonFileState,
    uiState,
    fileUiState,
    meetTimesStatusState,
    uploadPopupState,
    processFile,
    onDownloadClicked,
    onSubmitMeetResults,
    onSubmitIncrementalMeetResults,
    INVALID_FILE_MESSAGE,
    ZIP_FILE_MESSAGE,
    onRefreshStatus,
    setFileUiState,
    onUploadModalCancelClicked
  } = useMeetResultsUpload(meetId, meetStartDate, meetEndDate);

  if (meetResultsStatusState.objData.isProcessing === true) {
    return (
      <MeetUploadStatus statusObject={meetResultsStatusState.objData} onRefreshStatus={onRefreshStatus} />
    )
  }
  else {
    return (
      <Fragment>
        {(meetTimesStatusState.objData.individualTimes > 0 || meetTimesStatusState.objData.relayTimes > 0)
          && meetState.objData.isUploadLocked === false ?
          <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <Headings.H4>Meet results have already been uploaded for this meet</Headings.H4>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <p className={global.Bold}>Individual Times: {meetTimesStatusState.objData.individualTimes}</p>
                <p className={global.Bold}>Relay Times: {meetTimesStatusState.objData.relayTimes}</p>
              </div>
            </div>
          </Fragment> : <Fragment />
        }

        {meetState.objData.isUploadLocked === true ?
          <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <Headings.H4>Meet results have been uploaded and validated</Headings.H4>
              </div>
            </div>
          </Fragment> :
          <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <FileUpload />
              </div>
            </div>
            { 
              fileUiState.fileName !== "" &&
              jsonFileState.isValidFileType === true && 
              jsonFileState.isZipFile === false ?
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12">
                  <p className={global.Bold}>Individual Times: {jsonFileState.indTimesCount}</p>
                  <p className={global.Bold}>Relay Times: {jsonFileState.relayTimesCount}</p>
                  <p className={global.Bold}>File Errors: {jsonFileState.errors} {jsonFileState.errors > 0 ? " - download JSON files to view error details" : ""}</p>
                </div>
              </div> : <Fragment />
            }
          </Fragment>
        }

        {jsonFileState.isValidFileType === false || jsonFileState.isZipFile === true
          ? <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <p className={global.Error}>{jsonFileState.isValidFileType === false ? INVALID_FILE_MESSAGE : ZIP_FILE_MESSAGE}</p>
            </div>
          </div>
          : <Fragment />}

        {uiState.canUpload === false
          ?
          <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <p className={global.Bold}>{uiState.message}</p>
              </div>
            </div>
          </Fragment>
          : <Fragment />
        }

        {meetResultsState.message.length > 0
          ? <Fragment>
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12">
                <p className={global.Bold}>Individual Times Save Message: {meetResultsState.message}</p>
              </div>
            </div>
          </Fragment>
          : <Fragment />}

        {meetRelayResultsState.message.length > 0
          ? <Fragment>
            <div className="row usas-extra-top-margin">
              <div className={["col-xs-12", global.Bold].join(' ')}>Relay Times Save Message: {meetRelayResultsState.message}</div>
            </div>
          </Fragment>
          : <Fragment />}

        <div className="row usas-extra-top-margin usas-extra-bottom-margin"
          style={{ display: jsonFileState.individualTimesContent.length === 0 ? 'none' : 'flex' }}>
          <div className="col-xs-12 usas-extra-top-margin">
            {jsonFileState.isValidFileType === true && jsonFileState.isZipFile === false ?
              <SecondaryButton type="button" onClick={onDownloadClicked}>Download JSON Files</SecondaryButton> : <Fragment />
            }
            &nbsp;&nbsp;
            {
              uiState.canUpload === true &&
                jsonFileState.isValidFileType === true &&
                jsonFileState.isZipFile === false ?
                <PrimaryButton type="button" onClick={onSubmitMeetResults}>Upload Meet Results</PrimaryButton> : <Fragment />
            }
            &nbsp;&nbsp;
            {
              uiState.canUpload === true &&
                jsonFileState.isValidFileType === true &&
                jsonFileState.isZipFile === false &&
                (meetTimesStatusState.objData.individualTimes > 0 || meetTimesStatusState.objData.relayTimes > 0) ?
                <PrimaryButton type="button" onClick={onSubmitIncrementalMeetResults}>Append Meet Results</PrimaryButton> : <Fragment />
            }
          </div>
        </div>
        <PopUpModal widthPct={90} maxWidth={720} title={'Upload Meet Results?'} displayPopUp={uploadPopupState.displayModal === true} onModalCanceled={onUploadModalCancelClicked}>
          <div className="row">
            <p className="col-xs-12">
            Your times have been successfully uploaded. It may take up to 4 hours for the times to become visible in Reports and Data Hub. <b>Please do not perform multiple uploads.</b>
            </p>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onUploadModalCancelClicked}>
                Close
              </SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.UPLOADING_MSG}
          displayPopUp={
            meetResultsState?.isObjLoading ||
            meetResultsState?.isSaving ||
            meetRelayResultsState?.isSaving ||
            meetTimesStatusState.isObjLoading ||
            meetState.isObjLoading} />
      </Fragment>
    );
  };
};

export default MeetResultsUpload;