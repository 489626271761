import { Fragment } from 'react';

import useClubGroupClubSiteEdit from './UseClubGroupClubSiteEdit';

import ClubGroupsLoadingModal from '../../components/dialogs/loadingModal/ClubGroupsLoadingModal';

import Headings from '../../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import GroupInfoForm from '../../../../../../common/components/forms/groups/GroupInfoForm';
import ClubSiteAttributesForm from '../../../../../../common/components/forms/groups/ClubSiteAttributesForm';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const ClubGroupClubSiteEdit = () => {
  const {
    isLoading,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    orgUnitId
  } = useClubGroupClubSiteEdit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Club Site Group</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <GroupInfoForm
          formState={formState}
          errorState={errorState}
          onFormValueChanged={onFormValueChanged}
          orgUnitId={orgUnitId}
          attributeForm={<ClubSiteAttributesForm
            formState={formState}
            errorState={errorState}
            orgUnitId={orgUnitId}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            alwaysGetFacilities={true} />
          } />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <ClubGroupsLoadingModal
        isLoading={isLoading}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default ClubGroupClubSiteEdit;