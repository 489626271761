import useClubGroupAthletesFilter from "./UseClubGroupAthletesFilter";

import Input from "../../../../../../../common/components/inputs/Input";
import Dropdown from "../../../../../../../common/components/dropdowns/Dropdown";

import global from '../../../../../../../common/components/GlobalStyle.module.css';
import style from './ClubGroupAthletesFilter.module.css';

const ClubGroupAthletesFilter = ({ onSubmitFormCallback, onIsDirtyChanged = undefined, onErrorCaught = undefined }) => {
  const {
    displayClearFilterButton,
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFilterClicked,
    onValueTextPairChanged,
    COMPETITION_GENDER_OPTIONS
  } = useClubGroupAthletesFilter(onSubmitFormCallback, onIsDirtyChanged, onErrorCaught);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <label className={style.FilterAgeHeading}>Filter By Age</label>
        </div>
        <div className="col-xs-12 col-sm-1">
          <Input
            label="Start"
            name="startAge"
            type={'number'}
            value={formState.startAge}
            error={errorState.startAge}
            message={errorState.startAge}
            onChange={(value) => { updateFormState('startAge', value); }} />
        </div>
        <div className="col-xs-12 col-sm-1">
          <Input
            label="End"
            name="endAge"
            type={'number'}
            value={formState.endAge}
            error={errorState.endAge}
            message={errorState.endAge}
            onChange={(value) => { updateFormState('endAge', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Filter Athlete By Competition Category:"
            name="competitionGenderTypeId"
            value={formState.competitionGenderTypeId}
            error={errorState.competitionGenderTypeId}
            message={errorState.competitionGenderTypeId}
            options={COMPETITION_GENDER_OPTIONS}
            onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'competitionGenderTypeId', valueLabel, 'competitionGenderTypeName'); }} />
        </div>
        <div className={["col-xs-12", "col-sm-4", style.FilterButtons].join(' ')}>
          <button className={global.PlainButton} type="submit" disabled={isDirty !== true}>Filter</button>
          {displayClearFilterButton === true &&
            <button className={global.PlainButton} onClick={onClearFilterClicked} >Clear Filter</button>
          }
        </div>

      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.formError &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.formError || ''}</p>
          </div>
        </div>
      }
    </form>
  );
};

export default ClubGroupAthletesFilter;