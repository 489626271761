import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';

import validate from './ClubGroupClubSiteValidation';

import { createGroupObjFromGroupInfoForm, createOrgGroupFieldValues, getBaseGroupData } from '../../utils/ClubGroupsUtils';

import NavLinks from '../../../NavLinks';

import useClubGroupsData from '../../../../../state/clubGroups/UseClubGroupsData';

import useBaseUsasClubData from '../../../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupCategoryData from '../../../../../../common/state/groupCategory/UseGroupCategoryData';
import useGroupFieldNamesData from '../../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';

import useForm from '../../../../../../common/utils/UseForm';
import { DEFAULT_ID, BLANK_DATE_STRING } from '../../../../../../common/utils/Constants';

const INITIAL_STATE = {
  pageErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  orgUnitId: '',
  groupTypeId: DEFAULT_ID,
  groupTypeName: '',
  groupCategories: [],
  groupName: '',
  groupCode: '',
  groupDescription: '',
  facilityId: DEFAULT_ID,
  facilityName: '--',
  leadCoachId: '',
  leadCoachName: '--',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING
};

const useClubGroupClubSiteAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { groupCategoryState } = useGroupCategoryData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { clubGroupInfoState, postClubGroup } = useClubGroupsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setFormData, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    try {
      if (location.state?.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        getOrgGroupProperties(location.state.groupTypeId);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (location.state?.groupTypeId && location.state?.groupTypeName) {
        if (baseUsasClubState.isObjLoaded === true && groupCategoryState.isArrayLoaded === true
          && orgGroupPropertiesState.isArrayLoaded === true && orgGroupPropertiesState.groupTypeId === location.state?.groupTypeId) {
          setFormData({
            ...formState,
            groupTypeId: location.state.groupTypeId,
            groupTypeName: location.state.groupTypeName,
            orgUnitId: baseUsasClubState.objData.orgUnitId
          });
        }
      } else {
        navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState, groupCategoryState, orgGroupPropertiesState]);

  async function submitFormCallback(formState) {
    try {
      const newOrgGroupObj = createGroupObjFromGroupInfoForm(getBaseGroupData(), formState);

      newOrgGroupObj.orgGroupFieldValue = createOrgGroupFieldValues([
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EFFECTIVE_DATE, fieldValue: formState.effectiveDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_EXPIRATION_DATE, fieldValue: formState.expirationDate },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_FACILITY, fieldValue: formState.facilityId },
        { fieldName: GROUP_CONSTANTS.ORG_GROUP_TYPE_FIELD_NAME_LEAD_COACH, fieldValue: formState.leadCoachId }
      ], orgGroupPropertiesState.arrayData);

      const postClubGroupResult = await postClubGroup(newOrgGroupObj);

      if (postClubGroupResult?.objData?.orgGroupId) {
        navigate(NavLinks.CLUB_GROUPS);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  }

  return {
    isLoading: groupCategoryState.isArrayLoading || orgGroupPropertiesState.isArrayLoading,
    isSaving: clubGroupInfoState.isObjLoading,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackClicked,
    orgUnitId: baseUsasClubState.objData.orgUnitId || ''
  };
};

export default useClubGroupClubSiteAdd;