import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useClubGroupsData from '../../../state/clubGroups/UseClubGroupsData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';

const INITIAL_STATE = {
  displayDeletePopup: false,
  deletePopupGroupObj: {},
  pageErrorMessage: ''
};

const useClubGroups = () => {
  const navigate = useNavigate();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { clubGroupsState, clubGroupInfoState, getClubGroups, deleteClubGroup } = useClubGroupsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddClubGroupClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS_GROUP_TYPE);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onEditClubGroupClicked = (event, orgGroupId, groupTypeId, groupTypeName) => {
    try {
      event?.preventDefault();

      switch (groupTypeName) {
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_LIST:
          navigate(NavLinks.CLUB_GROUPS_LIST_EDIT, { state: { orgGroupId, groupTypeId } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP:
          navigate(NavLinks.CLUB_GROUPS_PRACTICE_EDIT, { state: { orgGroupId, groupTypeId } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_TRAVEL_TRIP:
          navigate(NavLinks.CLUB_GROUPS_TRAVEL_EDIT, { state: { orgGroupId, groupTypeId } });
          break;
        case GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE:
          navigate(NavLinks.CLUB_GROUPS_CLUB_SITE_EDIT, { state: { orgGroupId, groupTypeId } });
          break;
        default:
          throw new Error(`Invalid group type: ${groupTypeName}`);
      }
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onEditClubGroupMembersClicked = (event, orgGroupId) => {
    try {
      event?.preventDefault();

      navigate(NavLinks.CLUB_GROUPS_MEMBER_DETAIL, { state: { orgGroupId } });
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onDeleteClubGroupClicked = (event, orgGroupId, groupName) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: true,
        deletePopupGroupObj: { orgGroupId, groupName }
      });
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onDeleteModalConfirmClicked = async (event) => {
    try {
      event?.preventDefault();
      const orgGroupId = state.deletePopupGroupObj.orgGroupId;

      setState({ ...INITIAL_STATE });

      await deleteClubGroup(orgGroupId);

      await getClubGroups(baseUsasClubState.objData.orgUnitId);
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  const onModalCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({ ...INITIAL_STATE });
    } catch (e) {
      setState({
        ...state,
        pageErrorMessage: e.message
      });
    }
  };

  useEffect(() => {
    async function loadClubGroups() {
      try {
        if (baseUsasClubState.isObjLoaded === true) {
          await getClubGroups(baseUsasClubState.objData.orgUnitId);
        }
      } catch (e) {
        setState({
          ...state,
          pageErrorMessage: e.message
        });
      }
    }
    loadClubGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: clubGroupsState.isArrayLoading,
    isSaving: clubGroupInfoState.isSaving,
    gridData: clubGroupsState.arrayData,
    state,
    onAddClubGroupClicked,
    onEditClubGroupClicked,
    onDeleteClubGroupClicked,
    onEditClubGroupMembersClicked,
    onDeleteModalConfirmClicked,
    onModalCancelClicked
  };
};

export default useClubGroups;