import { useState } from 'react';
import MeetResultsData from './MeetResultsData';

const useMeetResutlsData = () => {
  const [meetResultsState, setMeetResultsState] = useState(MeetResultsData.INITIAL_STATE);
  const [meetRelayResultsState, setMeetRelayResultsState] = useState(MeetResultsData.INITIAL_STATE);
  const [meetResultsStatusState, setMeetResultsStatusState] = useState(MeetResultsData.INITIAL_STATE);

  const postMeetResults = (meetResultsObj, incrementalMeetTimes) => MeetResultsData.postMeetResultsData(meetResultsObj, incrementalMeetTimes, meetResultsState, setMeetResultsState);
  const postMeetRelayResults = (meetResultsObj, incrementalMeetTimes) => MeetResultsData.postMeetResultsData(meetResultsObj, incrementalMeetTimes, meetRelayResultsState, setMeetRelayResultsState);
  
  const getMeetResultsUploadStatus = (meetId) => MeetResultsData.getMeetResultsUploadStatusData(meetId, meetResultsStatusState, setMeetResultsStatusState);

  return {
    meetResultsState,
    meetRelayResultsState,
    meetResultsStatusState,
    postMeetResults,
    postMeetRelayResults,
    getMeetResultsUploadStatus
  };
}

export default useMeetResutlsData;