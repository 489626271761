import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const putTimeProcessCompletedData = (meetId, isIndividualTimes, isRelayTimes, state, setState) => {
  const api = initApi(API_NAMES.MEET, state, setState);

  const meetIdForUrl = encodeURIComponent(meetId);
  const url = `/Meet/${meetIdForUrl}/TimesProcessCompleted?isIndividualTimes=${isIndividualTimes}&isRelayTimes=${isRelayTimes}`;

  return api?.executeArray ? api.executeArray(url, 'PUT') : null;
};

const TimesProcessCompletedData = {
  putTimeProcessCompletedData
}

export default TimesProcessCompletedData;