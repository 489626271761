import { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import usePasswordInput from "./UsePasswordInput";

import Input from "./Input";

const PasswordInput = ({ label, name, value, error, message, onChange, autoFocus = false, initialHidePasswordValue = true }) => {
  const {
    hidePassword,
    onToggleHideShowPassword
  } = usePasswordInput(initialHidePasswordValue);

  return (
    <Fragment>
      <FontAwesomeIcon icon={hidePassword === false ? faEye : faEyeSlash} onClick={() => onToggleHideShowPassword()} style={{ cursor: 'pointer', marginRight: hidePassword === false ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
      <Input
        label={label}
        name={name}
        value={value}
        error={error}
        message={message}
        autoFocus={autoFocus}
        type={hidePassword === false ? 'text' : 'password'}
        onChange={(value) => { onChange(value) }} />
    </Fragment>
  );
};

export default PasswordInput;