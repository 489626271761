/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import useClubNonAthleteRosterData from '../../../state/clubNonAthleteRoster/UseClubNonAthleteRosterData';
import useClubRosterVendorSyncData from '../../../state/clubRosterVendorSync/UseClubRosterVendorSyncData';

import useBaseUsasClubData from '../../../../common/state/clubBase/UseBaseUsasClubData';
import useOrgGroupTypesData from '../../../../common/state/orgGroupTypes/UseOrgGroupTypesData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useMemberPersistentStorage from '../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

const useClubNonAthleteRoster = () => {
  const navigate = useNavigate();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { baseUsasClubState } = useBaseUsasClubData();
  const { orgGroupTypesState, getOrgGroupTypes } = useOrgGroupTypesData();
  const { clubNonAthleteRosterState, searchClubNonAthleteRoster } = useClubNonAthleteRosterData();
  const { clubRosterVendorSyncState, setClubRosterVendorSyncState, postClubRosterVendorSync } = useClubRosterVendorSyncData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const [ state, setState ] = useState(getInitialState());
  const [ vendorSyncState, setVendorSyncState ] = useState({ syncPersons: [] });
  const [ transferState, setTransferState ] = useState({ transferPersons: [] });

  const onEditNonAthlete = (personId) => {
    if (personId && baseUsasClubState.objData?.orgUnitId) {
      const returnUrl = getGlobalRoute('CLUB_NONATHLETE_ROSTER')?.route;
      setPersistentStorage(
        personId,
        import.meta.env.VITE_PROJECT_NAME,
        returnUrl,
        'Back to Club'
      );

      const routeObj = getGlobalRoute('MEMBER_INFO');
      navigateToCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, navigate);
    }
  };

  const onSubmitFormCallback = (formState) => {
    if (baseUsasClubState.isObjLoaded === true && formState) {
      searchClubNonAthleteRoster(baseUsasClubState.objData.usasClubId, createFilterObj(formState));
    }
  };

  const onSyncSelectedMembers = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postClubRosterVendorSync(vendorSyncState.syncPersons);
  }

  const onTransferCheckbox = (e, personOrgRoleDurationId, unClubId, firstName, preferredName, lastName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personArray = transferState.transferPersons;
    const idx = personArray.findIndex(
      (x) => x.personOrgRoleDurationId === personOrgRoleDurationId
    );

    if (idx > -1) {
      let removedItem = personArray.splice(idx, 1);
    } else {
      const memberTransferObj = {
        personOrgRoleDurationId: personOrgRoleDurationId,
        transferOrgUnitId: unClubId,
        name: `${firstName} ${lastName}`,
      };

      personArray.push(memberTransferObj);
    }

    setTransferState({
      ...transferState,
      transferPersons: personArray,
    });
  };

  const onVendorSyncCheckbox = (e, memberId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personArray = vendorSyncState.syncPersons;
    const idx = personArray.findIndex(x => x.memberId === memberId);

    if (idx > -1) {
      let removedItem = personArray.splice(idx, 1);
    } else {
      const memberObj = {
        orgUnitId: baseUsasClubState.objData?.orgUnitId || Constants.DEFAULT_ID,
        memberId: memberId
      }

      personArray.push(memberObj);
    }

    setVendorSyncState({
      ...vendorSyncState,
      syncPersons: personArray
    });
  }

  const clearTransferState = () => {
    setTransferState({ ...transferState, transferPersons: [] });
  };

  const clearVendorSync = () => {
    setVendorSyncState({...vendorSyncState, syncPersons: []})

    setClubRosterVendorSyncState({
      ...clubRosterVendorSyncState,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    })
  }

  useEffect(() => {
    if (baseUsasClubState.isObjLoaded === true) {
      searchClubNonAthleteRoster(baseUsasClubState.objData.usasClubId, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUsasClubState]);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === true) {
      const practiceGroupType = orgGroupTypesState.arrayData.find(x => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_PRACTICE_GROUP);
      const siteGroupType = orgGroupTypesState.arrayData.find(x => x.typeName === GROUP_CONSTANTS.GROUP_TYPE_NAME_CLUB_SITE);

      setState({
        ...state,
        practiceGroupTypeId: practiceGroupType?.groupTypeId || Constants.DEFAULT_ID,
        siteGroupTypeId: siteGroupType?.groupTypeId || Constants.DEFAULT_ID
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgGroupTypesState]);

  useEffect(() => {
    if (orgGroupTypesState.isArrayLoaded === false) {
      getOrgGroupTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createFilterObj(formState) {
    return {
      competitionGenderTypeId: formState.competitionGenderTypeId > 0 ? formState.competitionGenderTypeId : undefined,
      ageStart: formState.minAge.trim() !== '' ? formState.minAge.trim() : undefined,
      ageEnd: formState.maxAge.trim() !== '' ? formState.maxAge.trim() : undefined,
      firstName: formState.firstOrPreferredName.trim() !== '' ? formState.firstOrPreferredName.trim() : undefined,
      lastName: formState.lastName.trim() !== '' ? formState.lastName.trim() : undefined,
      practiceOrgGroupId: formState.practiceGroupId > 0 ? formState.practiceGroupId : undefined,
      clubSiteOrgGroupId: formState.siteGroupId > 0 ? formState.siteGroupId : undefined
    };
  }

  function getInitialState() {
    return {
      practiceGroupTypeId: Constants.DEFAULT_ID,
      siteGroupTypeId: Constants.DEFAULT_ID
    };
  }

  return {
    isLoading: baseUsasClubState.isObjLoading || clubNonAthleteRosterState.isArrayLoading || orgGroupTypesState.isArrayLoading,
    state,
    baseUsasClubState,
    clubNonAthleteRosterState,
    vendorSyncState,
    clubRosterVendorSyncState,
    transferState,
    clearVendorSync,
    clearTransferState,
    onEditNonAthlete,
    onSubmitFormCallback,
    onSyncSelectedMembers,
    onVendorSyncCheckbox,
    onTransferCheckbox,
    searchClubNonAthleteRoster,
    orgUnitId: baseUsasClubState.objData?.orgUnitId || ''
  };
};

export default useClubNonAthleteRoster;