import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useLSCLifeMemberRenewals from './UseLSCLifeMemberRenewals';

import LSCLifeMemberRenewalsGrid from './LSCLifeMemberRenewalsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const LSCLifeMemberRenewals = () => {
  const {
    isLoading,
    memberAdministrationLscLifeMemberRenewalsState,
    state,
    gridState,
    onSelectGridMember,
    onSaveClicked,
    onSelectAllClicked,
    onUnselectAllClicked,
    onMemberTypesValueChanged
  } = useLSCLifeMemberRenewals();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>LSC Life Member Renewals</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <Headings.H6>In the Life Member Type(s) column, click on the &nbsp;<FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>&nbsp; to be able to select/unselect which life member type(s) you want to add/remove for that person. By default, renewal life member types are selected for each person. Any new life member types must be manually selected for each person. Renewed life member types, labeled (Renewed), cannot be unselected.</Headings.H6>
        </div>
      </div>
      {gridState?.gridData?.length > 15 && isLoading === false &&
        <div className='visible-md visible-lg'>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onSelectAllClicked}>Select All Checkboxes</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAllClicked}>Unselect All Checkboxes</SecondaryButton>
            </div>
          </div>
          {state.error &&
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
              </div>
            </div>
          }
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton onClick={onSaveClicked}>Save</PrimaryButton>
            </div>
          </div>
        </div>
      }
      {gridState?.gridData?.length > 0 && isLoading === false &&
        <div className='hidden-md hidden-lg'>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onSelectAllClicked}>Select All Checkboxes</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAllClicked}>Unselect All Checkboxes</SecondaryButton>
            </div>
          </div>
          {state.error &&
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
              </div>
            </div>
          }
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton onClick={onSaveClicked}>Save</PrimaryButton>
            </div>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <LSCLifeMemberRenewalsGrid
            data={gridState.gridData}
            isLoading={memberAdministrationLscLifeMemberRenewalsState.isArrayLoading}
            onSelected={onSelectGridMember}
            showError={state.showError}
            onMemberTypesValueChanged={onMemberTypesValueChanged} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAllClicked}>Select All Checkboxes</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onUnselectAllClicked}>Unselect All Checkboxes</SecondaryButton>
        </div>
      </div>
      {state.error &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={onSaveClicked}>Save</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default LSCLifeMemberRenewals;