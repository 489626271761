import Checkbox from '../../../../../../../common/components/checkboxes/Checkbox';
import SGColumnHeader from '../../../../../../../common/components/grids/SGColumnHeader';

import SGConstants from '../../../../../../../common/utils/SGConstants';
import useSortableGrid from '../../../../../../../common/utils/UseSortableGrid';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthlete, onUpdateIsSelected }) => (
  <tr>
    <td style={{ width: '40%' }}>{groupAthlete.firstName || ''} {groupAthlete.lastName || ''}</td>
    <td style={{ width: '30%' }}>{groupAthlete.competitionGenderTypeName || ''}</td>
    <td style={{ width: '10%' }}>{groupAthlete.age || ''}</td>
    <td style={{ width: '20%' }}>
      <Checkbox
        name={`isSelected${groupAthlete.personId}`}
        checked={groupAthlete.isSelected}
        onChange={() => { onUpdateIsSelected(groupAthlete.personId) }} />
    </td>
  </tr>
);

const ClubGroupAthletesFormGridLarge = ({ formGridState, onUpdateIsSelected }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(formGridState);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'competitionGenderTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Age'} columnField={'age'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Add or Remove From Group'} columnField={'isSelected'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map((groupAthlete, i) =>
            <GridRow key={i} groupAthlete={groupAthlete} onUpdateIsSelected={onUpdateIsSelected} />)
          : <tr><td colSpan="4">No Athletes</td></tr>
        }
      </tbody>
    </table>
  );
}

export default ClubGroupAthletesFormGridLarge;