import { useEffect, useState } from 'react';

import validate from './ClubGroupAthletesFilterValidation';

import { DEFAULT_ID } from "../../../../../../../common/utils/Constants";
import UseForm from "../../../../../../../common/utils/UseForm";

const COMPETITION_GENDER_OPTIONS = [
  { id: DEFAULT_ID, name: "--" },
  { id: 1, name: "Male" },
  { id: 2, name: "Female" }
];

const INITIAL_FORM_STATE = {
  competitionGenderTypeId: DEFAULT_ID,
  competitionGenderTypeName: '--',
  startAge: '',
  endAge: ''
};

const useClubGroupAthletesFilter = (onSubmitFormCallback, onIsDirtyChanged, onErrorCaught) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, onValueTextPairChanged, setFormData, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [displayClearFilterButtonState, setDisplayClearFilterButtonState] = useState(false);

  const onClearFilterClicked = (e) => {
    try {
      e?.preventDefault();

      onSubmitFormCallback({ ...INITIAL_FORM_STATE });
      setFormData({ ...INITIAL_FORM_STATE });
      setErrors({});
    } catch (error) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
  };

  useEffect(() => {
    try {
      if (onIsDirtyChanged) {
        onIsDirtyChanged(isDirty);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    try {
      if (formState.competitionGenderTypeId !== DEFAULT_ID || formState.startAge !== '' || formState.endAge !== '') {
        setDisplayClearFilterButtonState(true);
      } else {
        setDisplayClearFilterButtonState(false);
      }
    } catch (e) {
      onErrorCaught ? onErrorCaught(e) : setErrors({ formError: e?.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return {
    displayClearFilterButton: displayClearFilterButtonState,
    isDirty,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    onClearFilterClicked,
    COMPETITION_GENDER_OPTIONS
  };
};

export default useClubGroupAthletesFilter;