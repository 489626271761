import { Fragment } from 'react';

import EditIcon from '../../../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../../../common/components/icons/DeleteIcon';
import AddRemoveMembersIcon from '../../../../../../../common/components/icons/AddRemoveMembersIcon';

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ group, onEditClicked, onDeleteClicked, onEditMembersClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{group?.groupName}</span>&nbsp;
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onEditMembersClicked(e, group?.orgGroupId, group.groupTypeId, group.groupTypeName)}>
        <AddRemoveMembersIcon />
      </button>
      {group?.numberOfMembers <= 0 &&
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={(e) => onDeleteClicked(e, group?.orgGroupId, group?.groupName)}>
          <DeleteIcon />
        </button>
      }
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onEditClicked(e, group?.orgGroupId, group.groupTypeId, group.groupTypeName)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Group Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{group?.groupName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group?.groupTypeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group?.groupCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Date Range</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group?.dateRange || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Members</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group?.numberOfMembers ?? <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ClubGroupsGridSmall = ({ data, isLoading, onEditClicked, onDeleteClicked, onEditMembersClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div>
            </div>
          </div>
        )
        : data.length > 0
          ? data.map((group, i) => <SmallGridRow key={i} group={group} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onEditMembersClicked={onEditMembersClicked} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Groups</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default ClubGroupsGridSmall;