import { Fragment } from "react";

import Checkbox from "../../../../common/components/checkboxes/Checkbox";
import GridFriendlyMultiSelect from "../../../../common/components/multiSelect/GridFriendlyMultiSelect";

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ member, onSelected, showError, onMemberTypesValueChanged }) => {
  return (
    <Fragment>
      <tr>
        <td>
          <Checkbox
            label={''}
            name="isSelected"
            onChange={() => onSelected(member.personId)}
            checked={member.isSelected ?? false} />
        </td>
        <td>{(member.firstName || member.middleName || member.lastName) ? `${member.firstName || ''}  ${member.preferredName !== '' && member.preferredName !== member.firstName ? '"' + member.preferredName + '"' : ''} ${member.middleName || ''} ${member.lastName || ''}` : ''}</td>
        <td>{member.memberId || ''}</td>
        <td>
          <GridFriendlyMultiSelect
            label=""
            name={"lifeMemberTypes" + member.personId}
            value={member.selectedMemberTypes}
            error={showError === true && member.selectedMemberTypes?.length === 0 && member.isSelected === true}
            message={showError === true && member.selectedMemberTypes?.length === 0 && member.isSelected === true ? 'At least one Life Member Type is required' : ''}
            options={member.options || []}
            onChange={(value) => { onMemberTypesValueChanged(member, value) }} />
        </td>
      </tr>
    </Fragment>
  );
}

const LSCLifeMemberRenewalsGridLarge = ({ data, isLoading, onSelected, showError, onMemberTypesValueChanged }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Select</th>
        <th>Name</th>
        <th>Member Id</th>
        <th style={{minWidth: '500px'}}>Life Member Type(s)</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((member, i) => <GridRow key={i} member={member} onSelected={onSelected} showError={showError} onMemberTypesValueChanged={onMemberTypesValueChanged} />)
          : <tr><td colSpan="4">No LSC Life Member Renewals Available at This Time</td></tr>
      }
    </tbody>
  </table>
);

export default LSCLifeMemberRenewalsGridLarge;