import { useContext } from 'react';

import { OrgRoleMultiselectStateContext } from './OrgRoleMultiselectContextProvider';

import OrgRoleMultiselectData from './OrgRoleMultiselectData';

const useOrgRoleMultiselectData = () => {
  const [orgRoleMultiselectState, setOrgRoleMultiselectState] = useContext(OrgRoleMultiselectStateContext);

  const getOrgRoleMultiselect = (roleGroupId) => {
    OrgRoleMultiselectData.getOrgRoleMultiselectByOrgRoleId(roleGroupId, orgRoleMultiselectState, setOrgRoleMultiselectState);
  };

  return {
    orgRoleMultiselectState,
    getOrgRoleMultiselect
  };
};

export default useOrgRoleMultiselectData;