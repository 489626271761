import { useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import TimesProcessCompletedData from './TimesProcessCompletedData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useTimesProcessCompletedData = () => {
  const [timesProcessCompletedState, setTimesProcessCompletedState] = useState(INITIAL_STATE);

  const putTimesProcessCompleted = (meetId, isIndividualTimes, isRelayTimes) => {
    return TimesProcessCompletedData.putTimeProcessCompletedData(meetId, isIndividualTimes, isRelayTimes, timesProcessCompletedState, setTimesProcessCompletedState);  
  }

  return {
    timesProcessCompletedState,
    putTimesProcessCompleted
  };
}
 export default useTimesProcessCompletedData;