import GearsIcon from '../../../components/icons/GearsIcon';
import CheckIcon from '../../../components/icons/CheckIcon';
import ActionButton from '../../buttons/ActionButton';

import style from './MeetUploadStatus.module.css';

const MeetUploadStatus = ({ statusObject, onRefreshStatus }) => (
  <>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <table className="table table-striped">
          <thead>
            <tr style={{ border: '1px solid gray' }}>
              <td style={{ backgroundColor: '#041e42', padding: '8px', fontWeight: 'bold', color: 'white' }}>
                Processing...<br />
                <span style={{ fontSize: '13px', color: 'lightgray' }}>
                  This may take a few minutes. 
                  You can stay here and select 'REFRESH' or work in other areas. <br />
                  Full upload functionality will be available once processing is complete.
                </span>
              </td>
              <td style={{ backgroundColor: '#041e42' }}></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightblue',
                  border: '1px solid gray',
                  color: 'charcoal'
                }
              }>Individual Times</td>
              <td style={
                {
                  width: '60px',
                  padding: '5px',
                  backgroundColor: 'lightgray',
                  border: '1px solid gray'
                }
              }>&nbsp;{statusObject.indvTimesCount}</td>
            </tr>
            <tr>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightblue',
                  border: '1px solid gray',
                  color: 'charcoal'
                }
              }>Relay Times</td>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightgray',
                  border: '1px solid gray'
                }
              }>&nbsp;{statusObject.relayTimesCount}</td>
            </tr>
            <tr>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightblue',
                  border: '1px solid gray',
                  color: 'charcoal'
                }
              }>Individual Recognition Added &nbsp;</td>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightGray',
                  border: '1px solid gray'
                }
              }>&nbsp;{statusObject.indvRecognitionsAdded === true ? <CheckIcon toolTipText='Yes' /> : <GearsIcon />}</td>
            </tr>
            <tr>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightblue',
                  border: '1px solid gray',
                  color: 'charcoal'
                }
              }>Relay Recognition Added &nbsp;</td>
              <td style={
                {
                  padding: '5px',
                  backgroundColor: 'lightgray',
                  border: '1px solid gray'
                }
              }>&nbsp;{statusObject.relayRecognitionsAdded === true ? <CheckIcon toolTipText='Yes' /> : <GearsIcon />}</td>
            </tr>
          </tbody >
        </table >
      </div >
    </div >
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <ActionButton className={style.button} type="button" onClick={onRefreshStatus}>Refresh</ActionButton>
      </div>
    </div>
  </>
);

export default MeetUploadStatus;